import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Element } from 'react-scroll';
import ModalContainer from 'common/components/modalContainer';
import Contact from 'landing/containers/contact';
import CircleSpinner from 'common/components/circleSpinner';
import Slide from 'react-reveal/Slide';
import { disableScroll } from 'common/utils/disableScroll';
import './index.scss';

class contactSection extends Component {
  constructor() {
    super();
    this.state = {
      isContactmodalVisible: false,
      contactModalMessage: '',
    };
  }

  contactModalVisibleChange = (status) => {
    this.setState(
      {
        isContactmodalVisible: status.visible,
        contactModalMessage: status.message,
      },
      () => disableScroll(this.state.isContactmodalVisible),
    );
  };

  render() {
    const { landingData } = this.props;
    return (
      <>
        {landingData ? (
          <div className="main-landing">
            <section className="landing-contact">
              <Element name="contact">
                {this.state.isContactmodalVisible && (
                  <ModalContainer className="contact-modal">
                    <div className="contact-modal-container">
                      <p className="contact-modal-message">
                        {this.state.contactModalMessage}
                      </p>
                      <Button
                        className="mr-4 contact-modal-button"
                        outline
                        color="primary"
                        onClick={() => {
                          this.setState({ isContactmodalVisible: false }, () =>
                            disableScroll(this.state.isContactmodalVisible),
                          );
                        }}
                      >
                        return
                      </Button>
                    </div>
                  </ModalContainer>
                )}
                <Container>
                  <Row>
                    <Col xs="12" md="6">
                      <Slide bottom>
                        <Row>
                          <Col>
                            <h2 className="landing-contact__heading">
                              {landingData.contactTitle}
                            </h2>
                            <p className="landing-contact__copy">
                              {landingData.contactText}
                            </p>
                          </Col>
                        </Row>
                        <div className="landing-contact__form">
                          <Contact
                            contactModalVisibleChange={
                              this.contactModalVisibleChange
                            }
                          />
                        </div>
                      </Slide>
                    </Col>
                    <Col xs="12" md="6">
                      <Slide bottom>
                        <div className="landing-contact__support">
                          <h2 className="landing-contact__heading">
                            {landingData.supportTitle}
                          </h2>
                          <p className="landing-contact__copy">
                            {landingData.supportText}
                          </p>
                          <img
                            className="landing-contact__image"
                            src={landingData.supportImage}
                            alt=""
                          />
                          <div className="landing-contact__contact">
                            <div>
                              <p className="landing-contact__copy landing-contact__name">
                                {landingData.supportName}
                              </p>
                              <p className="landing-contact__copy landing-contact__position">
                                {landingData.supportPosition}
                              </p>
                            </div>
                            <div>
                              <p className="landing-contact__copy landing-contact__email">
                                <a
                                  className="landing-contact__link"
                                  href="mailto:info@fintex.ltd"
                                >
                                  {landingData.supportEmail}
                                </a>
                              </p>
                              <p className="landing-contact__copy landing-contact__phone">
                                <a
                                  className="landing-contact__link"
                                  href="tel:+41444404344"
                                >
                                  {landingData.supportPhone}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </Col>
                  </Row>
                </Container>
              </Element>
            </section>
          </div>
        ) : (
          <div className="main-landing__load-container">
            <CircleSpinner color="white" />
          </div>
        )}
      </>
    );
  }
}

export default contactSection;
