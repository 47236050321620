import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_COMPANIES_FILTERS,
  SAVE_COMPANIES_SORT_CHECKBOX,
  SAVE_COMPANIES_FILTERS,
  SAVE_COMPANIES_SEARCH,
} from 'companies/redux/types';

const initialState = {
  sector: [],
  country: [],
  continent: [],
  industry: [],
  relations: [],
  search: '',
  sortBy: 'default',
};

const companiesFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_COMPANIES_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_COMPANIES_FILTERS]: state => ({
      ...state,
      sector: [],
      country: [],
      continent: [],
      industry: [],
      relations: [],
      sortBy: 'default',
    }),
    [SAVE_COMPANIES_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [SAVE_COMPANIES_SORT_CHECKBOX]: (state, action) => ({
      ...state,
      sortBy: action.payload.sortBy ? 'most_viewed' : 'default',
    }),
  },
);

export default companiesFiltersReducer;
