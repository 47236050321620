import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_LANDING_DATA_REQUEST,
  FETCH_LANDING_DATA_SUCCESS,
  FETCH_LANDING_DATA_FAILURE,
} = types;

const fetchLandingDataRequest = createActionCreator(FETCH_LANDING_DATA_REQUEST);
const fetchLandingDataSuccess = createActionCreator(
  FETCH_LANDING_DATA_SUCCESS,
  'data',
);
const fetchLandingDataFailure = createActionCreator(FETCH_LANDING_DATA_FAILURE);

const fetchLandingData = () => dispatch => {
  dispatch(fetchLandingDataRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/landing-page`)
    .then(response => {
      dispatch(fetchLandingDataSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchLandingDataFailure());
    });
};

export default fetchLandingData;
