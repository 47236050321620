import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_COMPANY_SUCCESS,
  REMOVE_ADMIN_COMPANY,
} from 'adminPanel/redux/types';

const initialState = {
  currentCompany: {},
};

const adminCompanyReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_COMPANY_SUCCESS]: (state, action) => ({
      ...state,
      currentCompany: action.payload.currentCompany,
    }),
    [REMOVE_ADMIN_COMPANY]: state => ({
      ...state,
      currentCompany: {},
    }),
  },
);

export default adminCompanyReducer;
