export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_MORE_SUCCESS = 'FETCH_DOCUMENTS_MORE_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const ADD_DOCUMENTS_REQUEST = 'ADD_DOCUMENTS_REQUEST';
export const ADD_DOCUMENTS_SUCCESS = 'ADD_DOCUMENTS_SUCCESS';
export const ADD_DOCUMENTS_FAILURE = 'ADD_DOCUMENTS_FAILURE';

export const EDIT_DOCUMENTS_REQUEST = 'EDIT_DOCUMENTS_REQUEST';
export const EDIT_DOCUMENTS_SUCCESS = 'EDIT_DOCUMENTS_SUCCESS';
export const EDIT_DOCUMENTS_FAILURE = 'EDIT_DOCUMENTS_FAILURE';

export const REMOVE_ONE_DOCUMENTS_REQUEST = 'REMOVE_ONE_DOCUMENTS_REQUEST';
export const REMOVE_ONE_DOCUMENTS_SUCCESS = 'REMOVE_ONE_DOCUMENTS_SUCCESS';
export const REMOVE_ONE_DOCUMENTS_FAILURE = 'REMOVE_ONE_DOCUMENTS_FAILURE';

export const REMOVE_DOCUMENTS = 'REMOVE_DOCUMENTS';
export const ADD_DOCUMENTS_SECTION_REQUEST = 'ADD_DOCUMENTS_SECTION_REQUEST';
export const ADD_DOCUMENTS_SECTION_SUCCESS = 'ADD_DOCUMENTS_SECTION_SUCCESS';
export const ADD_DOCUMENTS_SECTION_FAILURE = 'ADD_DOCUMENTS_SECTION_FAILURE';

export const EDIT_DOCUMENTS_SECTION_REQUEST = 'EDIT_DOCUMENTS_SECTION_REQUEST';
export const EDIT_DOCUMENTS_SECTION_SUCCESS = 'EDIT_DOCUMENTS_SECTION_SUCCESS';
export const EDIT_DOCUMENTS_SECTION_FAILURE = 'EDIT_DOCUMENTS_SECTION_FAILURE';

export const REMOVE_DOCUMENTS_SECTION_REQUEST =
  'REMOVE_DOCUMENTS_SECTION_REQUEST';
export const REMOVE_DOCUMENTS_SECTION_SUCCESS =
  'REMOVE_DOCUMENTS_SECTION_SUCCESS';
export const REMOVE_DOCUMENTS_SECTION_FAILURE =
  'REMOVE_DOCUMENTS_SECTION_FAILURE';
