export const FETCH_OTHER_STARTUPS_REQUEST = 'FETCH_OTHER_STARTUPS_REQUEST';
export const FETCH_OTHER_STARTUPS_SUCCESS = 'FETCH_OTHER_STARTUPS_SUCCESS';
export const FETCH_OTHER_STARTUPS_FAILURE = 'FETCH_OTHER_STARTUPS_FAILURE';

export const FETCH_UPCOMING_STARTUP_EVENTS_REQUEST =
  'FETCH_UPCOMING_STARTUP_EVENTS_REQUEST';
export const FETCH_UPCOMING_STARTUP_EVENTS_SUCCESS =
  'FETCH_UPCOMING_STARTUP_EVENTS_SUCCESS';
export const FETCH_UPCOMING_STARTUP_EVENTS_FAILURE =
  'FETCH_UPCOMING_STARTUP_EVENTS_FAILURE';

export const SAVE_STARTUP_EVENTS_DATE_RANGE = 'SAVE_STARTUP_EVENTS_DATE_RANGE';
export const SAVE_STARTUP_EVENTS_DATE = 'SAVE_STARTUP_EVENTS_DATE';
export const REMOVE_STARTUP_EVENTS_FILTERS = 'REMOVE_STARTUP_EVENTS_FILTERS';

export const FETCH_STARTUP_CALENDAR_EVENTS_REQUEST =
  'FETCH_STARTUP_CALENDAR_EVENTS_REQUEST';
export const FETCH_STARTUP_CALENDAR_EVENTS_SUCCESS =
  'FETCH_STARTUP_CALENDAR_EVENTS_SUCCESS';
export const FETCH_STARTUP_CALENDAR_EVENTS_FAILURE =
  'FETCH_STARTUP_CALENDAR_EVENST_FAILURE';

export const REMOVE_STARTUP_CALENDAR_EVENTS = 'REMOVE_STARTUP_CALENDAR_EVENTS';

export const SAVE_STARTUP_CALENDAR_FETCHED_MONTHS =
  'SAVE_STARTUP_CALENDAR_FETCHED_MONTHS';
