import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  REMOVE_USER_DATA,
} = types;

const fetchUserDataRequest = createActionCreator(FETCH_USER_DATA_REQUEST);
export const setUserData = createActionCreator(FETCH_USER_DATA_SUCCESS, 'data');

export const fetchUserDataFailure = createActionCreator(
  FETCH_USER_DATA_FAILURE,
);

const removeUserDataAction = createActionCreator(REMOVE_USER_DATA);

export const fetchUserData = () => (dispatch) => {
  dispatch(fetchUserDataRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/users/me`, {
      params: {
        relations: true,
      },
    })
    .then((response) => {
      dispatch(setUserData(response.data));
    })
    .catch(() => {
      dispatch(fetchUserDataFailure());
    });
};

export const removeUserData = () => (dispatch) => {
  dispatch(removeUserDataAction({}));
};
