import { useEffect, useRef } from 'react';

const useMainOptionsHeader = (toggleOptionsMenu) => {
  const node = useRef();

  useEffect(() => {
    const handleClickOutside = () => {
      // NOTE: timeout is set to make sure that options menu will be closed
      // when user clicks outside options menu on the username which is
      // also a button that should open options menu
      setTimeout(() => {
        toggleOptionsMenu();
      }, 100);
    };

    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      handleClickOutside();
    };

    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [toggleOptionsMenu]);

  return { node };
};

export default useMainOptionsHeader;
