export const REMOVE_INVESTORS_REQUESTS_FILTERS =
  'REMOVE_INVESTORS_REQUESTS_FILTERS';
export const SAVE_INVESTORS_REQUESTS_FILTERS =
  'SAVE_INVESTORS_REQUESTS_FILTERS';
export const SAVE_INVESTORS_REQUESTS_SEARCH = 'SAVE_INVESTORS_REQUESTS_SEARCH';

export const FETCH_INVESTORS_REQUESTS_REQUEST =
  'FETCH_INVESTORS_REQUESTS_REQUEST';
export const FETCH_INVESTORS_REQUESTS_FAILURE =
  'FETCH_INVESTORS_REQUESTS_FAILURE';

export const FETCH_INVESTORS_REQUESTS_SUCCESS =
  'FETCH_INVESTORS_REQUESTS_SUCCESS';
export const UPDATE_INVESTORS_REQUESTS = 'UPDATE_INVESTORS_REQUESTS';
