import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_CALENDAR_EVENTS_SUCCESS,
  REMOVE_CALENDAR_EVENTS,
  SAVE_CALENDAR_FETCHED_MONTHS,
} from 'events/redux/types';

const initialState = {
  events: [],
  fetchedMonths: [],
};

const eventsCalendarReducer = createReducer(
  { ...initialState },
  {
    [FETCH_CALENDAR_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      events: [...state.events, ...action.payload.events],
    }),
    [SAVE_CALENDAR_FETCHED_MONTHS]: (state, action) => ({
      ...state,
      fetchedMonths: [...state.fetchedMonths, action.payload.fetchedMonths],
    }),
    [REMOVE_CALENDAR_EVENTS]: (state) => ({
      ...state,
      events: [],
      fetchedMonths: [],
    }),
  },
);

export default eventsCalendarReducer;
