import * as Yup from 'yup';

const sendMailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('This field is required.')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      'E-mail address is invalid.',
    ),
  name: Yup.string()
    .trim()
    .min(3)
    .max(100)
    .required('This field is required.')
    .min(3, 'Username must be at least 3 characters long.'),
  message: Yup.string()
    .trim()
    .min(3)
    .max(8000)
    .required('This field is required.'),
});

export default sendMailValidationSchema;
