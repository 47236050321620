import { createReducer } from 'common/utils/reduxUtils';
import { SET_USER_AUTH_TOKEN } from 'userAuth/redux/types';

const authReducer = createReducer(
  {},
  {
    [SET_USER_AUTH_TOKEN]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
);

export default authReducer;
