import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_ADMIN_FUNDS_FILTERS,
  SAVE_ADMIN_FUNDS_FILTERS,
  SAVE_ADMIN_FUNDS_SEARCH,
  SAVE_ADMIN_FUNDS_ACTIVE_CHECKBOX,
  SAVE_ADMIN_FUNDS_PASSIVE_CHECKBOX,
} from 'adminPanel/redux/types';

const initialState = {
  search: '',
  active: 1,
  passive: 1,
  country: [],
  fundType: [],
  currency: [],
  continent: [],
};

const adminFundsFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_ADMIN_FUNDS_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_ADMIN_FUNDS_FILTERS]: state => ({
      ...state,
      active: 1,
      passive: 1,
      country: [],
      fundType: [],
      currency: [],
      continent: [],
    }),
    [SAVE_ADMIN_FUNDS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [SAVE_ADMIN_FUNDS_ACTIVE_CHECKBOX]: (state, action) => ({
      ...state,
      active: action.payload.active ? 1 : 0,
    }),
    [SAVE_ADMIN_FUNDS_PASSIVE_CHECKBOX]: (state, action) => ({
      ...state,
      passive: action.payload.passive ? 1 : 0,
    }),
  },
);

export default adminFundsFiltersReducer;
