import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_SUCCESS,
  FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_REQUEST,
  FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_FAILURE,
} from 'common/redux/types';

const fundManagersWithFundsReducer = createReducer([], {
  [FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
    isLoading: false,
  }),
  [FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
});

export default fundManagersWithFundsReducer;
