import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUNDS_MANAGERS_LIST_SUCCESS,
  UPDATE_ADMIN_FUNDS_MANAGERS_LIST,
} from 'adminPanel/redux/types';

const initialState = {
  list: [],
  nextPageIndex: null,
};

const adminFundsManagersListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUNDS_MANAGERS_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.adminFundsManagers;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [UPDATE_ADMIN_FUNDS_MANAGERS_LIST]: (state, action) => ({
      ...state,
      list: action.payload.updatedAdminFundsManagersList,
    }),
  },
);

export default adminFundsManagersListReducer;
