import axios from 'axios';
import { loadToken } from 'stateLoader';

export default function setRequestInterceptor() {
  axios.interceptors.request.use(
    (config) => {
      const interceptedConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${loadToken()}`,
        },
      };
      return interceptedConfig;
    },
    (error) => error,
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;
      if (
        (status === 403 || status === 404) &&
        data.error !== 'account_not_confirmed'
      ) {
        window.location.replace('/404');
      }

      return Promise.reject(error);
    },
  );
}
