export const REMOVE_FUNDS_MANAGERS_RELATIONS_FILTERS =
  'REMOVE_FUNDS_MANAGERS_RELATIONS_FILTERS';
export const SAVE_FUNDS_MANAGERS_RELATIONS_FILTERS =
  'SAVE_FUNDS_MANAGERS_RELATIONS_FILTERS';
export const SAVE_FUNDS_MANAGERS_RELATIONS_SEARCH =
  'SAVE_FUNDS_MANAGERS_RELATIONS_SEARCH';

export const FETCH_FUNDS_MANAGERS_RELATIONS_REQUEST =
  'FETCH_FUNDS_MANAGERS_RELATIONS_REQUEST';
export const FETCH_FUNDS_MANAGERS_RELATIONS_FAILURE =
  'FETCH_FUNDS_MANAGERS_RELATIONS_FAILURE';

export const FETCH_FUNDS_MANAGERS_RELATIONS_SUCCESS =
  'FETCH_FUNDS_MANAGERS_RELATIONS_SUCCESS';
export const UPDATE_FUNDS_MANAGERS_RELATIONS =
  'UPDATE_FUNDS_MANAGERS_RELATIONS';
