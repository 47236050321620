import React, { Component } from 'react';
import { getNotificationsIndicator } from 'common/api/notificationApi';
import { LinkContainer } from 'react-router-bootstrap';
import './style.scss';

class Notification extends Component {
  state = {
    notificationsIndicator: 0,
  };

  componentDidMount() {
    getNotificationsIndicator().then(response => {
      this.setState({
        notificationsIndicator: response.data.indicator,
      });
    });
  }

  render() {
    return (
      <LinkContainer
        to="/notifications"
        onClick={this.props.toggleMobileNav}
        className="main-nav__link"
        activeClassName="main-nav__link--active"
        offset={-100}
        data-text="notifications"
      >
        <div className="main-nav__link-text">
          {' '}
          Notifications
          {this.state.notificationsIndicator > 0 ? (
            <span className="indicator">
              {this.state.notificationsIndicator}
            </span>
          ) : (
            ' '
          )}
        </div>
      </LinkContainer>
    );
  }
}

export default Notification;
