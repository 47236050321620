import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_PROJECT_SUCCESS,
  REMOVE_ADMIN_PROJECT,
} from 'adminPanel/redux/types';

const initialState = {
  currentProject: {},
};

const adminProjectReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_PROJECT_SUCCESS]: (state, action) => ({
      ...state,
      currentProject: action.payload.currentProject,
    }),
    [REMOVE_ADMIN_PROJECT]: (state) => ({
      ...state,
      currentProject: {},
    }),
  },
);

export default adminProjectReducer;
