import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_USER_DATA_SUCCESS, REMOVE_USER_DATA } from 'common/redux/types';

const userDataReducer = createReducer(
  {},
  {
    [FETCH_USER_DATA_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),

    [REMOVE_USER_DATA]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
  },
);

export default userDataReducer;
