import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_STARTUP_CATEGORIES_LIST_SUCCESS } from 'common/redux/types';

const startupCategoriesListReducer = createReducer([], {
  [FETCH_STARTUP_CATEGORIES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
  }),
});

export default startupCategoriesListReducer;
