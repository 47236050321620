export const FETCH_STARTUPS_LIST_REQUEST = 'FETCH_STARTUPS_LIST_REQUEST';
export const FETCH_STARTUPS_LIST_SUCCESS = 'FETCH_STARTUPS_LIST_SUCCESS';
export const FETCH_STARTUPS_LIST_FAILURE = 'FETCH_STARTUPS_LIST_FAILURE';

export const UPDATE_STARTUPS_LIST = 'UPDATE_STARTUPS_LIST';
export const CLEAR_STARTUPS_LIST = 'CLEAR_STARTUPS_LIST';

export const SAVE_STARTUPS_FILTERS = 'SAVE_STARTUPS_FILTERS';
export const REMOVE_STARTUPS_FILTERS = 'REMOVE_STARTUPS_FILTERS';
export const SAVE_STARTUPS_SEARCH = 'SAVE_STARTUPS_SEARCH';

export const SAVE_STARTUPS_RAISED_RANGE = 'SAVE_STARTUPS_RAISED_RANGE';

export const SAVE_STARTUPS_SORT_CHECKBOX = 'SAVE_STARTUPS_SORT_CHECKBOX';

export const FETCH_STARTUPS_LATEST_NEWS_LIST_REQUEST =
  'FETCH_STARTUPS_LATEST_NEWS_LIST_REQUEST';
export const FETCH_STARTUPS_LATEST_NEWS_LIST_SUCCESS =
  'FETCH_STARTUPS_LATEST_NEWS_LIST_SUCCESS';
export const FETCH_STARTUPS_LATEST_NEWS_LIST_FAILURE =
  'FETCH_STARTUPS_LATEST_NEWS_LIST_FAILURE';
