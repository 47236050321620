export const REMOVE_STARTUP_RELATIONS_FILTERS =
  'REMOVE_STARTUP_RELATIONS_FILTERS';
export const SAVE_STARTUP_RELATIONS_FILTERS = 'SAVE_STARTUP_RELATIONS_FILTERS';
export const SAVE_STARTUP_RELATIONS_SEARCH = 'SAVE_STARTUP_RELATIONS_SEARCH';

export const FETCH_STARTUP_RELATIONS_REQUEST =
  'FETCH_STARTUP_RELATIONS_REQUEST';
export const FETCH_STARTUP_RELATIONS_FAILURE =
  'FETCH_STARTUP_RELATIONS_FAILURE';

export const FETCH_STARTUP_RELATIONS_SUCCESS =
  'FETCH_STARTUP_RELATIONS_SUCCESS';
export const UPDATE_STARTUP_RELATIONS = 'UPDATE_STARTUP_RELATIONS';
