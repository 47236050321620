import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_COMPANIES_REQUESTS_FILTERS,
  SAVE_COMPANIES_REQUESTS_FILTERS,
  SAVE_COMPANIES_REQUESTS_SEARCH,
} from 'adminPanel/redux/types';

const initialState = {
  sector: [],
  country: [],
  continent: [],
  industry: [],
  search: '',
  per_page: 10,
};

const companiesRequestsFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_COMPANIES_REQUESTS_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_COMPANIES_REQUESTS_FILTERS]: state => ({
      ...state,
      sector: [],
      country: [],
      continent: [],
      industry: [],
    }),
    [SAVE_COMPANIES_REQUESTS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default companiesRequestsFiltersReducer;
