import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';
import AuthenticationForbidden from 'permissions/components/authenticationForbidden';
import AuthenticationRequired from 'permissions/components/authenticationRequired';

const MainHeaderAuthButtons = ({
  isMobileViewActive,
  toggleMobileNav,
  userData,
  openOptionsMenu,
  handleLogOutClick,
}) => (
  <>
    <AuthenticationForbidden>
      <LinkContainer to={isMobileViewActive ? '/login' : '/auth'}>
        <Button
          outline
          color={isMobileViewActive ? 'primary' : 'secondary'}
          onClick={toggleMobileNav}
        >
          Log in
        </Button>
      </LinkContainer>
    </AuthenticationForbidden>
    <AuthenticationRequired redirectFallback={null}>
      {isMobileViewActive ? (
        <Button
          outline
          color="primary"
          onClick={() => {
            handleLogOutClick();
            toggleMobileNav();
          }}
        >
          Log out
        </Button>
      ) : (
        <button className="main-nav__username" onClick={openOptionsMenu}>
          {userData ? userData.name : ''}
        </button>
      )}
    </AuthenticationRequired>
  </>
);

export default MainHeaderAuthButtons;
