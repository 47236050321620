import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_NEWS_SUCCESS, REMOVE_NEWS } from 'news/redux/types';
import { FETCH_LATEST_NEWS_SUCCESS } from '../types';

const initialState = {
  currentNews: {},
  latestNews: [],
};

const newsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_NEWS_SUCCESS]: (state, action) => ({
      ...state,
      currentNews: action.payload.currentNews,
    }),
    [REMOVE_NEWS]: state => ({
      ...state,
      currentNews: {},
    }),

    [FETCH_LATEST_NEWS_SUCCESS]: (state, action) => ({
        ...state,
        latestNews: action.payload.latestNews,
      }),
  },
);

export default newsReducer;
