import React from 'react';
import shortid from 'shortid';
import { Link } from 'react-router-dom';

const LoginHeaderNavigation = ({
  mainNavItemsClasses,
  mainNavListClasses,
  toggleMobileNav,
}) => (
  <ul className={mainNavListClasses}>
    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <Link
        onClick={toggleMobileNav}
        className="main-nav__link"
        to={{
          pathname: '/',
          state: {
            target: 'about',
            offset: -100,
            duration: 500,
            smooth: true,
          },
        }}
      >
        <span className="main-nav__link-text main-nav__link-text__on-landing">
          About
        </span>
      </Link>
    </li>

    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <Link
        onClick={toggleMobileNav}
        className="main-nav__link"
        to={{
          pathname: '/',
          state: {
            target: 'contact',
            offset: -100,
            duration: 500,
            smooth: true,
          },
        }}
      >
        <span className="main-nav__link-text main-nav__link-text main-nav__link-text__on-landing">
          Contact and support
        </span>
      </Link>
    </li>
    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <Link
        onClick={toggleMobileNav}
        className="main-nav__link"
        to={{
          pathname: '/',
          state: {
            target: 'corporate',
            offset: -100,
            duration: 500,
            smooth: true,
          },
        }}
      >
        <span className="main-nav__link-text main-nav__link-text__on-landing">
          Corporate
        </span>
      </Link>
    </li>
  </ul>
);

export default LoginHeaderNavigation;
