import { createReducer } from 'common/utils/reduxUtils';
import {
  SAVE_USERS_FILTERS,
  SAVE_USERS_SEARCH,
  REMOVE_USERS_FILTERS,
} from 'adminPanel/adminContent/usersManagement/redux/types';

const initialState = {
  relation: [],
  per_page: 10,
  search: '',
  company: [],
  country: [],
  fundsManager: [],
  loginMethod: [],
  registerSource: [],
};

const usersFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_USERS_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [SAVE_USERS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [REMOVE_USERS_FILTERS]: (state) => ({
      ...state,
      relation: [],
      per_page: 10,
      search: '',
      company: [],
      country: [],
      fundsManager: [],
      loginMethod: [],
      registerSource: [],
    }),
  },
);

export default usersFiltersReducer;
