import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Page404Loader extends Component {
  componentDidMount() {
    this.props.history.replace('/404');
  }

  render() {
    return '';
  }
}

export default withRouter(Page404Loader);
