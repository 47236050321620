import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_STARTUP_TEAM_SUCCESS,
  REMOVE_STARTUP_EMPLOYEE_SUCCESS,
} from 'common/redux/types';

const initialState = {
  list: [],
};

const startupTeamReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUP_TEAM_SUCCESS]: (state, action) => ({
      list: [...action.payload.startupTeam],
    }),
    [REMOVE_STARTUP_EMPLOYEE_SUCCESS]: (state, action) => {
      const { memberIndex, groupIndex } = action.payload.indexes;
      const newList = state.list.slice();
      const membersList = [...state.list[groupIndex].data];
      membersList.splice(memberIndex, 1);
      newList[groupIndex].data = membersList;

      return {
        list: [...newList],
      };
    },
  },
);

export default startupTeamReducer;
