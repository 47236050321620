import React from 'react';
import shortid from 'shortid';
import { LinkContainer } from 'react-router-bootstrap';
import Notification from 'common/components/notification';
import NotificationMobile from 'common/components/notificationMobile';
import AuthenticationRequired from 'permissions/components/authenticationRequired';
import LoggedInHeaderNavigation from './LoggedInHeaderNavigation';
import MainHeaderAuthButtons from './MainHeaderAuthButtons';

const DefaultHeaderNavigation = ({
  mainNavListClasses,
  mainNavItemsClasses,
  toggleMobileNav,
  cookies,
  indicator,
  getIndicator,
  clearIndicator,
  isMobileViewActive,
  userData,
  openOptionsMenu,
  handleLogOutClick,
}) => (
  <ul className={mainNavListClasses}>
    {isMobileViewActive && (
      <li key={shortid.generate()} className={mainNavItemsClasses}>
        <LinkContainer
          to="/"
          onClick={toggleMobileNav}
          className="main-nav__link"
          offset={-100}
          data-text="fintex"
        >
          <span className="main-nav__link-text">Fintex ltd.</span>
        </LinkContainer>
      </li>
    )}
    <LoggedInHeaderNavigation
      toggleMobileNav={toggleMobileNav}
      mainNavItemsClasses={mainNavItemsClasses}
    />

    <AuthenticationRequired redirectFallback={null}>
      {isMobileViewActive ? (
        <li key={shortid.generate()} className={mainNavItemsClasses}>
          <NotificationMobile toggleMobileNav={toggleMobileNav} />
        </li>
      ) : (
        <li key={shortid.generate()} className={mainNavItemsClasses}>
          <Notification
            cookies={cookies}
            indicator={indicator}
            getIndicator={getIndicator}
            clearIndicator={clearIndicator}
          />
        </li>
      )}
    </AuthenticationRequired>
    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <MainHeaderAuthButtons
        userData={userData}
        openOptionsMenu={openOptionsMenu}
        handleLogOutClick={handleLogOutClick}
        toggleMobileNav={toggleMobileNav}
        isMobileViewActive={isMobileViewActive}
      />
    </li>
  </ul>
);

export default DefaultHeaderNavigation;
