import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_RELATIONS_FILTERS,
  SAVE_RELATIONS_FILTERS,
  SAVE_RELATIONS_SEARCH,
} from 'profile/relations/redux/types';

const initialState = {
  relation: [],
  search: '',
  type: 'companies',
  per_page: 10,
};

const RelationsFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_RELATIONS_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter.category === 'type') {
        return {
          ...state,
          relation: [],
          type: action.payload.filter.value,
        };
      }
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_RELATIONS_FILTERS]: state => ({
      ...state,
      relation: [],
    }),
    [SAVE_RELATIONS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default RelationsFiltersReducer;
