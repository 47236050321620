import { createReducer } from 'common/utils/reduxUtils';
import {
  SAVE_EVENTS_DATE,
  REMOVE_EVENTS_FILTERS,
  SAVE_EVENTS_DATE_RANGE,
  SAVE_EVENTS_PUBLICITY_CHECKBOX,
} from '../types';

const initialState = {
  date: {},
  dateRange: {},
  publicity: [],
};

const EventsFiltersReducer = createReducer(
  { ...initialState },
  {
    [REMOVE_EVENTS_FILTERS]: (state) => ({
      ...state,
      dateRange: {},
    }),
    [SAVE_EVENTS_DATE_RANGE]: (state, action) => ({
      ...state,
      dateRange: action.payload.dateRange,
    }),
    [SAVE_EVENTS_DATE]: (state, action) => ({
      ...state,
      date: action.payload.date,
    }),
    [SAVE_EVENTS_PUBLICITY_CHECKBOX]: (state, action) => {
      if (action.payload.checkbox.value) {
        return {
          ...state,
          publicity: [...state.publicity, action.payload.checkbox.name],
        };
      }
      return {
        ...state,
        publicity: [...state.publicity].filter(
          (item) => item !== action.payload.checkbox.name,
        ),
      };
    },
  },
);

export default EventsFiltersReducer;
