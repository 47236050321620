import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_FUNDS_MANAGERS_FILTERS,
  SAVE_FUNDS_MANAGERS_FILTERS,
  SAVE_FUNDS_MANAGERS_SEARCH,
  SAVE_FUNDS_MANAGERS_MANAGEMENT_FEE_RANGE,
  SAVE_FUNDS_MANAGERS_PERFORMANCE_FEE_RANGE,
  SAVE_FUNDS_MANAGERS_DURATION_RANGE,
} from 'fundsManagers/redux/types';

const initialState = {
  country: [],
  fundType: [],
  kindOfFund: [],
  currency: [],
  continent: [],
  relations: [],
  trading: [],
  openClosed: [],
  activePassive: [],
  managementFeeRange: {},
  performanceFeeRange: {},
  durationRange: {},
  search: '',
};

const fundsManagersFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_FUNDS_MANAGERS_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_FUNDS_MANAGERS_FILTERS]: state => ({
      ...state,
      country: [],
      fundType: [],
      kindOfFund: [],
      currency: [],
      continent: [],
      relations: [],
      trading: [],
      openClosed: [],
      activePassive: [],
      managementFeeRange: {},
      performanceFeeRange: {},
      durationRange: {},
    }),
    [SAVE_FUNDS_MANAGERS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [SAVE_FUNDS_MANAGERS_MANAGEMENT_FEE_RANGE]: (state, action) => ({
      ...state,
      managementFeeRange: action.payload.managementFeeRange,
    }),
    [SAVE_FUNDS_MANAGERS_PERFORMANCE_FEE_RANGE]: (state, action) => ({
      ...state,
      performanceFeeRange: action.payload.performanceFeeRange,
    }),
    [SAVE_FUNDS_MANAGERS_DURATION_RANGE]: (state, action) => ({
      ...state,
      durationRange: action.payload.durationRange,
    }),
  },
);

export default fundsManagersFiltersReducer;
