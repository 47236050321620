import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FUND_REQUEST,
  FETCH_FUND_SUCCESS,
  FETCH_FUND_FAILURE,
  REMOVE_FUND,
} = types;

const fetchFundRequest = createActionCreator(FETCH_FUND_REQUEST);
const fetchFundSuccess = createActionCreator(FETCH_FUND_SUCCESS, 'currentFund');
const fetchFundFailure = createActionCreator(FETCH_FUND_FAILURE);

export const fetchFund = id => dispatch => {
  dispatch(fetchFundRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/funds/${id}`)
    .then(response => {
      dispatch(fetchFundSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFundFailure());
    });
};

export const removeFund = createActionCreator(REMOVE_FUND);
