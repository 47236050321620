import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUNDS_MANAGERS_NAMES_LIST_SUCCESS,
  FETCH_FUNDS_MANAGERS_NAMES_LIST_REQUEST,
  FETCH_FUNDS_MANAGERS_NAMES_LIST_FAILURE,
} from 'common/redux/types';

const fundsManagersNamesReducer = createReducer([], {
  [FETCH_FUNDS_MANAGERS_NAMES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
    isLoading: false,
  }),
  [FETCH_FUNDS_MANAGERS_NAMES_LIST_REQUEST]: state => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FUNDS_MANAGERS_NAMES_LIST_FAILURE]: state => ({
    ...state,
    isLoading: false,
  }),
});

export default fundsManagersNamesReducer;
