import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import sendMailValidationSchema from 'landing/validators/mailValidators';
import Input from 'common/components/input';
import './index.scss';

const ContactForm = ({ submitContactForm }) => (
  <Formik
    initialValues={{ name: '', email: '', message: '' }}
    validationSchema={sendMailValidationSchema}
    onSubmit={(values, { resetForm, setSubmitting }) => {
      submitContactForm(values, { resetForm, setSubmitting });
    }}
    render={({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    }) => (
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Input
            type="text"
            value={values.name}
            error={errors.name}
            touched={touched.name}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Name"
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="text"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="E-mail address"
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="textarea"
            value={values.message}
            error={errors.message}
            touched={touched.message}
            name="message"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Message"
          />
        </FormGroup>
        <div className="user-reset__submit-button-container">
          <Button
            type="submit"
            disabled={isSubmitting}
            color="primary"
            className="user-reset__submit-button"
          >
            Send
          </Button>
          {errors.submit && (
            <p className="submit-error-message">{errors.submit}</p>
          )}
        </div>
      </Form>
    )}
  />
);

ContactForm.propTypes = {
  submitContactForm: PropTypes.func.isRequired,
};

export default ContactForm;
