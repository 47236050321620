export const FETCH_COMPANIES_LIST_REQUEST = 'FETCH_COMPANIES_LIST_REQUEST';
export const FETCH_COMPANIES_LIST_SUCCESS = 'FETCH_COMPANIES_LIST_SUCCESS';
export const FETCH_COMPANIES_LIST_FAILURE = 'FETCH_COMPANIES_LIST_FAILURE';

export const UPDATE_COMPANIES_LIST = 'UPDATE_COMPANIES_LIST';
export const CLEAR_COMPANIES_LIST = 'CLEAR_COMPANIES_LIST';

export const SAVE_COMPANIES_FILTERS = 'SAVE_COMPANIES_FILTERS';
export const REMOVE_COMPANIES_FILTERS = 'REMOVE_COMPANIES_FILTERS';
export const SAVE_COMPANIES_SEARCH = 'SAVE_COMPANIES_SEARCH';
export const SAVE_COMPANIES_SORT_CHECKBOX = 'SAVE_COMPANIES_SORT_CHECKBOX';

export const FETCH_COMPANIES_SECTORS_PERFORMANCE_REQUEST =
  'FETCH_COMPANIES_SECTORS_PERFORMANCE_REQUEST';
export const FETCH_COMPANIES_SECTORS_PERFORMANCE_SUCCESS =
  'FETCH_COMPANIES_SECTORS_PERFORMANCE_SUCCESS';
export const FETCH_COMPANIES_SECTORS_PERFORMANCE_FAILURE =
  'FETCH_COMPANIES_SECTORS_PERFORMANCE_FAILURE';
