import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import fetchLandingData from 'common/redux/actions/landingActions';

const useMainFooter = () => {
  const dispatch = useDispatch();

  const isAdminSection = useSelector(
    (state) => state.mainHeader.isAdminSection,
  );
  const isProfileSection = useSelector(
    (state) => state.mainHeader.isProfileSection,
  );
  const landingData = useSelector((state) => state.landing.data);

  const partnersSorted = useMemo(
    () =>
      landingData
        ? orderBy(
            landingData.partners.map((item) => ({
              partner: item.partner.toUpperCase(),
              website: item.website,
            })),
            ['partner'],
            ['asc'],
          )
        : [],
    [landingData],
  );

  useEffect(() => {
    if (!landingData) {
      dispatch(fetchLandingData());
    }
  }, [dispatch, landingData]);

  return {
    isAdminSection,
    isProfileSection,
    partnersSorted,
  };
};

export default useMainFooter;
