import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_WATCHLIST_CALENDAR_EVENTS_SUCCESS,
  SAVE_WATCHLIST_CALENDAR_FETCHED_MONTHS,
  FETCH_WATCHLIST_UPCOMING_EVENTS_SUCCESS,
} from 'watchlist/redux/types';

const initialState = {
  events: [],
  fetchedMonths: [],
  isAnyUpcomingEvent: false,
};

const watchlistCalendarReducer = createReducer(
  { ...initialState },
  {
    [FETCH_WATCHLIST_CALENDAR_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      events: [...state.events, ...action.payload.events],
    }),
    [FETCH_WATCHLIST_UPCOMING_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      isAnyUpcomingEvent: action.payload.upcomingEvents.data.length > 0,
    }),
    [SAVE_WATCHLIST_CALENDAR_FETCHED_MONTHS]: (state, action) => ({
      ...state,
      fetchedMonths: [...state.fetchedMonths, action.payload.fetchedMonths],
    }),
  },
);

export default watchlistCalendarReducer;
