import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';
import {
  checkCookieAcceptation,
  setCookieAcceptation, setCookieRejection,
} from 'common/utils/cookiesUtils';

const { GET_COOKIE_ACCEPT, SET_COOKIE_ACCEPT } = types;

const getCookieAcceptationAction = createActionCreator(GET_COOKIE_ACCEPT);

const setCookieAcceptationAction = createActionCreator(SET_COOKIE_ACCEPT);

export const getCookieAcceptation = () => dispatch => {
  dispatch(getCookieAcceptationAction(checkCookieAcceptation()));
};

export const setCookiesAccept = () => dispatch => {
  setCookieAcceptation();

  dispatch(setCookieAcceptationAction(checkCookieAcceptation()));
};

export const setCookiesReject = () => dispatch => {
  setCookieRejection();

  dispatch(setCookieAcceptationAction(checkCookieAcceptation()));
};
