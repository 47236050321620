import React from 'react';
import './style.scss';

const Page404 = () => (
  <div className="error__wrapper">
    <div className="error__content">
      <h1 className="error__header">404</h1>
      <h3 className="error__info">Page not found</h3>
    </div>
  </div>
);

export default Page404;
