export const FETCH_FUNDS_MANAGERS_LIST_REQUEST =
  'FETCH_FUNDS_MANAGERS_LIST_REQUEST';
export const FETCH_FUNDS_MANAGERS_LIST_SUCCESS =
  'FETCH_FUNDS_MANAGERS_LIST_SUCCESS';
export const FETCH_FUNDS_MANAGERS_LIST_FAILURE =
  'FETCH_FUNDS_MANAGERS_LIST_FAILURE';

export const UPDATE_FUNDS_MANAGERS_LIST = 'UPDATE_FUNDS_MANAGERS_LIST';
export const CLEAR_FUNDS_MANAGERS_LIST = 'CLEAR_FUNDS_MANAGERS_LIST';

export const SAVE_FUNDS_MANAGERS_FILTERS = 'SAVE_FUNDS_MANAGERS_FILTERS';
export const REMOVE_FUNDS_MANAGERS_FILTERS = 'REMOVE_FUNDS_MANAGERS_FILTERS';
export const SAVE_FUNDS_MANAGERS_SEARCH = 'SAVE_FUNDS_MANAGERS_SEARCH';

export const SAVE_FUNDS_MANAGERS_MANAGEMENT_FEE_RANGE =
  'SAVE_FUNDS_MANAGERS_MANAGEMENT_FEE_RANGE';
export const SAVE_FUNDS_MANAGERS_PERFORMANCE_FEE_RANGE =
  'SAVE_FUNDS_MANAGERS_PERFORMANCE_FEE_RANGE';
export const SAVE_FUNDS_MANAGERS_DURATION_RANGE =
  'SAVE_FUNDS_MANAGERS_DURATION_RANGE';

export const FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_REQUEST =
  'FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_REQUEST';
export const FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_SUCCESS =
  'FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_SUCCESS';
export const FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_FAILURE =
  'FETCH_FUNDS_MANAGERS_LATEST_NEWS_LIST_FAILURE';
