import { useEffect } from 'react';
import setRequestInterceptor from 'common/utils/interceptorUtils';
import { fetchUserData } from 'common/redux/actions/userDataActions';
import { useDispatch, useSelector } from 'react-redux';

const MainDataLoader = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    setRequestInterceptor();
  }, []);

  useEffect(() => {
    if (token) dispatch(fetchUserData());
    // eslint-disable-next-line
  }, [dispatch]);

  return null;
};

export default MainDataLoader;
