import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_FAILURE,
  REMOVE_PROJECTS,
} from '../types';

const initialState = {
  list: [],
  nextPageIndex: null,
  isLoading: false,
};

const projectsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_PROJECTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.projects;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        isLoading: false,
      };
    },
    [FETCH_PROJECTS_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_PROJECTS_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [REMOVE_PROJECTS]: (state) => ({
      ...state,
      list: [],
      nextPageIndex: null,
      isLoading: false,
    }),
  },
);

export default projectsReducer;
