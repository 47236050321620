import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_SECTORS_LIST_SUCCESS } from 'common/redux/types';

const sectorsListReducer = createReducer(
  {},
  {
    [FETCH_SECTORS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),
  },
);

export default sectorsListReducer;
