export const FETCH_DEMO_COMPANIES_REQUEST = 'FETCH_DEMO_COMPANIES_REQUEST';
export const FETCH_DEMO_COMPANIES_SUCCESS = 'FETCH_DEMO_COMPANIES_SUCCESS';
export const FETCH_DEMO_COMPANIES_FAILURE = 'FETCH_DEMO_COMPANIES_FAILURE';

export const SAVE_DEMO_COMPANIES_FILTERS = 'SAVE_DEMO_COMPANIES_FILTERS';
export const SAVE_DEMO_COMPANIES_SEARCH = 'SAVE_DEMO_COMPANIES_SEARCH';
export const REMOVE_DEMO_COMPANIES_FILTERS = 'REMOVE_DEMO_COMPANIES_FILTERS';

export const FETCH_DEMO_STARTUPS_REQUEST = 'FETCH_DEMO_STARTUPS_REQUEST';
export const FETCH_DEMO_STARTUPS_SUCCESS = 'FETCH_DEMO_STARTUPS_SUCCESS';
export const FETCH_DEMO_STARTUPS_FAILURE = 'FETCH_DEMO_STARTUPS_FAILURE';

export const SAVE_DEMO_STARTUPS_FILTERS = 'SAVE_DEMO_STARTUPS_FILTERS';
export const SAVE_DEMO_STARTUPS_SEARCH = 'SAVE_DEMO_STARTUPS_SEARCH';
export const REMOVE_DEMO_STARTUPS_FILTERS = 'REMOVE_DEMO_STARTUPS_FILTERS';
