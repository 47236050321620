export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';
export const REMOVE_EVENTS = 'REMOVE_EVENTS';

export const FETCH_UPCOMING_EVENTS_REQUEST = 'FETCH_UPCOMING_EVENTS_REQUEST';
export const FETCH_UPCOMING_EVENTS_SUCCESS = 'FETCH_UPCOMING_EVENTS_SUCCESS';
export const FETCH_UPCOMING_EVENTS_FAILURE = 'FETCH_UPCOMING_EVENTS_FAILURE';

export const SAVE_EVENTS_DATE_RANGE = 'SAVE_EVENTS_DATE_RANGE';
export const SAVE_EVENTS_DATE = 'SAVE_EVENTS_DATE';
export const REMOVE_EVENTS_FILTERS = 'REMOVE_EVENTS_FILTERS';

export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENST_FAILURE';

export const REMOVE_CALENDAR_EVENTS = 'REMOVE_CALENDAR_EVENTS';

export const SAVE_EVENTS_PUBLICITY_CHECKBOX = 'SAVE_EVENTS_PUBLICITY_CHECKBOX';

export const SAVE_CALENDAR_FETCHED_MONTHS = 'SAVE_CALENDAR_FETCHED_MONTHS';
