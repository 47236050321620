import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { getNotifications } from 'common/api/notificationApi';
import shortid from 'shortid';
import placeholder from 'assets/img/placeholder.png';
import CircleSpinnser from 'common/components/circleSpinner';
import timeSince from 'common/utils/timeUtils';
import { fetchNews, fetchLatestNews } from 'news/redux/actions/newsActions';
import {
  fetchEvent,
  fetchLatestEvents,
} from 'event/redux/actions/eventActions';
import { fetchCompany } from 'common/redux/actions/companyActions';
import { fetchFund } from 'common/redux/actions/fundActions';
import { fetchFundsManager } from 'common/redux/actions/fundsManagerActions';
import { fetchStartup } from 'common/redux/actions/startupActions';
import './style.scss';

const mapDispatchToProps = (dispatch) => ({
  getLatestNews: bindActionCreators(fetchLatestNews, dispatch),
  getNews: bindActionCreators(fetchNews, dispatch),
  getLatestEvents: bindActionCreators(fetchLatestEvents, dispatch),
  getEvent: bindActionCreators(fetchEvent, dispatch),
  getCompany: bindActionCreators(fetchCompany, dispatch),
  getFund: bindActionCreators(fetchFund, dispatch),
  getFundsManager: bindActionCreators(fetchFundsManager, dispatch),
  getStartup: bindActionCreators(fetchStartup, dispatch),
});

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      notificationsList: [],
      nextPageIndex: null,
      isLoading: false,
      isClicked: false,
    };
  }

  componentWillUnmount() {
    if (this.state.isClicked) {
      this.props.clearIndicator();
    }
  }

  getCurrentNews = (id) => {
    this.props.getLatestNews(id);
    this.props.getNews(id);
  };

  getCurrentEvent = (id) => {
    this.props.getLatestEvents(id);
    this.props.getEvent(id);
  };

  showNotification = () => {
    this.setState(
      (prevState) => ({
        showNotification: !prevState.showNotification,
        isLoading: true,
        isClicked: true,
      }),
      () => {
        if (this.state.showNotification) {
          getNotifications().then((response) => {
            this.setState({
              notificationsList: response.data.data,
              isLoading: false,
              nextPageIndex:
                response.data.meta.current_page + 1 <=
                response.data.meta.last_page
                  ? response.data.meta.current_page + 1
                  : null,
            });
          });
        }
      },
    );
  };

  handleSeeMore = () => {
    getNotifications(this.state.nextPageIndex).then((response) => {
      this.setState((prevState) => ({
        notificationsList: [
          ...prevState.notificationsList,
          ...response.data.data,
        ],
        nextPageIndex:
          response.data.meta.current_page + 1 <= response.data.meta.last_page
            ? response.data.meta.current_page + 1
            : null,
      }));
    });
  };

  render() {
    const notificationContainerClasses = classNames({
      notification__container: true,
      'notification__container--cookies': !this.props.cookies,
    });

    const redirectToDestination = (element) => {
      if (element.destination === 'event') {
        this.props.history.push(`/events/${element.destination_id}`);
        this.getCurrentEvent(element.destination_id);
      }
      if (element.destination === 'news') {
        this.props.history.push(
          `/${element.destination}/${element.destination_id}`,
        );
        this.getCurrentNews(element.destination_id);
      }
      if (element.destination === 'company') {
        this.props.history.push(
          `/${element.destination}/${element.destination_id}`,
        );
        this.props.getCompany(element.destination_id);
      }
      if (element.destination === 'fund') {
        this.props.history.push(
          `/${element.destination}/${element.destination_id}`,
        );
        this.props.getFund(element.destination_id);
      }
      if (element.destination === 'funds_manager') {
        this.props.history.push(`/fundsmanager/${element.destination_id}`);
        this.props.getFundsManager(element.destination_id);
      }
      if (element.destination === 'startup') {
        this.props.history.push(`/startup/${element.destination_id}`);
        this.props.getStartup(element.destination_id);
      }
      if (element.destination === 'relations') {
        this.props.history.push(`/profile/relations`);
      }
      if (element.destination === 'user_settings') {
        this.props.history.push(`/profile/settings`);
      }
      if (element.destination === 'notifications' || !element.destination) {
        return false;
      }
      return false;
    };

    return (
      <div className="notification">
        <div
          onClick={this.showNotification}
          className="notification__icon"
          role="presentation"
        >
          {!this.state.isClicked && this.props.indicator > 0 ? (
            <div className="notification__icon--indicator">
              {this.props.indicator < 9 ? this.props.indicator : '9+'}
            </div>
          ) : (
            ' '
          )}
        </div>

        {this.state.showNotification && (
          <div className={notificationContainerClasses}>
            <div className="container__arrow" />
            <div className="container__content">
              {this.state.isLoading ? (
                <div className="content__loader">
                  <CircleSpinnser />
                </div>
              ) : (
                <>
                  {this.state.notificationsList.map((element) => (
                    <div
                      key={shortid.generate()}
                      onClick={
                        element.destination === 'notifications' ||
                        !element.destination
                          ? () => null
                          : () => redirectToDestination(element)
                      }
                      onKeyPress={
                        element.destination === 'notifications' ||
                        !element.destination
                          ? () => null
                          : () => redirectToDestination(element)
                      }
                      role="button"
                      tabIndex={
                        element.destination === 'notifications' ||
                        !element.destination
                          ? null
                          : '0'
                      }
                    >
                      <div
                        className={classNames({
                          content__item: true,
                          'content__item--disabled':
                            element.destination === 'notifications' ||
                            !element.destination,
                        })}
                      >
                        <div className="item__info">
                          <div
                            className="info__logo"
                            style={{
                              background: `url(${element.logo || placeholder})`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '64px',
                            }}
                          />
                          <div className="info__text">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: element.title,
                              }}
                            />
                          </div>
                        </div>
                        <div className="item__date">
                          {timeSince(element.timestamp)}
                          {' '}
                          ago.
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {this.state.nextPageIndex ? (
                <p
                  className="content__loadMore"
                  onClick={this.handleSeeMore}
                  role="presentation"
                >
                  Load more
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(withRouter(Notification));
