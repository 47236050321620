import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { removeUserAuthTokenAndResetState } from 'userAuth/redux/actions/authActions';
import { clearAuthData } from 'common/utils/authenticationUtils';
// import { getNotificationsIndicator } from 'common/api/notificationApi';

const useMainHeaderNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData.data);

  const isOnLandingPage = useSelector(
    (state) => state.mainHeader.isOnLandingPage,
  );
  const isOnLoginPage = useSelector((state) => state.mainHeader.isOnLoginPage);
  const [isOptionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [indicator, setIndicator] = useState(0);

  const toggleOptionsMenu = useCallback(
    () => setOptionsMenuOpen((value) => !value),
    [],
  );

  const getIndicator = useCallback(() => {
    // getNotificationsIndicator().then((response) => {
    //   setIndicator(response.data.indicator);
    // });
  }, []);

  useEffect(() => {
    getIndicator();
  }, [getIndicator, location]);

  const clearIndicator = useCallback(() => setIndicator(0), []);

  // NOTE: This method in some way duplicates part of the functionality
  // of toggleOptionsMenu method but it's used to make sure that when we click
  // outside the options menu to close it and in the same time we click on
  // username button nothing will happen
  const openOptionsMenu = useCallback(() => setOptionsMenuOpen(true), []);

  const handleLogOutClick = useCallback(() => {
    dispatch(removeUserAuthTokenAndResetState());
    clearAuthData();
    history.push('/');
  }, [dispatch, history]);

  return {
    isOnLandingPage,
    isOnLoginPage,
    isOptionsMenuOpen,
    indicator,
    clearIndicator,
    toggleOptionsMenu,
    handleLogOutClick,
    userData,
    openOptionsMenu,
  };
};

export default useMainHeaderNavigation;
