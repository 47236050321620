export const REMOVE_ADMIN_INDUSTRIES_FILTERS =
  'REMOVE_ADMIN_INDUSTRIES_FILTERS';
export const SAVE_ADMIN_INDUSTRIES_FILTERS = 'SAVE_ADMIN_INDUSTRIES_FILTERS';
export const SAVE_ADMIN_INDUSTRIES_SEARCH = 'SAVE_ADMIN_INDUSTRIES_SEARCH';

export const FETCH_ADMIN_INDUSTRIES_REQUEST = 'FETCH_ADMIN_INDUSTRIES_REQUEST';
export const FETCH_ADMIN_INDUSTRIES_FAILURE = 'FETCH_ADMIN_INDUSTRIES_FAILURE';

export const FETCH_ADMIN_INDUSTRIES_SUCCESS = 'FETCH_ADMIN_INDUSTRIES_SUCCESS';
export const UPDATE_ADMIN_INDUSTRIES_REQUESTS =
  'UPDATE_ADMIN_INDUSTRIES_REQUESTS';
