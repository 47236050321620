import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_ADMIN_NEWS_SUCCESS, REMOVE_ADMIN_NEWS } from 'adminPanel/redux/types';

const initialState = {
  currentNews: {},
};

const adminNewsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_NEWS_SUCCESS]: (state, action) => ({
      ...state,
      currentNews: action.payload.currentNews,
    }),
    [REMOVE_ADMIN_NEWS]: state => ({
      ...state,
      currentNews: {},
    }),
  },
);

export default adminNewsReducer;
