import React from 'react';
import { Container } from 'reactstrap';
import CircleSpinner from 'common/components/circleSpinner';
import './index.scss';

const ComponentLoadingIndicator = () => (
    <Container className="componentLoading">
        <div className="componentLoading__spinner-container">
            <CircleSpinner />
        </div>
    </Container>);

export default ComponentLoadingIndicator;