import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_STATISTICS_SUCCESS } from 'adminPanel/redux/types';

const initialState = {
  data: [],
};

const statisticsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STATISTICS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
  },
);

export default statisticsReducer;
