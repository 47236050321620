import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_FUND_SUCCESS, REMOVE_FUND } from 'common/redux/types';

const initialState = {
  currentFund: {},
};

const fundReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUND_SUCCESS]: (state, action) => ({
      ...state,
      currentFund: action.payload.currentFund,
    }),
    [REMOVE_FUND]: state => ({
      ...state,
      currentFund: {},
    }),
  },
);

export default fundReducer;
