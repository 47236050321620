import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUNDS_MANAGERS_REQUESTS_REQUEST,
  FETCH_FUNDS_MANAGERS_REQUESTS_SUCCESS,
  FETCH_FUNDS_MANAGERS_REQUESTS_FAILURE,
  UPDATE_FUNDS_MANAGERS_REQUESTS,
} from 'adminPanel/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: null,
  meta: {},
  isLoading: false,
};

const fundsManagersRequestsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUNDS_MANAGERS_REQUESTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        meta,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_FUNDS_MANAGERS_REQUESTS_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_FUNDS_MANAGERS_REQUESTS_FAILURE]: state => ({
      ...state,
      isLoading: false,
    }),
    [UPDATE_FUNDS_MANAGERS_REQUESTS]: (state, action) => ({
      ...state,
      list: action.payload.updatedFundsManagersRequests,
    }),
  },
);

export default fundsManagersRequestsReducer;
