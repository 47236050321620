export const SAVE_ADMIN_STARTUP_CATEGORIES_SEARCH =
  'SAVE_ADMIN_STARTUP_CATEGORIES_SEARCH';

export const FETCH_ADMIN_STARTUP_CATEGORIES_REQUEST =
  'FETCH_ADMIN_STARTUP_CATEGORIES_REQUEST';
export const FETCH_ADMIN_STARTUP_CATEGORIES_FAILURE =
  'FETCH_ADMIN_STARTUP_CATEGORIES_FAILURE';

export const FETCH_ADMIN_STARTUP_CATEGORIES_SUCCESS =
  'FETCH_ADMIN_STARTUP_CATEGORIES_SUCCESS';
export const UPDATE_ADMIN_STARTUP_CATEGORIES_REQUESTS =
  'UPDATE_ADMIN_STARTUP_CATEGORIES_REQUESTS';
