import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import Page404 from 'common/components/Page404';
import Page404Loader from 'common/components/Page404Loader';
import ComponentLoadingIndicator from 'common/components/componentLoadingIndicator';

// used for style time picker in admin panel. For some reason it cannot be loaded in lazy way so we need to import it here.
import 'antd/dist/antd.css';
import {
  showDefaultPageMainHeader,
  showLandingPageMainHeader,
  showLoginPageMainHeader,
} from 'common/components/mainHeader/redux/mainHeaderActions';
import {
  showAdminPageMainFooter,
  showProfilePageMainFooter,
} from 'common/components/mainFooter/redux/mainFooterActions';
import contactSection from './landing/containers/contactSection';

const Profile = React.lazy(() => import('profile'));
const AdminPanel = React.lazy(() => import('adminPanel'));
const Landing = React.lazy(() => import('landing/containers/landing'));
const UserAuth = React.lazy(() => import('userAuth/containers/userAuth'));
const UserAuthLogin = React.lazy(() =>
  import('userAuth/containers/userAuthLogin'),
);
const UserAuthRegister = React.lazy(() =>
  import('userAuth/containers/userAuthRegister'),
);
const UserRegisterSuccess = React.lazy(() =>
  import('userAuth/components/userRegisterSuccess'),
);
const UserResetSuccess = React.lazy(() =>
  import('userAuth/components/userResetSuccess'),
);
const UserLinkedInRegister = React.lazy(() =>
  import('userAuth/containers/userLinkedInRegister'),
);
const UserLinkedInAuth = React.lazy(() =>
  import('userAuth/containers/userLinkedInAuth'),
);
const UserAccountActive = React.lazy(() =>
  import('userAuth/containers/userAccountActive'),
);
const UserResetView = React.lazy(() =>
  import('userAuth/components/userResetView'),
);
const UserEmailChangeDecision = React.lazy(() =>
  import('userAuth/components/userEmailChangeDecision'),
);
const Companies = React.lazy(() => import('companies'));
const Watchlist = React.lazy(() => import('watchlist'));
const Startups = React.lazy(() => import('startups'));
const News = React.lazy(() => import('news/containers/news'));
const NewsSocialShare = React.lazy(() =>
  import('news/containers/newsSocialShare'),
);
const Project = React.lazy(() => import('project/containers/project'));
const ProjectSocialShare = React.lazy(() =>
  import('project/containers/projectSocialShare'),
);
const Event = React.lazy(() => import('event'));
const Company = React.lazy(() => import('company/containers/company'));
const LseCompanyPreview = React.lazy(() =>
  import('adminPanel/adminContent/lseCompanyPreview'),
);
const PrivacyPolicy = React.lazy(() =>
  import('common/components/privacyPolicy'),
);
const TermsConditions = React.lazy(() =>
  import('common/components/termsConditions'),
);
const Notifications = React.lazy(() =>
  import('common/components/notificationMobile/notificationView'),
);
const Career = React.lazy(() => import('career'));
const JobOffer = React.lazy(() => import('jobOffer'));
const JobApply = React.lazy(() => import('jobOffer/components/jobApply'));
const FundsManagers = React.lazy(() => import('fundsManagers'));
const FundsManager = React.lazy(() =>
  import('fundsManager/containers/fundsManager'),
);
const Startup = React.lazy(() => import('startup/containers/startup'));
const Fund = React.lazy(() => import('fund/containers/fund'));

const IndividualDemoLink = React.lazy(() => import('individualDemoLink'));

const PerformanceByIndustry = React.lazy(() => import('performanceByIndustry'));

const DefaultLayout = ({
  path,
  exact,
  strict,
  location,
  component: Component,
  isOnLandingPage,
  isOnLoginPage,
  isAdminSection,
  isProfileSection,
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOnLandingPage) {
      dispatch(showLandingPageMainHeader());
    } else if (isOnLoginPage) {
      dispatch(showLoginPageMainHeader());
    } else if (isAdminSection) {
      dispatch(showAdminPageMainFooter());
    } else if (isProfileSection) {
      dispatch(showProfilePageMainFooter());
    } else {
      dispatch(showDefaultPageMainHeader());
    }
  }, [
    dispatch,
    isOnLandingPage,
    isOnLoginPage,
    isAdminSection,
    isProfileSection,
  ]);

  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      location={location}
      render={(props) => <Component {...props} {...rest} />}
    />
  );
};

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location]);
  return (
    <Suspense fallback={<ComponentLoadingIndicator />}>
      <Switch>
        <DefaultLayout isOnLandingPage path="/" exact component={Landing} />
        <DefaultLayout
          isOnLoginPage
          path="/auth/:source?"
          exact
          component={UserAuth}
        />
        <DefaultLayout
          isOnLandingPage
          path="/registration-success"
          exact
          component={UserRegisterSuccess}
        />
        <DefaultLayout
          isOnLandingPage
          path="/reset-password"
          exact
          component={UserResetView}
        />
        <DefaultLayout
          isOnLandingPage
          path="/reset-success"
          exact
          component={UserResetSuccess}
        />
        <DefaultLayout
          isOnLandingPage
          path="/linkedin-auth"
          exact
          component={UserLinkedInAuth}
        />
        <DefaultLayout
          isOnLandingPage
          path="/linkedin-registration"
          exact
          component={UserLinkedInRegister}
        />
        <DefaultLayout
          isOnLoginPage
          path="/login"
          exact
          component={UserAuthLogin}
        />
        <DefaultLayout path="/notifications" exact component={Notifications} />
        <DefaultLayout path="/career" exact component={Career} />
        <DefaultLayout path="/job-offer/:id" exact component={JobOffer} />
        <DefaultLayout path="/apply-success" exact component={JobApply} />
        <DefaultLayout
          isOnLandingPage
          path="/registration"
          exact
          component={UserAuthRegister}
        />
        <DefaultLayout
          isOnLandingPage
          path="/account-activate"
          exact
          component={UserAccountActive}
        />
        <DefaultLayout
          isOnLandingPage
          path="/email-change/:aproval"
          exact
          component={UserEmailChangeDecision}
        />

        <DefaultLayout path="/companies" exact component={Companies} />
        <DefaultLayout path="/fundsmanagers" exact component={FundsManagers} />
        <DefaultLayout
          path="/performance-by-industry"
          exact
          component={PerformanceByIndustry}
        />
        <DefaultLayout
          path="/performance-by-industry/:symbol"
          exact
          component={PerformanceByIndustry}
        />
        <DefaultLayout path="/watchlist" exact component={Watchlist} />
        <DefaultLayout path="/startups" exact component={Startups} />
        <DefaultLayout path="/news/:id" exact component={News} />
        <DefaultLayout
          path="/news/:id/:socialShareKey"
          exact
          component={NewsSocialShare}
        />
        <DefaultLayout path="/project/:id" exact component={Project} />
        <DefaultLayout
          path="/project/:id/:socialShareKey"
          exact
          component={ProjectSocialShare}
        />
        <DefaultLayout
          isOnLandingPage
          path="/terms"
          exact
          component={TermsConditions}
        />
        <DefaultLayout
          isOnLandingPage
          path="/privacy"
          exact
          component={PrivacyPolicy}
        />
        <DefaultLayout
          isOnLandingPage
          path="/contact"
          exact
          component={contactSection}
        />
        <DefaultLayout path="/events/:id" exact component={Event} />
        <DefaultLayout path="/company/lse/:id" component={LseCompanyPreview} />
        <DefaultLayout path="/company/:id" component={Company} />
        <DefaultLayout path="/fundsmanager/:id" component={FundsManager} />
        <DefaultLayout path="/startup/:id" component={Startup} />
        <DefaultLayout path="/fund/:id" component={Fund} />

        <DefaultLayout isAdminSection path="/admin" component={AdminPanel} />
        <DefaultLayout isProfileSection path="/profile" component={Profile} />

        <Route exact path="/demo/key/:demoKey" component={IndividualDemoLink} />

        <DefaultLayout path="/404" component={Page404} />
        <Route component={Page404Loader} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
