import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PermissionRequired from 'permissions/components/permissionRequired';
import { PERMISSIONS_FUNCTION_TYPES } from 'userAuth/utils/permissionTypes';
import shortid from 'shortid';
import './index.scss';
import LoggedInOptionsNavigation from './LoggedInOptionsNavigation';
import useMainOptionsHeader from './useMainOptionsHeader';
import { useTheme } from "../../../shared/hooks";

const MainHeaderOptionsMenu = ({
  handleLogOutClick,
  toggleOptionsMenu,
  isOnLandingPage,
}) => {
  const { node } = useMainOptionsHeader(toggleOptionsMenu);

  const {theme} = useTheme()

  return (
    <div ref={node} className={`main-header-options-menu main-header-options-menu-${theme === 'dark' && theme}`}>
      <ul>
        {isOnLandingPage && (
          <LoggedInOptionsNavigation toggleOptionsMenu={toggleOptionsMenu} />
        )}

        <PermissionRequired
          permission={PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS}
        >
          <li
            key={shortid.generate()}
            className="main-header-options-menu__item"
          >
            <Link
              to="/profile/settings"
              onClick={toggleOptionsMenu}
              className="main-header-options-menu__link"
            >
              Profile
            </Link>
          </li>
        </PermissionRequired>
        <PermissionRequired
          permission={PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL}
        >
          <li
            key={shortid.generate()}
            className="main-header-options-menu__item"
          >
            <Link
              to="/admin/company/management"
              onClick={toggleOptionsMenu}
              className="main-header-options-menu__link"
            >
              Management
            </Link>
          </li>
        </PermissionRequired>
        <li
          key={shortid.generate()}
          className="main-header-options-menu__item main-header-options-menu__item--log-out"
        >
          <button
            onClick={() => {
              handleLogOutClick();
              toggleOptionsMenu();
            }}
            className="main-header-options-menu__log-out"
          >
            Log out
          </button>
        </li>
      </ul>
    </div>
  );
};
MainHeaderOptionsMenu.propTypes = {
  handleLogOutClick: PropTypes.func.isRequired,
  toggleOptionsMenu: PropTypes.func.isRequired,
};

export default MainHeaderOptionsMenu;
