import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_COUNTRY_CALLING_CODES_LIST_SUCCESS } from 'common/redux/types';

const countryCallingCodesListReducer = createReducer([], {
  [FETCH_COUNTRY_CALLING_CODES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
  }),
});

export default countryCallingCodesListReducer;
