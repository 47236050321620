import { createReducer } from 'common/utils/reduxUtils';
import EVENT_DATE_INFO_SHOW_LOCAL_DATE_TIME from './types';

const initialState = {
  showLocalDateTime: Object.prototype.hasOwnProperty.call(
    localStorage,
    EVENT_DATE_INFO_SHOW_LOCAL_DATE_TIME,
  )
    ? localStorage.getItem(EVENT_DATE_INFO_SHOW_LOCAL_DATE_TIME) === 'true'
    : true,
};

const eventDateInfoReducer = createReducer(
  { ...initialState },
  {
    [EVENT_DATE_INFO_SHOW_LOCAL_DATE_TIME]: (state, action) => ({
      ...state,
      showLocalDateTime: action.payload.showLocalDateTime,
    }),
  },
);

export default eventDateInfoReducer;
