import {
  SHOW_ADMIN_PAGE_MAIN_FOOTER,
  SHOW_PROFILE_PAGE_MAIN_FOOTER,
} from 'common/components/mainFooter/redux/mainFooterTypes';
import { createActionCreator } from 'common/utils/reduxUtils';

export const showAdminPageMainFooter = createActionCreator(
  SHOW_ADMIN_PAGE_MAIN_FOOTER,
);
export const showProfilePageMainFooter = createActionCreator(
  SHOW_PROFILE_PAGE_MAIN_FOOTER,
);
