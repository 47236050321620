import React, {useContext} from 'react';
import './index.scss';
import { ThemeContext } from 'providers/themeProvider';

const ThemeSwitch = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className={`theme-switch ${theme}`}>
            <input
                type="checkbox"
                id="theme-toggle"
                checked={theme === 'dark'}
                onChange={toggleTheme}
            />
            <label htmlFor="theme-toggle" className="switch">
                <span className="icon sun-icon">☀️</span>
                <span className="icon moon-icon">🌑</span>
            </label>
        </div>
    );
};

export default ThemeSwitch;
