export const REMOVE_RELATIONS_FILTERS = 'REMOVE_RELATIONS_FILTERS';
export const SAVE_RELATIONS_FILTERS = 'SAVE_RELATIONS_FILTERS';
export const SAVE_RELATIONS_SEARCH = 'SAVE_RELATIONS_SEARCH';

export const FETCH_RELATIONS_REQUEST = 'FETCH_RELATIONS_REQUEST';
export const FETCH_RELATIONS_FAILURE = 'FETCH_RELATIONS_FAILURE';

export const FETCH_RELATIONS_SUCCESS = 'FETCH_RELATIONS_SUCCESS';

export const UPDATE_RELATIONS_REQUESTS = 'UPDATE_RELATIONS_REQUESTS';
