import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_STARTUP_SUCCESS, REMOVE_STARTUP } from 'common/redux/types';

const initialState = {
  currentStartup: {},
};

const startupReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUP_SUCCESS]: (state, action) => ({
      ...state,
      currentStartup: action.payload.currentStartup,
    }),
    [REMOVE_STARTUP]: (state) => ({
      ...state,
      currentStartup: {},
    }),
  },
);

export default startupReducer;
