import { createReducer } from 'common/utils/reduxUtils';
import { SAVE_LSE_DATA_FILTERS } from 'adminPanel/adminContent/lseDataRequests/redux/types.js';

const initialState = {
  per_page: 10,
  showRejected: false,
};

const lseDataFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_LSE_DATA_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter.category) {
        const { category, value } = action.payload.filter;
        return {
          ...state,
          [category]: value,
        };
      }
      return {
        ...state,
      };
    },
  },
);

export default lseDataFiltersReducer;
