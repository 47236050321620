import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_SYMBOLS_LIST_REQUEST,
  FETCH_SYMBOLS_LIST_SUCCESS,
  FETCH_SYMBOLS_LIST_FAILURE,
  CLEAR_SYMBOLS_LIST,
} from 'common/redux/types';

const initialState = {
  list: [],
  isLoading: false,
};

const SymbolsListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_SYMBOLS_LIST_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_SYMBOLS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      list: action.payload.list,
    }),
    [FETCH_SYMBOLS_LIST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [CLEAR_SYMBOLS_LIST]: (state) => ({
      ...state,
      isLoading: false,
      list: [],
    }),
  },
);

export default SymbolsListReducer;
