import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_EVENT_SUCCESS, REMOVE_EVENT } from 'event/redux/types';
import { FETCH_LATEST_EVENTS_SUCCESS } from '../types';

const initialState = {
  currentEvent: {},
  latestEvents: [],
};

const eventReducer = createReducer(
  { ...initialState },
  {
    [FETCH_EVENT_SUCCESS]: (state, action) => ({
      ...state,
      currentEvent: action.payload.currentEvent,
    }),
    [REMOVE_EVENT]: state => ({
      ...state,
      currentEvent: {},
    }),

    [FETCH_LATEST_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      latestEvents: action.payload.latestEvents,
    }),
  },
);

export default eventReducer;
