import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_FAILURE,
  REMOVE_COMPANY,
} = types;

export const fetchCompanyRequest = createActionCreator(FETCH_COMPANY_REQUEST);
export const fetchCompanySuccess = createActionCreator(
  FETCH_COMPANY_SUCCESS,
  'currentCompany',
);
export const fetchCompanyFailure = createActionCreator(FETCH_COMPANY_FAILURE);

export const fetchCompany = (id) => (dispatch) => {
  dispatch(fetchCompanyRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/companies/${id}`)
    .then((response) => {
      dispatch(fetchCompanySuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchCompanyFailure());
    });
};

export const removeCompany = createActionCreator(REMOVE_COMPANY);
