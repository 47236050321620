import React from 'react';
import AuthenticationRequired from 'permissions/components/authenticationRequired';
import { PERMISSIONS_FUNCTION_TYPES } from 'userAuth/utils/permissionTypes';
import PermissionRequired from 'permissions/components/permissionRequired';
import { LinkContainer } from 'react-router-bootstrap';

const LoggedInHeaderNavigation = ({ toggleMobileNav, mainNavItemsClasses }) => {
  return (
    <AuthenticationRequired redirectFallback={null}>
      <PermissionRequired
        permission={PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST}
      >
        <li className={mainNavItemsClasses}>
          <LinkContainer
            to="/performance-by-industry"
            onClick={toggleMobileNav}
            className="main-nav__link"
            activeClassName="main-nav__link--active"
            offset={-100}
            data-text="performance-by-industry"
          >
            <span className="main-nav__link-text">Charts and analytics</span>
          </LinkContainer>
        </li>
      </PermissionRequired>
      <PermissionRequired
        permission={PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST}
      >
        <li className={mainNavItemsClasses}>
          <LinkContainer
            to="/watchlist"
            onClick={toggleMobileNav}
            className="main-nav__link"
            activeClassName="main-nav__link--active"
            offset={-100}
            data-text="watchlist"
          >
            <span className="main-nav__link-text"> Watchlist</span>
          </LinkContainer>
        </li>
      </PermissionRequired>
      <PermissionRequired
        permission={PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES}
      >
        <li className={mainNavItemsClasses}>
          <LinkContainer
            to="/companies"
            onClick={toggleMobileNav}
            className="main-nav__link"
            activeClassName="main-nav__link--active"
            offset={-100}
            data-text="companies"
          >
            <span className="main-nav__link-text"> Equities </span>
          </LinkContainer>
        </li>
      </PermissionRequired>
      <PermissionRequired
        permission={PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS}
      >
        <li className={mainNavItemsClasses}>
          <LinkContainer
            to="/fundsmanagers"
            onClick={toggleMobileNav}
            className="main-nav__link"
            activeClassName="main-nav__link--active"
            offset={-100}
            data-text="funds"
          >
            <span className="main-nav__link-text"> Funds </span>
          </LinkContainer>
        </li>
      </PermissionRequired>
      <PermissionRequired permission={PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS}>
        <li className={mainNavItemsClasses}>
          <LinkContainer
            to="/startups"
            onClick={toggleMobileNav}
            className="main-nav__link"
            activeClassName="main-nav__link--active"
            offset={-100}
            data-text="startups"
          >
            <span className="main-nav__link-text"> Startups </span>
          </LinkContainer>
        </li>
      </PermissionRequired>
    </AuthenticationRequired>
  );
};

export default LoggedInHeaderNavigation;
