import {
  SHOW_LANDING_PAGE_MAIN_HEADER,
  SHOW_LOGIN_PAGE_MAIN_HEADER,
  SHOW_DEFAULT_MAIN_HEADER,
} from 'common/components/mainHeader/redux/mainHeaderTypes';
import { createActionCreator } from 'common/utils/reduxUtils';

export const showLandingPageMainHeader = createActionCreator(
  SHOW_LANDING_PAGE_MAIN_HEADER,
);
export const showLoginPageMainHeader = createActionCreator(
  SHOW_LOGIN_PAGE_MAIN_HEADER,
);
export const showDefaultPageMainHeader = createActionCreator(
  SHOW_DEFAULT_MAIN_HEADER,
);
