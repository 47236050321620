import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUND_SUCCESS,
  REMOVE_ADMIN_FUND,
} from 'adminPanel/redux/types';

const initialState = {
  currentFund: {},
};

const adminFundReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUND_SUCCESS]: (state, action) => ({
      ...state,
      currentFund: action.payload.currentFund,
    }),
    [REMOVE_ADMIN_FUND]: state => ({
      ...state,
      currentFund: {},
    }),
  },
);

export default adminFundReducer;
