import { combineReducers } from 'redux';

import mainHeaderReducer from 'common/components/mainHeader/redux/mainHeaderReducer';
import adminRelationsFiltersReducer from 'adminPanel/adminContent/relations/redux/reducers/relationsFiltersReducer';
import adminRelationsReducer from 'adminPanel/adminContent/relations/redux/reducers/relationsReducer';
import adminStartupsRelationsFiltersReducer from 'adminPanel/adminContent/startups/relations/redux/reducers/relationsFiltersReducer';
import adminStartupsRelationsReducer from 'adminPanel/adminContent/startups/relations/redux/reducers/relationsReducer';
import adminFundsManagersRelationsFiltersReducer from 'adminPanel/adminContent/fundsManagers/relations/redux/reducers/relationsFiltersReducer';
import adminFundsManagersRelationsReducer from 'adminPanel/adminContent/fundsManagers/relations/redux/reducers/relationsReducer';
import adminCompaniesFiltersReducer from 'adminPanel/redux/reducers/adminCompaniesFiltersReducer';
import adminCompaniesListReducer from 'adminPanel/redux/reducers/adminCompaniesListReducer';
import adminCompanyReducer from 'adminPanel/redux/reducers/adminCompanyReducer';
import adminCompanyEventsReducer from 'adminPanel/redux/reducers/adminCompanyEventsReducer';
import adminCompanyNewsReducer from 'adminPanel/redux/reducers/adminCompanyNewsReducer';
import adminFundsManagersListReducer from 'adminPanel/redux/reducers/funds/adminFundsManagersListReducer';
import adminFundsManagerReducer from 'adminPanel/redux/reducers/funds/adminFundsManagersReducer';
import adminStartupReducer from 'adminPanel/redux/reducers/startups/adminStartupsReducer';
import adminFundsManagersFiltersReducer from 'adminPanel/redux/reducers/funds/adminFundsManagersFiltersReducer';
import adminStartupsFiltersReducer from 'adminPanel/redux/reducers/startups/adminStartupsFiltersReducer';
import adminFundsListReducer from 'adminPanel/redux/reducers/funds/adminFundsListReducer';
import adminStartupsListReducer from 'adminPanel/redux/reducers/startups/adminStartupsListReducer';
import adminFundReducer from 'adminPanel/redux/reducers/funds/adminFundReducer';
import adminNewsReducer from 'adminPanel/redux/reducers/adminNewsReducer';
import adminProjectReducer from 'adminPanel/redux/reducers/adminProjectReducer';
import adminUsersListReducer from 'adminPanel/redux/reducers/adminUsersListReducer';
import adminStatisticsReducer from 'adminPanel/redux/reducers/adminStatisticsReducer';
import adminFundStatisticsReducer from 'adminPanel/redux/reducers/funds/adminFundStatisticsReducer';
import adminStartupStatisticsReducer from 'adminPanel/redux/reducers/startups/adminStartupStatisticsReducer';
import authReducer from 'userAuth/redux/reducers/authReducer';
import companiesListReducer from 'companies/redux/reducers/companiesListReducer';
import companiesSectorsPerformanceReducer from 'companies/redux/reducers/companiesSectorsPerformanceReducer';
import companiesFiltersReducer from 'companies/redux/reducers/companiesFiltersReducer';
import companyReducer from 'common/redux/reducers/companyReducer';
import companiesRequestsReducer from 'adminPanel/redux/reducers/companiesRequestsReducer';
import companiesRequestsFiltersReducer from 'adminPanel/redux/reducers/companiesRequestsFiltersReducer';
import demoCompaniesReducer from 'adminPanel/adminContent/demo/redux/reducers/demoCompaniesReducer';
import demoCompaniesFiltersReducer from 'adminPanel/adminContent/demo/redux/reducers/demoCompaniesFiltersReducer';
import demoStartupsReducer from 'adminPanel/adminContent/demo/redux/reducers/demoStartupsReducer';
import demoStartupsFiltersReducer from 'adminPanel/adminContent/demo/redux/reducers/demoStartupsFiltersReducer';
import pushCompaniesReducer from 'adminPanel/adminContent/push/redux/reducers/pushCompaniesReducer';
import pushCompaniesFiltersReducer from 'adminPanel/adminContent/push/redux/reducers/pushCompaniesFiltersReducer';
import pushStartupsReducer from 'adminPanel/adminContent/push/redux/reducers/pushStartupsReducer';
import pushStartupsFiltersReducer from 'adminPanel/adminContent/push/redux/reducers/pushStartupsFiltersReducer';
import pushFundsManagersReducer from 'adminPanel/adminContent/push/redux/reducers/pushFundsManagersReducer';
import pushFundsManagersFiltersReducer from 'adminPanel/adminContent/push/redux/reducers/pushFundsManagersFiltersReducer';
import lseDataReducer from 'adminPanel/adminContent/lseDataRequests/redux/reducers/lseDataReducer';
import lseDataFiltersReducer from 'adminPanel/adminContent/lseDataRequests/redux/reducers/lseDataFiltersReducer';
import shareholdersReducer from 'adminPanel/adminContent/shareholders/redux/reducers/shareholdersReducer';
import shareholdersFiltersReducer from 'adminPanel/adminContent/shareholders/redux/reducers/shareholdersFiltersReducer';
import investorsReducer from 'adminPanel/adminContent/investors/redux/reducers/investorsReducer';
import investorsFiltersReducer from 'adminPanel/adminContent/investors/redux/reducers/investorsFiltersReducer';
import industriesReducer from 'adminPanel/adminContent/industries/redux/reducers/industriesReducer';
import industriesFiltersReducer from 'adminPanel/adminContent/industries/redux/reducers/industriesFiltersReducer';
import continentsListReducer from 'common/redux/reducers/continentsListReducer';
import countriesListReducer from 'common/redux/reducers/countriesListReducer';
import countryCallingCodesListReducer from 'common/redux/reducers/countryCallingCodesListReducer';
import companiesNamesReducer from 'common/redux/reducers/companiesNamesReducer';
import dataPreviewReducer from 'profile/settings/redux/reducers/dataPreviewReducer';
import exchangesListReducer from 'common/redux/reducers/exchangesListReducer';
import industriesListReducer from 'common/redux/reducers/industriesListReducer';
import landingReducer from 'common/redux/reducers/landingReducer';
import locationDataReducer from 'common/redux/reducers/locationDataReducer';
import newsReducer from 'news/redux/reducers/newsReducer';
import newsListReducer from 'newsList/redux/reducers/newsListReducer';
import projectReducer from 'project/redux/reducers/projectReducer';
import companyHistoricalDataReducer from 'common/redux/reducers/companyHistoricalDataReducer';
import companyTeamReducer from 'common/redux/reducers/companyTeamReducer';
import otherCompaniesReducer from 'company/redux/reducers/otherCompaniesReducer';
import sectorsListReducer from 'common/redux/reducers/sectorsListReducer';
import eventReducer from 'event/redux/reducers/eventReducer';
import eventsReducer from 'events/redux/reducers/eventsReducer';
import eventsFiltersReducer from 'events/redux/reducers/eventsFiltersReducer';
import eventsCalendarReducer from 'events/redux/reducers/eventsCalendarReducer';
import upcomingEventsReducer from 'events/redux/reducers/upcomingEventsReducer';
import projectsReducer from 'projects/redux/reducers/projectsReducer';
import symbolsListReducer from 'common/redux/reducers/symbolsListReducer';
import watchlistReducer from 'watchlist/redux/reducers/watchlistReducer';
import watchlistFiltersReducer from 'watchlist/redux/reducers/watchlistFiltersReducer';
import usersFiltersReducer from 'adminPanel/adminContent/usersManagement/redux/reducer/usersFiltersReducer';
import statisticsFiltersReducer from 'adminPanel/adminContent/statistics/redux/reducer/statisticsFiltersReducer';
import fundStatisticsFiltersReducer from 'adminPanel/adminContent/fundsStatistics/redux/reducer/statisticsFiltersReducer';
import startupStatisticsFiltersReducer from 'adminPanel/adminContent/startupsStatistics/redux/reducer/statisticsFiltersReducer';
import cookiesReducer from 'common/redux/reducers/cookiesReducer';
import userDataReducer from 'common/redux/reducers/userDataReducer';
import watchlistCalendarReducer from 'watchlist/redux/reducers/watchlistCalendarReducer';
import watchlistLatestNewsListReducer from 'watchlist/redux/reducers/watchlistLatestNewsListReducer';
import offersReducer from 'common/redux/reducers/offersReducer';
import offersFiltersReducer from 'common/redux/reducers/offersFiltersReducer';
import relationsReducer from 'profile/relations/redux/reducers/relationsReducer';
import relationsFiltersReducer from 'profile/relations/redux/reducers/relationsFiltersReducer';
import requestsReducer from 'profile/requests/redux/reducers/requestsReducer';
import requestsFiltersReducer from 'profile/requests/redux/reducers/requestsFiltersReducer';
import {
  allPhotosReducer,
  galleryReducer,
} from 'gallery/redux/reducers/galleryReducer';
import {
  allStartupPhotosReducer,
  startupGalleryReducer,
} from 'common/redux/reducers/startupGalleryReducer';
import userDetailsFiltersReducer from 'adminPanel/adminContent/usersManagement/redux/reducer/userDetailsFiltersReducer';
import userDetailsListReducer from 'adminPanel/redux/reducers/userDetailsListReducer';
import { REMOVE_USER_AUTH_TOKEN } from 'userAuth/redux/types';
import fundsManagersListReducer from 'fundsManagers/redux/reducers/fundsManagersListReducer';
import fundsManagersLatestNewsListReducer from 'fundsManagers/redux/reducers/fundsManagersLatestNewsListReducer';
import fundsManagersFiltersReducer from 'fundsManagers/redux/reducers/fundsManagersFiltersReducer';
import fundTypesListReducer from 'common/redux/reducers/fundTypesListReducer';
import startupsListReducer from 'startups/redux/reducers/startupsListReducer';
import startupsLatestNewsListReducer from 'startups/redux/reducers/startupsLatestNewsListReducer';
import startupsFiltersReducer from 'startups/redux/reducers/startupsFiltersReducer';
import startupCategoriesListReducer from 'common/redux/reducers/startupCategoriesListReducer';
import otherFundsManagersReducer from 'fundsManager/redux/reducers/otherFundsManagersReducer';
import otherStartupsReducer from 'startup/redux/reducers/otherStartupsReducer';
import fundsListReducer from 'funds/redux/reducers/fundsListReducer';
import otherFundsReducer from 'fund/redux/reducers/otherFundsReducer';
import currenciesListReducer from 'common/redux/reducers/currenciesListReducer';
import fundReducer from 'common/redux/reducers/fundReducer';
import fundsManagerReducer from 'common/redux/reducers/fundsManagerReducer';
import startupReducer from 'common/redux/reducers/startupReducer';
import adminFundNewsReducer from 'adminPanel/redux/reducers/funds/adminFundNewsReducer';
import adminFundsManagerNewsReducer from 'adminPanel/redux/reducers/funds/adminFundsManagerNewsReducer';
import adminStartupNewsReducer from 'adminPanel/redux/reducers/startups/adminStartupNewsReducer';
import adminStartupProjectsReducer from 'adminPanel/redux/reducers/startups/adminStartupProjectsReducer';
import fundsManagersRequestsReducer from 'adminPanel/redux/reducers/funds/fundsManagersRequestsReducer';
import fundsManagersRequestsFiltersReducer from 'adminPanel/redux/reducers/funds/fundsManagersRequestsFiltersReducer';
import startupsRequestsReducer from 'adminPanel/redux/reducers/startups/startupsRequestsReducer';
import startupsRequestsFiltersReducer from 'adminPanel/redux/reducers/startups/startupsRequestsFiltersReducer';
import adminFundEventsReducer from 'adminPanel/redux/reducers/funds/adminFundEventsReducer';
import adminFundsManagerEventsReducer from 'adminPanel/redux/reducers/funds/adminFundsManagerEventsReducer';
import adminStartupEventsReducer from 'adminPanel/redux/reducers/startups/adminStartupEventsReducer';
import adminFundsFiltersReducer from 'adminPanel/redux/reducers/funds/adminFundsFiltersReducer';
import fundTypesReducer from 'adminPanel/adminContent/fundTypes/redux/reducers/fundTypesReducer';
import fundTypesFiltersReducer from 'adminPanel/adminContent/fundTypes/redux/reducers/fundTypesFiltersReducer';
import startupCategoriesReducer from 'adminPanel/adminContent/startupCategories/redux/reducers/startupCategoriesReducer';
import startupCategoriesFiltersReducer from 'adminPanel/adminContent/startupCategories/redux/reducers/startupCategoriesFiltersReducer';
import fundsManagersNamesReducer from 'common/redux/reducers/fundsManagersNamesReducer';
import startupsNamesReducer from 'common/redux/reducers/startupsNamesReducer';
import fundsNamesReducer from 'common/redux/reducers/fundsNamesReducer';
import fundManagersWithFundsReducer from 'common/redux/reducers/fundManagersWithFundsReducer';
import eventDateInfoReducer from 'common/components/eventDateInfo/redux/eventDateInfoReducer';
import employeesListReducer from 'team/redux/reducers/employeesListReducer';
import documentsReducer from 'documents/redux/reducers/documentsReducer';
import chartsDataReducer from 'charts/redux/reducers/chartsDataReducer';
import fundsManagerTeamReducer from './fundsManagerTeamReducer';
import fundsManagerDocumentsReducer from './fundsManagerDocumentsReducer';
import fundDocumentsReducer from './fundDocumentsReducer';
import startupDocumentsReducer from './startupDocumentsReducer';
import startupTeamReducer from './startupTeamReducer';

const appReducer = combineReducers({
  mainHeader: mainHeaderReducer,
  documents: documentsReducer,
  gallery: galleryReducer,
  allPhotos: allPhotosReducer,
  startupGallery: startupGalleryReducer,
  allStartupPhotos: allStartupPhotosReducer,
  adminRelations: adminRelationsReducer,
  adminRelationsFilters: adminRelationsFiltersReducer,
  adminFundsManagersRelations: adminFundsManagersRelationsReducer,
  adminFundsManagersRelationsFilters: adminFundsManagersRelationsFiltersReducer,
  adminStartupsRelations: adminStartupsRelationsReducer,
  adminStartupsRelationsFilters: adminStartupsRelationsFiltersReducer,
  adminCompanies: adminCompaniesListReducer,
  adminCompaniesFilters: adminCompaniesFiltersReducer,
  adminCompany: adminCompanyReducer,
  adminFundsManagers: adminFundsManagersListReducer,
  adminFundsManagersFilters: adminFundsManagersFiltersReducer,
  adminStartupsFilters: adminStartupsFiltersReducer,
  adminFundsManager: adminFundsManagerReducer,
  adminStartup: adminStartupReducer,
  adminFunds: adminFundsListReducer,
  adminStartups: adminStartupsListReducer,
  adminFundsFilters: adminFundsFiltersReducer,
  adminFund: adminFundReducer,
  adminFundNews: adminFundNewsReducer,
  adminFundsManagerNews: adminFundsManagerNewsReducer,
  adminStartupNews: adminStartupNewsReducer,
  adminStartupProjects: adminStartupProjectsReducer,
  adminFundEvents: adminFundEventsReducer,
  adminFundsManagerEvents: adminFundsManagerEventsReducer,
  adminStartupEvents: adminStartupEventsReducer,
  adminCompanyEvents: adminCompanyEventsReducer,
  adminCompanyNews: adminCompanyNewsReducer,
  adminNews: adminNewsReducer,
  adminProject: adminProjectReducer,
  adminUsers: adminUsersListReducer,
  chartsData: chartsDataReducer,
  userDetails: userDetailsListReducer,
  adminStatistics: adminStatisticsReducer,
  adminFundStatistics: adminFundStatisticsReducer,
  adminStartupStatistics: adminStartupStatisticsReducer,
  auth: authReducer,
  relations: relationsReducer,
  relationsFilters: relationsFiltersReducer,
  requests: requestsReducer,
  requestsFilters: requestsFiltersReducer,
  companies: companiesListReducer,
  sectorsPerformance: companiesSectorsPerformanceReducer,
  companyTeam: companyTeamReducer,
  fundsManagerTeam: fundsManagerTeamReducer,
  startupTeam: startupTeamReducer,
  companiesFilters: companiesFiltersReducer,
  companiesRequests: companiesRequestsReducer,
  companiesRequestsFilters: companiesRequestsFiltersReducer,
  fundsManagers: fundsManagersListReducer,
  fundsManagersLatestNews: fundsManagersLatestNewsListReducer,
  startups: startupsListReducer,
  startupsLatestNews: startupsLatestNewsListReducer,
  startupsFilters: startupsFiltersReducer,
  funds: fundsListReducer,
  employees: employeesListReducer,
  fundsManagersFilters: fundsManagersFiltersReducer,
  fundsManagersRequests: fundsManagersRequestsReducer,
  fundsManagersRequestsFilters: fundsManagersRequestsFiltersReducer,
  startupsRequests: startupsRequestsReducer,
  startupsRequestsFilters: startupsRequestsFiltersReducer,
  dataPreview: dataPreviewReducer,
  demoCompanies: demoCompaniesReducer,
  demoCompaniesFilters: demoCompaniesFiltersReducer,
  demoStartups: demoStartupsReducer,
  demoStartupsFilters: demoStartupsFiltersReducer,
  pushCompanies: pushCompaniesReducer,
  pushCompaniesFilters: pushCompaniesFiltersReducer,
  pushStartups: pushStartupsReducer,
  pushStartupsFilters: pushStartupsFiltersReducer,
  pushFundsManagers: pushFundsManagersReducer,
  pushFundsManagersFilters: pushFundsManagersFiltersReducer,
  lseData: lseDataReducer,
  lseDataFilters: lseDataFiltersReducer,
  shareholders: shareholdersReducer,
  shareholdersFilters: shareholdersFiltersReducer,
  investors: investorsReducer,
  investorsFilters: investorsFiltersReducer,
  industriesAdmin: industriesReducer,
  industriesFilters: industriesFiltersReducer,
  fundTypesAdmin: fundTypesReducer,
  fundTypesFilters: fundTypesFiltersReducer,
  startupCategoriesAdmin: startupCategoriesReducer,
  startupCategoriesFilters: startupCategoriesFiltersReducer,
  companyHistoricalData: companyHistoricalDataReducer,
  fundsManagerDocuments: fundsManagerDocumentsReducer,
  startupDocuments: startupDocumentsReducer,
  fundDocuments: fundDocumentsReducer,
  company: companyReducer,
  fundsManager: fundsManagerReducer,
  fund: fundReducer,
  startup: startupReducer,
  continents: continentsListReducer,
  countries: countriesListReducer,
  countryCallingCodes: countryCallingCodesListReducer,
  fundTypes: fundTypesListReducer,
  startupCategories: startupCategoriesListReducer,
  currencies: currenciesListReducer,
  companiesNames: companiesNamesReducer,
  fundsManagersNames: fundsManagersNamesReducer,
  startupsNames: startupsNamesReducer,
  fundsNames: fundsNamesReducer,
  fundManagersWithFunds: fundManagersWithFundsReducer,
  exchanges: exchangesListReducer,
  industries: industriesListReducer,
  landing: landingReducer,
  locationData: locationDataReducer,
  newsList: newsListReducer,
  news: newsReducer,
  project: projectReducer,
  otherCompanies: otherCompaniesReducer,
  otherFundsManagers: otherFundsManagersReducer,
  otherStartups: otherStartupsReducer,
  otherFunds: otherFundsReducer,
  event: eventReducer,
  events: eventsReducer,
  eventsFilters: eventsFiltersReducer,
  eventsCalendar: eventsCalendarReducer,
  upcomingEvents: upcomingEventsReducer,
  projects: projectsReducer,
  sectors: sectorsListReducer,
  symbols: symbolsListReducer,
  watchlist: watchlistReducer,
  watchlistCalendar: watchlistCalendarReducer,
  watchlistLatestNews: watchlistLatestNewsListReducer,
  watchlistFilters: watchlistFiltersReducer,
  usersFilters: usersFiltersReducer,
  userDetailsFilters: userDetailsFiltersReducer,
  statisticsFilters: statisticsFiltersReducer,
  fundStatisticsFilters: fundStatisticsFiltersReducer,
  startupStatisticsFilters: startupStatisticsFiltersReducer,
  cookies: cookiesReducer,
  userData: userDataReducer,
  offers: offersReducer,
  offersFilters: offersFiltersReducer,
  eventDateInfo: eventDateInfoReducer,
});

const rootReducer = (state, action) => {
  let getState = state;
  if (action.type === REMOVE_USER_AUTH_TOKEN) {
    getState = {
      landing: state.landing,
    };
  }

  return appReducer(getState, action);
};

export default rootReducer;
