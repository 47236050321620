import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';
import rootReducer from 'common/redux/reducers/rootReducer';
import { loadState, saveState } from './stateLoader';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  loadState(),
  composeEnhancers(applyMiddleware(thunk)),
);

store.subscribe(
  throttle(() => {
    saveState({
      expireTime: new Date().getTime() + 24 * 3600000,
      state: {
        userData: store.getState().userData,
        auth: store.getState().auth,
      },
    });
  }, 30000),
);

export default store;
