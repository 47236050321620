import { createReducer } from 'common/utils/reduxUtils';
import { SAVE_ADMIN_STARTUP_CATEGORIES_SEARCH } from 'adminPanel/adminContent/startupCategories/redux/types';

const initialState = {
  sector: [],
  search: '',
  per_page: 10,
};

const StartupCategoriesFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_ADMIN_STARTUP_CATEGORIES_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default StartupCategoriesFiltersReducer;
