import { createReducer } from 'common/utils/reduxUtils';
import { SET_COOKIE_ACCEPT } from 'common/redux/types';

const cookiesReducer = createReducer(
  {},
  {
    [SET_COOKIE_ACCEPT]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
);

export default cookiesReducer;
