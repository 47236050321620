export const checkCookieAcceptation = () => {
  if (typeof window !== 'undefined') {
    return (
      document.cookie.replace(
        /(?:(?:^|.*;\s*)acceptCookies\s*=\s*([^;]*).*$)|^.*$/,
        '$1',
      ) === 'true'
    );
  }
  return false;
};

export const setCookieAcceptation = () => {
  if (typeof window !== 'undefined') {
    document.cookie =
      'acceptCookies=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }
};

export const setCookieRejection = () => {
  if (typeof window !== 'undefined') {
    document.cookie =
        'acceptCookies=false; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  }
};
