import axios from 'axios';
import * as types from 'news/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAILURE,
  REMOVE_NEWS,
  FETCH_LATEST_NEWS_REQUEST,
  FETCH_LATEST_NEWS_SUCCESS,
  FETCH_LATEST_NEWS_FAILURE,
} = types;

const fetchNewsRequest = createActionCreator(FETCH_NEWS_REQUEST);
const fetchNewsSuccess = createActionCreator(FETCH_NEWS_SUCCESS, 'currentNews');
const fetchNewsFailure = createActionCreator(FETCH_NEWS_FAILURE);

const fetchLatestNewsRequest = createActionCreator(FETCH_LATEST_NEWS_REQUEST);
const fetchLatestNewsSuccess = createActionCreator(
  FETCH_LATEST_NEWS_SUCCESS,
  'latestNews',
);
const fetchLatestNewsFailure = createActionCreator(FETCH_LATEST_NEWS_FAILURE);

export const fetchNews = id => dispatch => {
  dispatch(fetchNewsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/news/${id}`)
    .then(response => {
      dispatch(fetchNewsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchNewsFailure());
    });
};

export const fetchLatestNews = id => dispatch => {
  dispatch(fetchLatestNewsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/news/${id}/latest`)
    .then(response => {
      dispatch(fetchLatestNewsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchLatestNewsFailure());
    });
};

export const removeNews = createActionCreator(REMOVE_NEWS);

export const getApiSocialShareKey = (id, shareKey) =>
  axios.get(
    `${process.env.REACT_APP_API_URL}/news/${id}/checkKey/${shareKey}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );

export const fetchNewsSocialShare = (id, shareKey) => dispatch => {
  dispatch(fetchNewsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/news/${id}/${shareKey}`)
    .then(response => {
      dispatch(fetchNewsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchNewsFailure());
    });
};

export const fetchLatestNewsSocialShare = (id, shareKey) => dispatch => {
  dispatch(fetchLatestNewsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/news/${id}/latest/${shareKey}`)
    .then(response => {
      dispatch(fetchLatestNewsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchLatestNewsFailure());
    });
};
