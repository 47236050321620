export const REMOVE_USER_REQUESTS_FILTERS = 'REMOVE_USER_REQUESTS_FILTERS';
export const SAVE_USER_REQUESTS_FILTERS = 'SAVE_USER_REQUESTS_FILTERS';
export const SAVE_USER_REQUESTS_SEARCH = 'SAVE_USER_REQUESTS_SEARCH';

export const FETCH_USER_REQUESTS_REQUEST = 'FETCH_USER_REQUESTS_REQUEST';
export const FETCH_USER_REQUESTS_FAILURE = 'FETCH_USER_REQUESTS_FAILURE';

export const FETCH_USER_REQUESTS_SUCCESS = 'FETCH_USER_REQUESTS_SUCCESS';

export const UPDATE_USER_REQUESTS_REQUESTS = 'UPDATE_USER_REQUESTS_REQUESTS';
