import { useMemo } from 'react';
import classNames from 'classnames';

const useClasses = (cookies, isMobileNavOpen, isMobileViewActive) => {
  const mainHeaderWrapperClasses = useMemo(
    () =>
      classNames({
        'main-header-wrapper': true,
        'cookie-msg-displayed': !cookies,
      }),
    [cookies],
  );

  const mainHeaderClasses = useMemo(
    () =>
      classNames({
        'main-header': true,
        'cookie-msg-displayed': !cookies,
      }),
    [cookies],
  );

  const menuButtonClasses = useMemo(
    () =>
      classNames({
        'menu-button': true,
        'menu-button--close': isMobileNavOpen,
      }),
    [isMobileNavOpen],
  );

  const mainNavListClasses = useMemo(
    () =>
      classNames({
        'main-nav__list': true,
        'main-nav__list--open': isMobileNavOpen && isMobileViewActive,
        'main-nav__list--close': !isMobileNavOpen && isMobileViewActive,
        'cookies-msg-displayed': !cookies,
      }),
    [isMobileNavOpen, isMobileViewActive, cookies],
  );

  const mainNavItemsClasses = useMemo(
    () =>
      classNames({
        'main-nav__item': true,
        'main-nav__item--visible': isMobileNavOpen,
      }),
    [isMobileNavOpen],
  );

  return {
    mainHeaderWrapperClasses,
    mainHeaderClasses,
    menuButtonClasses,
    mainNavListClasses,
    mainNavItemsClasses,
  };
};

export default useClasses;
