import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthentication } from 'permissions/hooks';

const AuthenticationRequired = ({ redirectFallback = '/auth', children }) => {
  const { isAuthenticated } = useAuthentication();
  return isAuthenticated ? (
    children
  ) : redirectFallback ? (
    <Redirect to={redirectFallback} />
  ) : null;
};

export default AuthenticationRequired;
