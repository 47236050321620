export const SAVE_STARTUPS_STATISTICS_FILTERS =
  'SAVE_STARTUPS_STATISTICS_FILTERS';
export const REMOVE_STARTUPS_STATISTICS_FILTERS =
  'REMOVE_STARTUPS_STATISTICS_FILTERS';

export const SAVE_STARTUPS_STATISTICS_RELATION =
  'SAVE_STARTUPS_STATISTICS_RELATION';
export const SAVE_STARTUPS_STATISTICS_DATE_RANGE =
  'SAVE_STARTUPS_STATISTICS_DATE_RANGE';
export const SAVE_STARTUPS_STATISTICS_REGION =
  'SAVE_STARTUPS_STATISTICS_REGION';
