import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { userPermissionsSelector } from 'permissions/selectors';

const usePermissions = () => {
  const userPermissions = useSelector(userPermissionsSelector, shallowEqual);

  const checkUserPermission = useCallback(
    (permission) =>
      Array.isArray(permission)
        ? permission.some((item) => userPermissions.includes(item))
        : userPermissions.includes(permission),
    [userPermissions],
  );

  return { checkUserPermission };
};

const useUserTypes = () => {
  const userData = useSelector((state) => state.userData.data);

  const checkUserType = useCallback(
    (userType) =>
      userData &&
      (Array.isArray(userType)
        ? userType.some((item) => userData[item])
        : userData[userType]),
    [userData],
  );

  return { checkUserType };
};

const useAuthentication = () => {
  const userData = useSelector((state) => state.userData.data);
  const token = useSelector((state) => state.auth.token);

  const isAuthenticated = token && userData;

  return { isAuthenticated };
};

export { usePermissions, useUserTypes, useAuthentication };
