import React from 'react';
import './index.scss';

const GooglePlayButton = () => (
  <a
    className="google-play-button"
    href="https://play.google.com/store/apps/details?id=com.sharesinside.android"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span>Get it on Google Play</span>
  </a>
);

export default GooglePlayButton;
