export const FETCH_ADMIN_COMPANIES_LIST_REQUEST =
  'FETCH_ADMIN_COMPANIES_LIST_REQUEST';
export const FETCH_ADMIN_COMPANIES_LIST_SUCCESS =
  'FETCH_ADMIN_COMPANIES_LIST_SUCCESS';
export const FETCH_ADMIN_COMPANIES_LIST_FAILURE =
  'FETCH_ADMIN_COMPANIES_LIST_FAILURE';

export const UPDATE_ADMIN_COMPANIES_LIST = 'UPDATE_ADMIN_COMPANIES_LIST';

export const FETCH_ADMIN_USERS_LIST_REQUEST = 'FETCH_ADMIN_USERS_LIST_REQUEST';
export const FETCH_ADMIN_USERS_LIST_SUCCESS = 'FETCH_ADMIN_USERS_LIST_SUCCESS';
export const FETCH_ADMIN_USERS_LIST_FAILURE = 'FETCH_ADMIN_USERS_LIST_FAILURE';

export const FETCH_STATISTICS_REQUEST = 'FETCH_STATISTICS_REQUEST';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const FETCH_STATISTICS_FAILURE = 'FETCH_STATISTICS_FAILURE';

export const FETCH_FUND_STATISTICS_REQUEST = 'FETCH_FUND_STATISTICS_REQUEST';
export const FETCH_FUND_STATISTICS_SUCCESS = 'FETCH_FUND_STATISTICS_SUCCESS';
export const FETCH_FUND_STATISTICS_FAILURE = 'FETCH_FUND_STATISTICS_FAILURE';

export const FETCH_COMPANIES_REQUESTS_REQUEST =
  'FETCH_COMPANIES_REQUESTS_REQUEST';
export const FETCH_COMPANIES_REQUESTS_SUCCESS =
  'FETCH_COMPANIES_REQUESTS_SUCCESS';
export const FETCH_COMPANIES_REQUESTS_FAILURE =
  'FETCH_COMPANIES_REQUESTS_FAILURE';

export const UPDATE_COMPANIES_REQUESTS = 'UPDATE_COMPANIES_REQUESTS';

export const FETCH_FUNDS_MANAGERS_REQUESTS_REQUEST =
  'FETCH_FUNDS_MANAGERS_REQUESTS_REQUEST';
export const FETCH_FUNDS_MANAGERS_REQUESTS_SUCCESS =
  'FETCH_FUNDS_MANAGERS_REQUESTS_SUCCESS';
export const FETCH_FUNDS_MANAGERS_REQUESTS_FAILURE =
  'FETCH_FUNDS_MANAGERS_REQUESTS_FAILURE';

export const UPDATE_FUNDS_MANAGERS_REQUESTS = 'UPDATE_FUNDS_MANAGERS_REQUESTS';

export const REMOVE_ADMIN_COMPANIES_FILTERS = 'REMOVE_ADMIN_COMPANIES_FILTERS';
export const SAVE_ADMIN_COMPANIES_FILTERS = 'SAVE_ADMIN_COMPANIES_FILTERS';
export const SAVE_ADMIN_COMPANIES_SEARCH = 'SAVE_ADMIN_COMPANIES_SEARCH';

export const REMOVE_COMPANIES_REQUESTS_FILTERS =
  'REMOVE_COMPANIES_REQUESTS_FILTERS';
export const SAVE_COMPANIES_REQUESTS_FILTERS =
  'SAVE_COMPANIES_REQUESTS_FILTERS';
export const SAVE_COMPANIES_REQUESTS_SEARCH = 'SAVE_COMPANIES_REQUESTS_SEARCH';

export const REMOVE_FUNDS_MANAGERS_REQUESTS_FILTERS =
  'REMOVE_FUNDS_MANAGERS_REQUESTS_FILTERS';
export const SAVE_FUNDS_MANAGERS_REQUESTS_FILTERS =
  'SAVE_FUNDS_MANAGERS_REQUESTS_FILTERS';
export const SAVE_FUNDS_MANAGERS_REQUESTS_SEARCH =
  'SAVE_FUNDS_MANAGERS_REQUESTS_SEARCH';

export const FETCH_ADMIN_COMPANY_NEWS_REQUEST =
  'FETCH_ADMIN_COMPANY_NEWS_REQUEST';
export const FETCH_ADMIN_COMPANY_NEWS_SUCCESS =
  'FETCH_ADMIN_COMPANY_NEWS_SUCCESS';
export const FETCH_ADMIN_COMPANY_NEWS_FAILURE =
  'FETCH_ADMIN_COMPANY_NEWS_FAILURE';
export const REMOVE_ADMIN_COMPANY_NEWS = 'REMOVE_ADMIN_COMPANY_NEWS';

export const FETCH_ADMIN_FUND_NEWS_REQUEST = 'FETCH_ADMIN_FUND_NEWS_REQUEST';
export const FETCH_ADMIN_FUND_NEWS_SUCCESS = 'FETCH_ADMIN_FUND_NEWS_SUCCESS';
export const FETCH_ADMIN_FUND_NEWS_FAILURE = 'FETCH_ADMIN_FUND_NEWS_FAILURE';
export const REMOVE_ADMIN_FUND_NEWS = 'REMOVE_ADMIN_FUND_NEWS';

export const FETCH_ADMIN_FUNDS_MANAGER_NEWS_REQUEST =
  'FETCH_ADMIN_FUNDS_MANAGER_NEWS_REQUEST';
export const FETCH_ADMIN_FUNDS_MANAGER_NEWS_SUCCESS =
  'FETCH_ADMIN_FUNDS_MANAGER_NEWS_SUCCESS';
export const FETCH_ADMIN_FUNDS_MANAGER_NEWS_FAILURE =
  'FETCH_ADMIN_FUNDS_MANAGER_NEWS_FAILURE';
export const REMOVE_ADMIN_FUNDS_MANAGER_NEWS =
  'REMOVE_ADMIN_FUNDS_MANAGER_NEWS';

export const FETCH_ADMIN_COMPANY_EVENTS_REQUEST =
  'FETCH_ADMIN_COMPANY_EVENTS_REQUEST';
export const FETCH_ADMIN_COMPANY_EVENTS_SUCCESS =
  'FETCH_ADMIN_COMPANY_EVENTS_SUCCESS';
export const FETCH_ADMIN_COMPANY_EVENTS_FAILURE =
  'FETCH_ADMIN_COMPANY_EVENTS_FAILURE';
export const REMOVE_ADMIN_COMPANY_EVENTS = 'REMOVE_ADMIN_COMPANY_EVENTS';

export const FETCH_ADMIN_FUND_EVENTS_REQUEST =
  'FETCH_ADMIN_FUND_EVENTS_REQUEST';
export const FETCH_ADMIN_FUND_EVENTS_SUCCESS =
  'FETCH_ADMIN_FUND_EVENTS_SUCCESS';
export const FETCH_ADMIN_FUND_EVENTS_FAILURE =
  'FETCH_ADMIN_FUND_EVENTS_FAILURE';
export const REMOVE_ADMIN_FUND_EVENTS = 'REMOVE_ADMIN_FUND_EVENTS';

export const FETCH_ADMIN_FUNDS_MANAGER_EVENTS_REQUEST =
  'FETCH_ADMIN_FUNDS_MANAGER_EVENTS_REQUEST';
export const FETCH_ADMIN_FUNDS_MANAGER_EVENTS_SUCCESS =
  'FETCH_ADMIN_FUNDS_MANAGER_EVENTS_SUCCESS';
export const FETCH_ADMIN_FUNDS_MANAGER_EVENTS_FAILURE =
  'FETCH_ADMIN_FUNDS_MANAGER_EVENTS_FAILURE';
export const REMOVE_ADMIN_FUNDS_MANAGER_EVENTS =
  'REMOVE_ADMIN_FUNDS_MANAGER_EVENTS';

export const FETCH_ADMIN_COMPANY_REQUEST = 'FETCH_ADMIN_COMPANY_REQUEST';
export const FETCH_ADMIN_COMPANY_SUCCESS = 'FETCH_ADMIN_COMPANY_SUCCESS';
export const FETCH_ADMIN_COMPANY_FAILURE = 'FETCH_ADMIN_COMPANY_FAILURE';
export const REMOVE_ADMIN_COMPANY = 'REMOVE_ADMIN_COMPANY';

export const FETCH_ADMIN_NEWS_REQUEST = 'FETCH_ADMIN_NEWS_REQUEST';
export const FETCH_ADMIN_NEWS_SUCCESS = 'FETCH_ADMIN_NEWS_SUCCESS';
export const FETCH_ADMIN_NEWS_FAILURE = 'FETCH_ADMIN_NEWS_FAILURE';
export const REMOVE_ADMIN_NEWS = 'REMOVE_ADMIN_NEWS';

export const FETCH_USER_DETAILS_LIST_REQUEST =
  'FETCH_USER_DETAILS_LIST_REQUEST';
export const FETCH_USER_DETAILS_LIST_SUCCESS =
  'FETCH_USER_DETAILS_LIST_SUCCESS';
export const FETCH_USER_DETAILS_LIST_FAILURE =
  'FETCH_USER_DETAILS_LIST_FAILURE';

// funds managers

export const FETCH_ADMIN_FUNDS_MANAGERS_LIST_REQUEST =
  'FETCH_ADMIN_FUNDS_MANAGERS_LIST_REQUEST';
export const FETCH_ADMIN_FUNDS_MANAGERS_LIST_SUCCESS =
  'FETCH_ADMIN_FUNDS_MANAGERS_LIST_SUCCESS';
export const FETCH_ADMIN_FUNDS_MANAGERS_LIST_FAILURE =
  'FETCH_ADMIN_FUNDS_MANAGERS_LIST_FAILURE';

export const UPDATE_ADMIN_FUNDS_MANAGERS_LIST =
  'UPDATE_ADMIN_FUNDS_MANAGERS_LIST';

export const FETCH_ADMIN_FUNDS_MANAGER_REQUEST =
  'FETCH_ADMIN_FUNDS_MANAGER_REQUEST';
export const FETCH_ADMIN_FUNDS_MANAGER_SUCCESS =
  'FETCH_ADMIN_FUNDS_MANAGER_SUCCESS';
export const FETCH_ADMIN_FUNDS_MANAGER_FAILURE =
  'FETCH_ADMIN_FUNDS_MANAGER_FAILURE';
export const REMOVE_ADMIN_FUNDS_MANAGER = 'REMOVE_ADMIN_FUNDS_MANAGER';

export const REMOVE_ADMIN_FUNDS_MANAGERS_FILTERS =
  'REMOVE_ADMIN_FUNDS_MANAGERS_FILTERS';
export const SAVE_ADMIN_FUNDS_MANAGERS_FILTERS =
  'SAVE_ADMIN_FUNDS_MANAGERS_FILTERS';
export const SAVE_ADMIN_FUNDS_MANAGERS_SEARCH =
  'SAVE_ADMIN_FUNDS_MANAGERS_SEARCH';
export const SAVE_ADMIN_FUNDS_MANAGERS_ACTIVE_CHECKBOX =
  'SAVE_ADMIN_FUNDS_MANAGERS_ACTIVE_CHECKBOX';
export const SAVE_ADMIN_FUNDS_MANAGERS_PASSIVE_CHECKBOX =
  'SAVE_ADMIN_FUNDS_MANAGERS_PASSIVE_CHECKBOX';

export const REMOVE_ADMIN_FUNDS_FILTERS = 'REMOVE_ADMIN_FUNDS_FILTERS';
export const SAVE_ADMIN_FUNDS_FILTERS = 'SAVE_ADMIN_FUNDS_FILTERS';
export const SAVE_ADMIN_FUNDS_SEARCH = 'SAVE_ADMIN_FUNDS_SEARCH';
export const SAVE_ADMIN_FUNDS_ACTIVE_CHECKBOX =
  'SAVE_ADMIN_FUNDS_ACTIVE_CHECKBOX';
export const SAVE_ADMIN_FUNDS_PASSIVE_CHECKBOX =
  'SAVE_ADMIN_FUNDS_PASSIVE_CHECKBOX';

// funds

export const FETCH_ADMIN_FUNDS_LIST_REQUEST = 'FETCH_ADMIN_FUND_LIST_REQUEST';
export const FETCH_ADMIN_FUNDS_LIST_SUCCESS = 'FETCH_ADMIN_FUND_LIST_SUCCESS';
export const FETCH_ADMIN_FUNDS_LIST_FAILURE = 'FETCH_ADMIN_FUND_LIST_FAILURE';

export const UPDATE_ADMIN_FUNDS_LIST = 'UPDATE_ADMIN_FUNDS_LIST';
export const REMOVE_ADMIN_FUNDS_LIST = 'REMOVE_ADMIN_FUNDS_LIST';

export const FETCH_ADMIN_FUND_REQUEST = 'FETCH_ADMIN_FUND_REQUEST';
export const FETCH_ADMIN_FUND_SUCCESS = 'FETCH_ADMIN_FUND_SUCCESS';
export const FETCH_ADMIN_FUND_FAILURE = 'FETCH_ADMIN_FUND_FAILURE';
export const REMOVE_ADMIN_FUND = 'REMOVE_ADMIN_FUND';

// startups

export const FETCH_STARTUPS_REQUESTS_REQUEST =
  'FETCH_STARTUPS_REQUESTS_REQUEST';
export const FETCH_STARTUPS_REQUESTS_SUCCESS =
  'FETCH_STARTUPS_REQUESTS_SUCCESS';
export const FETCH_STARTUPS_REQUESTS_FAILURE =
  'FETCH_STARTUPS_REQUESTS_FAILURE';

export const UPDATE_STARTUPS_REQUESTS = 'UPDATE_STARTUPS_REQUESTS';

export const REMOVE_STARTUPS_REQUESTS_FILTERS =
  'REMOVE_STARTUPS_REQUESTS_FILTERS';
export const SAVE_STARTUPS_REQUESTS_FILTERS = 'SAVE_STARTUPS_REQUESTS_FILTERS';
export const SAVE_STARTUPS_REQUESTS_SEARCH = 'SAVE_STARTUPS_REQUESTS_SEARCH';

export const FETCH_ADMIN_STARTUPS_LIST_REQUEST =
  'FETCH_ADMIN_STARTUP_LIST_REQUEST';
export const FETCH_ADMIN_STARTUPS_LIST_SUCCESS =
  'FETCH_ADMIN_STARTUP_LIST_SUCCESS';
export const FETCH_ADMIN_STARTUPS_LIST_FAILURE =
  'FETCH_ADMIN_STARTUP_LIST_FAILURE';

export const UPDATE_ADMIN_STARTUPS_LIST = 'UPDATE_ADMIN_STARTUPS_LIST';
export const REMOVE_ADMIN_STARTUPS_LIST = 'REMOVE_ADMIN_STARTUPS_LIST';

export const REMOVE_ADMIN_STARTUPS_FILTERS = 'REMOVE_ADMIN_STARTUPS_FILTERS';
export const SAVE_ADMIN_STARTUPS_FILTERS = 'SAVE_ADMIN_STARTUPS_FILTERS';
export const SAVE_ADMIN_STARTUPS_SEARCH = 'SAVE_ADMIN_STARTUPS_SEARCH';

export const FETCH_ADMIN_STARTUP_REQUEST = 'FETCH_ADMIN_STARTUP_REQUEST';
export const FETCH_ADMIN_STARTUP_SUCCESS = 'FETCH_ADMIN_STARTUP_SUCCESS';
export const FETCH_ADMIN_STARTUP_FAILURE = 'FETCH_ADMIN_STARTUP_FAILURE';
export const REMOVE_ADMIN_STARTUP = 'REMOVE_ADMIN_STARTUP';

export const FETCH_ADMIN_STARTUP_NEWS_REQUEST =
  'FETCH_ADMIN_STARTUP_NEWS_REQUEST';
export const FETCH_ADMIN_STARTUP_NEWS_SUCCESS =
  'FETCH_ADMIN_STARTUP_NEWS_SUCCESS';
export const FETCH_ADMIN_STARTUP_NEWS_FAILURE =
  'FETCH_ADMIN_STARTUP_NEWS_FAILURE';
export const REMOVE_ADMIN_STARTUP_NEWS = 'REMOVE_ADMIN_STARTUP_NEWS';

export const FETCH_ADMIN_STARTUP_EVENTS_REQUEST =
  'FETCH_ADMIN_STARTUP_EVENTS_REQUEST';
export const FETCH_ADMIN_STARTUP_EVENTS_SUCCESS =
  'FETCH_ADMIN_STARTUP_EVENTS_SUCCESS';
export const FETCH_ADMIN_STARTUP_EVENTS_FAILURE =
  'FETCH_ADMIN_STARTUP_EVENTS_FAILURE';
export const REMOVE_ADMIN_STARTUP_EVENTS = 'REMOVE_ADMIN_STARTUP_EVENTS';

export const FETCH_STARTUP_STATISTICS_REQUEST =
  'FETCH_STARTUP_STATISTICS_REQUEST';
export const FETCH_STARTUP_STATISTICS_SUCCESS =
  'FETCH_STARTUP_STATISTICS_SUCCESS';
export const FETCH_STARTUP_STATISTICS_FAILURE =
  'FETCH_STARTUP_STATISTICS_FAILURE';

export const FETCH_ADMIN_STARTUP_PROJECTS_REQUEST =
  'FETCH_ADMIN_STARTUP_PROJECTS_REQUEST';
export const FETCH_ADMIN_STARTUP_PROJECTS_SUCCESS =
  'FETCH_ADMIN_STARTUP_PROJECTS_SUCCESS';
export const FETCH_ADMIN_STARTUP_PROJECTS_FAILURE =
  'FETCH_ADMIN_STARTUP_PROJECTS_FAILURE';
export const REMOVE_ADMIN_STARTUP_PROJECT = 'REMOVE_ADMIN_STARTUP_PROJECT';

export const FETCH_ADMIN_PROJECT_REQUEST = 'FETCH_ADMIN_PROJECT_REQUEST';
export const FETCH_ADMIN_PROJECT_SUCCESS = 'FETCH_ADMIN_PROJECT_SUCCESS';
export const FETCH_ADMIN_PROJECT_FAILURE = 'FETCH_ADMIN_PROJECT_FAILURE';
export const REMOVE_ADMIN_PROJECT = 'REMOVE_ADMIN_PROJECT';
