import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_STARTUPS_LATEST_NEWS_LIST_REQUEST,
  FETCH_STARTUPS_LATEST_NEWS_LIST_SUCCESS,
  FETCH_STARTUPS_LATEST_NEWS_LIST_FAILURE,
} from 'startups/redux/types';

const initialState = {
  list: [],
  hasNextPage: false,
  nextPageIndex: null,
  isLoading: false,
};

const startupsLatestNewsListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUPS_LATEST_NEWS_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.startupsLatestNews;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        hasNextPage,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_STARTUPS_LATEST_NEWS_LIST_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_STARTUPS_LATEST_NEWS_LIST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
);

export default startupsLatestNewsListReducer;
