import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_FUNDS_MANAGER_REQUEST,
  FETCH_FUNDS_MANAGER_SUCCESS,
  FETCH_FUNDS_MANAGER_FAILURE,
  REMOVE_FUNDS_MANAGER,
} = types;

const fetchFundsManagerRequest = createActionCreator(
  FETCH_FUNDS_MANAGER_REQUEST,
);
const fetchFundsManagerSuccess = createActionCreator(
  FETCH_FUNDS_MANAGER_SUCCESS,
  'currentFundsManager',
);
const fetchFundsManagerFailure = createActionCreator(
  FETCH_FUNDS_MANAGER_FAILURE,
);

export const fetchFundsManager = id => dispatch => {
  dispatch(fetchFundsManagerRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/funds-managers/${id}`)
    .then(response => {
      dispatch(fetchFundsManagerSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchFundsManagerFailure());
    });
};

export const removeFundsManager = createActionCreator(REMOVE_FUNDS_MANAGER);
