import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_USER_REQUESTS_SUCCESS,
  UPDATE_USER_REQUESTS_REQUESTS,
} from 'profile/requests/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: null,
  meta: {},
};

const RequestsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_USER_REQUESTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        meta,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [UPDATE_USER_REQUESTS_REQUESTS]: (state, action) => ({
      ...state,
      list: action.payload.updatedCompaniesRequests,
    }),
  },
);

export default RequestsReducer;
