import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_PUSH_FUNDS_MANAGERS_FILTERS,
  SAVE_PUSH_FUNDS_MANAGERS_FILTERS,
  SAVE_PUSH_FUNDS_MANAGERS_SEARCH,
} from 'adminPanel/adminContent/push/redux/types.js';

const initialState = {
  search: '',
  relations: [],
  activePassive: [],
  fundCountry: [],
  fundType: [],
  currency: [],
  fundContinent: [],
  fundsManagerCountry: [],
  fundsManagerContinent: [],
  per_page: 10,
};

const pushFundsManagersFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_PUSH_FUNDS_MANAGERS_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_PUSH_FUNDS_MANAGERS_FILTERS]: (state) => ({
      ...state,
      relations: [],
      activePassive: [],
      fundCountry: [],
      fundType: [],
      currency: [],
      fundContinent: [],
      fundsManagerCountry: [],
      fundsManagerContinent: [],
    }),
    [SAVE_PUSH_FUNDS_MANAGERS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default pushFundsManagersFiltersReducer;
