export const FETCH_PUSH_COMPANIES_REQUEST = 'FETCH_PUSH_COMPANIES_REQUEST';
export const FETCH_PUSH_COMPANIES_SUCCESS = 'FETCH_PUSH_COMPANIES_SUCCESS';
export const FETCH_PUSH_COMPANIES_FAILURE = 'FETCH_PUSH_COMPANIES_FAILURE';

export const SAVE_PUSH_COMPANIES_FILTERS = 'SAVE_PUSH_COMPANIES_FILTERS';
export const SAVE_PUSH_COMPANIES_SEARCH = 'SAVE_PUSH_COMPANIES_SEARCH';
export const REMOVE_PUSH_COMPANIES_FILTERS = 'REMOVE_PUSH_COMPANIES_FILTERS';

export const FETCH_PUSH_STARTUPS_REQUEST = 'FETCH_PUSH_STARTUPS_REQUEST';
export const FETCH_PUSH_STARTUPS_SUCCESS = 'FETCH_PUSH_STARTUPS_SUCCESS';
export const FETCH_PUSH_STARTUPS_FAILURE = 'FETCH_PUSH_STARTUPS_FAILURE';

export const SAVE_PUSH_STARTUPS_FILTERS = 'SAVE_PUSH_STARTUPS_FILTERS';
export const SAVE_PUSH_STARTUPS_SEARCH = 'SAVE_PUSH_STARTUPS_SEARCH';
export const REMOVE_PUSH_STARTUPS_FILTERS = 'REMOVE_PUSH_STARTUPS_FILTERS';

export const FETCH_PUSH_FUNDS_MANAGERS_REQUEST =
  'FETCH_PUSH_FUNDS_MANAGERS_REQUEST';
export const FETCH_PUSH_FUNDS_MANAGERS_SUCCESS =
  'FETCH_PUSH_FUNDS_MANAGERS_SUCCESS';
export const FETCH_PUSH_FUNDS_MANAGERS_FAILURE =
  'FETCH_PUSH_FUNDS_MANAGERS_FAILURE';

export const SAVE_PUSH_FUNDS_MANAGERS_FILTERS =
  'SAVE_PUSH_FUNDS_MANAGERS_FILTERS';
export const SAVE_PUSH_FUNDS_MANAGERS_SEARCH =
  'SAVE_PUSH_FUNDS_MANAGERS_SEARCH';
export const REMOVE_PUSH_FUNDS_MANAGERS_FILTERS =
  'REMOVE_PUSH_FUNDS_MANAGERS_FILTERS';
