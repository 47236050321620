import { createSelector } from 'reselect';
import { PERMISSION_USERS_TYPES } from 'userAuth/utils/permissionTypes';

const userDataSelector = (state) => state.userData.data;

const verifyPermissionUserType = (permissionUserType, userData) =>
  (permissionUserType.TYPE === 'REGULAR_USER' && !userData.is_demo_account) ||
  userData[permissionUserType.OBJECT_PROPERTIES];

export const userPermissionsSelector = createSelector(
  userDataSelector,
  (userData) =>
    userData
      ? [
          ...new Set(
            PERMISSION_USERS_TYPES.filter((permissionUserType) =>
              verifyPermissionUserType(permissionUserType, userData),
            )
              .map((permissionUserType) => permissionUserType.PERMISSIONS)
              .flat(),
          ),
        ]
      : [],
);
