import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_GALLERY_REQUEST,
  FETCH_GALLERY_SUCCESS,
  FETCH_GALLERY_FAILURE,
  REMOVE_GALLERY,
  FETCH_ALLPHOTOS_REQUEST,
  FETCH_ALLPHOTOS_SUCCESS,
  FETCH_ALLPHOTOS_FAILURE,
  REMOVE_ALLPHOTOS,
} from '../types';

const initialState = {
  data: [],
  nextPageIndex: null,
  isLoading: false,
};

export const galleryReducer = createReducer(
  { ...initialState },
  {
    [FETCH_GALLERY_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: [...data],
      };
    },
    [FETCH_GALLERY_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_GALLERY_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [REMOVE_GALLERY]: (state) => ({
      ...state,
      data: [],
      isLoading: false,
    }),
  },
);

export const allPhotosReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ALLPHOTOS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
      };
    },
    [FETCH_ALLPHOTOS_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_ALLPHOTOS_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [REMOVE_ALLPHOTOS]: (state) => ({
      ...state,
      data: [],
      nextPageIndex: null,
      isLoading: false,
    }),
  },
);
