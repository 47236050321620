import React from 'react';
import shortid from 'shortid';
import { Link as ScrollLink } from 'react-scroll';
import LoggedInHeaderNavigation from './LoggedInHeaderNavigation';
import MainHeaderAuthButtons from './MainHeaderAuthButtons';

const LandingHeaderNavigation = ({
  toggleMobileNav,
  mainNavItemsClasses,
  mainNavListClasses,
  isMobileViewActive,
  userData,
  openOptionsMenu,
  handleLogOutClick,
}) => (
  <ul className={mainNavListClasses}>
    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <ScrollLink
        onClick={toggleMobileNav}
        className="main-nav__link"
        activeClass="main-nav__link--active"
        to="about"
        spy
        smooth
        offset={-100}
        duration={500}
        data-text="About"
      >
        <span className="main-nav__link-text main-nav__link-text__on-landing">
          About
        </span>
      </ScrollLink>
    </li>

    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <ScrollLink
        onClick={toggleMobileNav}
        className="main-nav__link"
        activeClass="main-nav__link--active"
        to="contact"
        spy
        smooth
        offset={-100}
        duration={500}
        data-text="Contact and support"
      >
        <span className="main-nav__link-text main-nav__link-text main-nav__link-text__on-landing">
          Contact and support
        </span>
      </ScrollLink>
    </li>
    <li key={shortid.generate()} className={mainNavItemsClasses}>
      <ScrollLink
        onClick={toggleMobileNav}
        className="main-nav__link"
        activeClass="main-nav__link--active"
        to="corporate"
        spy
        smooth
        offset={-100}
        duration={500}
        data-text="Corporate"
      >
        <span className="main-nav__link-text main-nav__link-text__on-landing">
          Corporate
        </span>
      </ScrollLink>
    </li>
    {isMobileViewActive && (
      <LoggedInHeaderNavigation
        toggleMobileNav={toggleMobileNav}
        mainNavItemsClasses={mainNavItemsClasses}
      />
    )}

    <li className={mainNavItemsClasses}>
      <MainHeaderAuthButtons
        userData={userData}
        openOptionsMenu={openOptionsMenu}
        handleLogOutClick={handleLogOutClick}
        toggleMobileNav={toggleMobileNav}
        isMobileViewActive={isMobileViewActive}
      />
    </li>
  </ul>
);

export default LandingHeaderNavigation;
