import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_COMPANY_SUCCESS, REMOVE_COMPANY } from 'common/redux/types';

const initialState = {
  currentCompany: {},
};

const companyReducer = createReducer(
  { ...initialState },
  {
    [FETCH_COMPANY_SUCCESS]: (state, action) => ({
      ...state,
      currentCompany: action.payload.currentCompany,
    }),
    [REMOVE_COMPANY]: state => ({
      ...state,
      currentCompany: {},
    }),
  },
);

export default companyReducer;
