import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_OFFERS_FILTERS,
  SAVE_OFFERS_FILTERS,
  SAVE_OFFERS_SEARCH,
} from 'common/redux/types';

const initialState = {
  countries: [],
  search: '',
};

const OffersFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_OFFERS_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_OFFERS_FILTERS]: state => ({
      ...state,
      countries: [],
    }),
    [SAVE_OFFERS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default OffersFiltersReducer;
