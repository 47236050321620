import { createReducer } from 'common/utils/reduxUtils';
import {
  SHOW_LANDING_PAGE_MAIN_HEADER,
  SHOW_LOGIN_PAGE_MAIN_HEADER,
  SHOW_DEFAULT_MAIN_HEADER,
} from 'common/components/mainHeader/redux/mainHeaderTypes';
import {
  SHOW_ADMIN_PAGE_MAIN_FOOTER,
  SHOW_PROFILE_PAGE_MAIN_FOOTER,
} from 'common/components/mainFooter/redux/mainFooterTypes';

const initialState = {
  isOnLandingPage: false,
  isOnLoginPage: false,
  isAdminSection: false,
  isProfileSection: false,
};

const mainHeaderReducer = createReducer(
  { ...initialState },
  {
    [SHOW_LANDING_PAGE_MAIN_HEADER]: (state) => ({
      ...state,
      isOnLandingPage: true,
      isOnLoginPage: false,
      isAdminSection: false,
      isProfileSection: false,
    }),
    [SHOW_LOGIN_PAGE_MAIN_HEADER]: (state) => ({
      ...state,
      isOnLandingPage: false,
      isOnLoginPage: true,
      isAdminSection: false,
      isProfileSection: false,
    }),
    [SHOW_DEFAULT_MAIN_HEADER]: (state) => ({
      ...state,
      isOnLandingPage: false,
      isOnLoginPage: false,
      isAdminSection: false,
      isProfileSection: false,
    }),
    [SHOW_ADMIN_PAGE_MAIN_FOOTER]: (state) => ({
      ...state,
      isOnLandingPage: false,
      isOnLoginPage: false,
      isAdminSection: true,
      isProfileSection: false,
    }),
    [SHOW_PROFILE_PAGE_MAIN_FOOTER]: (state) => ({
      ...state,
      isOnLandingPage: false,
      isOnLoginPage: false,
      isAdminSection: false,
      isProfileSection: true,
    }),
  },
);

export default mainHeaderReducer;
