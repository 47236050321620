import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_PUSH_STARTUPS_SUCCESS,
  FETCH_PUSH_STARTUPS_REQUEST,
  FETCH_PUSH_STARTUPS_FAILURE,
} from 'adminPanel/adminContent/push/redux/types.js';

const initialState = {
  isLoading: false,
  list: [],
  nextPageIndex: null,
  meta: {},
};

const pushStartupsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_PUSH_STARTUPS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.pushStartups;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        isLoading: false,
        list: [...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        meta,
      };
    },
    [FETCH_PUSH_STARTUPS_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_PUSH_STARTUPS_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
);

export default pushStartupsReducer;
