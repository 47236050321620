export const FETCH_FUNDS_LIST_REQUEST = 'FETCH_FUNDS_LIST_REQUEST';
export const FETCH_FUNDS_LIST_SUCCESS = 'FETCH_FUNDS_LIST_SUCCESS';
export const FETCH_FUNDS_LIST_FAILURE = 'FETCH_FUNDS_LIST_FAILURE';
export const UPDATE_FUNDS_LIST = 'UPDATE_FUNDS_LIST';

export const SAVE_FUNDS_FILTERS = 'SAVE_FUNDS_FILTERS';
export const REMOVE_FUNDS_FILTERS = 'REMOVE_FUNDS_FILTERS';
export const SAVE_FUNDS_SEARCH = 'SAVE_FUNDS_SEARCH';

export const CLEAR_FUNDS_LIST = 'CLEAR_FUNDS_LIST';
