import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_FUND_STATISTICS_SUCCESS } from 'adminPanel/redux/types';

const initialState = {
  data: [],
};

const fundStatisticsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUND_STATISTICS_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
  },
);

export default fundStatisticsReducer;
