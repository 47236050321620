import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUNDS_LIST_REQUEST,
  FETCH_FUNDS_LIST_SUCCESS,
  FETCH_FUNDS_LIST_FAILURE,
  UPDATE_FUNDS_LIST,
  CLEAR_FUNDS_LIST,
} from 'funds/redux/types';
import getRandomInt from 'common/utils/mathUtils';

const initialState = {
  list: [],
  hasNextPage: false,
  nextPageIndex: null,
  // NOTE: seed param determines sort order
  seed: getRandomInt(1000),
  isLoading: false,
};

const fundsListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUNDS_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.funds;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        hasNextPage,
        isFollowingAll: meta.is_following_all,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_FUNDS_LIST_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_FUNDS_LIST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [UPDATE_FUNDS_LIST]: (state, action) => ({
      ...state,
      list: [...action.payload.data],
    }),
    [CLEAR_FUNDS_LIST]: (state) => ({
      ...state,
      list: [],
      hasNextPage: false,
      nextPageIndex: null,
      isLoading: false,
    }),
  },
);

export default fundsListReducer;
