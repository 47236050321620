import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUNDS_MANAGERS_RELATIONS_SUCCESS,
  UPDATE_FUNDS_MANAGERS_RELATIONS,
} from 'adminPanel/adminContent/fundsManagers/relations/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: null,
  meta: {},
};

const RelationsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUNDS_MANAGERS_RELATIONS_SUCCESS]: (state, action) => {
      const {
        data,
        meta,
        admins,
        editors,
        shareholders,
        vips,
        user_group: userGroup,
      } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        roles: {
          admins,
          editors,
          shareholders,
          vips,
        },
        userGroup,
        meta,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [UPDATE_FUNDS_MANAGERS_RELATIONS]: (state, action) => ({
      ...state,
      list: action.payload.updatedFundsManagersRequests,
    }),
  },
);

export default RelationsReducer;
