import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import fetchLandingData from 'common/redux/actions/landingActions';

const useLanding = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const landingData = useSelector((state) => state.landing.data);

  useEffect(() => {
    dispatch(fetchLandingData());
  }, [dispatch]);

  return {
    landingData,
    location,
  };
};

export default useLanding;
