import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_MORE_SUCCESS,
  REMOVE_DOCUMENTS,
  ADD_DOCUMENTS_SUCCESS,
  EDIT_DOCUMENTS_SUCCESS,
  REMOVE_ONE_DOCUMENTS_SUCCESS,
  ADD_DOCUMENTS_SECTION_SUCCESS,
  EDIT_DOCUMENTS_SECTION_SUCCESS,
  REMOVE_DOCUMENTS_SECTION_SUCCESS,
} from '../types';

const initialState = {
  list: [],
};

const documentsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
      const { data } = action.payload.documents;
      return {
        ...state,
        list: data.map((element) => ({
          data: element.data.slice(0, 3),
          name: element.name,
          nextPageIndex: element.data.length > 3 ? 1 : null,
          sectionId: element.section_id,
        })),
      };
    },
    [FETCH_DOCUMENTS_MORE_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.documents;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: [
          ...state.list.map((element) => {
            if (element.sectionId === action.payload.sectionId) {
              return {
                data:
                  meta.current_page === 1 ? data : [...element.data, ...data],
                name: element.name,
                nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
                sectionId: action.payload.sectionId,
              };
            }

            return element;
          }),
        ],
      };
    },
    [ADD_DOCUMENTS_SUCCESS]: (state, action) => {
      const { data } = action.payload.documents;
      return {
        ...state,
        list: [
          ...state.list.map((element) => {
            if (element.sectionId === action.payload.data.section_id) {
              return {
                ...element,
                data: [data, ...element.data],
              };
            }

            return element;
          }),
        ],
      };
    },
    [EDIT_DOCUMENTS_SUCCESS]: (state, action) => {
      const { data } = action.payload.documents;
      return {
        list: [
          ...state.list.map((element) => {
            if (element.sectionId === action.payload.data.section_id) {
              return {
                ...element,
                data: element.data.map((documentsElement) => {
                  if (documentsElement.id === data.id) return data;
                  return documentsElement;
                }),
              };
            }

            return element;
          }),
        ],
      };
    },
    [REMOVE_ONE_DOCUMENTS_SUCCESS]: (state, action) => {
      const { id, sectionId } = action.payload;
      return {
        ...state,
        list: [
          ...state.list.map((element) => {
            if (element.sectionId === sectionId) {
              return {
                ...element,
                data: element.data.filter((data) => data.id !== id),
              };
            }

            return element;
          }),
        ],
      };
    },
    [REMOVE_DOCUMENTS]: (state) => ({
      ...state,
      list: [],
    }),
    [ADD_DOCUMENTS_SECTION_SUCCESS]: (state, action) => {
      const { data } = action.payload.documentsSection;
      return {
        ...state,
        list: [
          ...state.list,
          {
            data: [],
            name: action.payload.data.name,
            nextPageIndex: 0,
            sectionId: data.sectionId,
          },
        ],
      };
    },
    [EDIT_DOCUMENTS_SECTION_SUCCESS]: (state, action) => ({
      list: [
        ...state.list.map((element) => {
          if (element.sectionId === action.payload.id) {
            return {
              ...element,
              name: action.payload.data.name,
            };
          }

          return element;
        }),
      ],
    }),
    [REMOVE_DOCUMENTS_SECTION_SUCCESS]: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        list: [...state.list.filter((element) => element.sectionId !== id)],
      };
    },
  },
);

export default documentsReducer;
