import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_FUNDS_STATISTICS_FILTERS,
  SAVE_FUNDS_STATISTICS_FILTERS,
  SAVE_FUNDS_STATISTICS_DATE_RANGE,
} from 'adminPanel/adminContent/fundsStatistics/redux/types';

const initialState = {
  relation: [],
  dateRange: {},
  country: [],
};

const fundsStatisticsFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_FUNDS_STATISTICS_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_FUNDS_STATISTICS_FILTERS]: state => ({
      ...state,
      relation: [],
      dateRange: {},
      country: [],
    }),
    [SAVE_FUNDS_STATISTICS_DATE_RANGE]: (state, action) => ({
      ...state,
      dateRange: action.payload.dateRange,
    }),
  },
);

export default fundsStatisticsFiltersReducer;
