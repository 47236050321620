import React from 'react';
import propTypes from 'prop-types';
import './index.scss';

const CookiesBanner = ({setCookiesAccept, setCookiesReject}) => (
    <div className="cookies-banner">
        <img className="cookie-icon" src="/cookies.png" alt="cookie icon"/>
        <p className="cookie-info--paragraph">
            <strong class="h3"> We use cookies! </strong>
            <br/>
            Using this website means you are okay with this but you can find out
            more&nbsp;
            <a
                className="cookie-law--link"
                href="http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm"
                rel="noopener noreferrer"
                target="_blank"
            >
                here.
            </a>
        </p>
        <button
            class="btn btn-outline-success p-1"
            onClick={() => {
                setCookiesAccept();
            }}
        >
            Sweet!
        </button>
        <button
            class="btn btn-outline-danger p-1"
            onClick={() => {
                setCookiesReject();
            }}
        >
            Sorry, I'm on a diet!
        </button>
    </div>
);

CookiesBanner.propTypes = {
    setCookiesAccept: propTypes.func.isRequired,
};

export default CookiesBanner;
