import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_COMPANIES_SECTORS_PERFORMANCE_REQUEST,
  FETCH_COMPANIES_SECTORS_PERFORMANCE_SUCCESS,
  FETCH_COMPANIES_SECTORS_PERFORMANCE_FAILURE,
} from 'companies/redux/types';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
};

const companiesSectorsPerformanceReducer = createReducer(
  { ...initialState },
  {
    [FETCH_COMPANIES_SECTORS_PERFORMANCE_SUCCESS]: (state, action) => {
      return {
        ...state,
        list: action.payload.companiesSectorsPerformance,
        isLoading: false,
        isLoaded: true,
      };
    },
    [FETCH_COMPANIES_SECTORS_PERFORMANCE_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
      isLoaded: false,
    }),
    [FETCH_COMPANIES_SECTORS_PERFORMANCE_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
      isLoaded: false,
    }),
  },
);

export default companiesSectorsPerformanceReducer;
