import axios from 'axios';
import * as types from 'event/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  REMOVE_EVENT,
  FETCH_LATEST_EVENTS_REQUEST,
  FETCH_LATEST_EVENTS_SUCCESS,
  FETCH_LATEST_EVENTS_FAILURE,
} = types;

const fetchEventRequest = createActionCreator(FETCH_EVENT_REQUEST);
const fetchEventSuccess = createActionCreator(
  FETCH_EVENT_SUCCESS,
  'currentEvent',
);
const fetchEventFailure = createActionCreator(FETCH_EVENT_FAILURE);

const fetchLatestEventsRequest = createActionCreator(
  FETCH_LATEST_EVENTS_REQUEST,
);
const fetchLatestEventsSuccess = createActionCreator(
  FETCH_LATEST_EVENTS_SUCCESS,
  'latestEvents',
);
const fetchLatestEventsFailure = createActionCreator(
  FETCH_LATEST_EVENTS_FAILURE,
);

export const fetchEvent = id => dispatch => {
  dispatch(fetchEventRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/events/${id}`)
    .then(response => {
      dispatch(fetchEventSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchEventFailure());
    });
};

export const fetchLatestEvents = id => dispatch => {
  dispatch(fetchLatestEventsRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/events/${id}/latest`)
    .then(response => {
      dispatch(fetchLatestEventsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchLatestEventsFailure());
    });
};

export const removeEvent = createActionCreator(REMOVE_EVENT);
