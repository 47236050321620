import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_COMPANIES_NAMES_LIST_SUCCESS,
  FETCH_COMPANIES_NAMES_LIST_REQUEST,
  FETCH_COMPANIES_NAMES_LIST_FAILURE,
} from 'common/redux/types';

const countriesListReducer = createReducer([], {
  [FETCH_COMPANIES_NAMES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
    isLoading: false,
  }),
  [FETCH_COMPANIES_NAMES_LIST_REQUEST]: state => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_COMPANIES_NAMES_LIST_FAILURE]: state => ({
    ...state,
    isLoading: false,
  }),
});

export default countriesListReducer;
