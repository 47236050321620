import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_USER_DETAILS_LIST_SUCCESS } from 'adminPanel/redux/types';

const initialState = {
  data: [],
  hasNextPage: false,
  nextPageIndex: null,
};

const adminUsersListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_USER_DETAILS_LIST_SUCCESS]: (state, action) => {
      const {
        data,
        meta,
        admin_in: adminIn,
        follower_in: followerIn,
        vip_in: vipIn,
        editor_in: editorIn,
        shareholder_in: shareholderIn,
      } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        adminIn,
        followerIn,
        vipIn,
        editorIn,
        shareholderIn,
        meta,
        hasNextPage,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
  },
);

export default adminUsersListReducer;
