import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_OFFERS_REQUEST,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAILURE,
  FETCH_SINGLE_OFFER_SUCCESS,
  REMOVE_SINGLE_OFFER,
} from 'common/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: null,
  offer: {},
  isLoading: false,
};

const OffersReducer = createReducer(
  { ...initialState },
  {
    [FETCH_OFFERS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_SINGLE_OFFER_SUCCESS]: (state, action) => ({
      ...state,
      offer: action.payload.offer,
    }),
    [REMOVE_SINGLE_OFFER]: state => ({
      ...state,
      offer: {},
    }),
    [FETCH_OFFERS_REQUEST]: state => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_OFFERS_FAILURE]: state => ({
      ...state,
      isLoading: false,
    }),
  },
);

export default OffersReducer;
