import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUNDS_MANAGER_SUCCESS,
  REMOVE_ADMIN_FUNDS_MANAGER,
} from 'adminPanel/redux/types';

const initialState = {
  currentFundsManager: {},
};

const adminFundsManagerReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUNDS_MANAGER_SUCCESS]: (state, action) => ({
      ...state,
      currentFundsManager: action.payload.currentFundsManager,
    }),
    [REMOVE_ADMIN_FUNDS_MANAGER]: state => ({
      ...state,
      currentFundsManager: {},
    }),
  },
);

export default adminFundsManagerReducer;
