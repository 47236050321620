import React from 'react';
import shortid from 'shortid';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import AppStoreButton from 'common/components/appStoreButton';
import GooglePlayButton from 'common/components/googlePlayButton';
import logo from 'assets/img/logo.png';
import './index.scss';
import useMainFooter from './useMainFooter';

const MainFooter = () => {
  const { isAdminSection, isProfileSection, partnersSorted } = useMainFooter();

  return (
    <div
      className={
        isProfileSection
          ? 'profile__footer'
          : isAdminSection
          ? 'footer__container footer'
          : ''
      }
    >
      <div className="main-footer">
        <Container>
          <Row>
            <Col xs="12" sm="6" md="4" lg="3" className="main-footer__section">
              <div className="main-footer__logo">
                <img
                  src={logo}
                  alt="Fintex ltd. - Bringing Companies and Shareholders Together"
                />
              </div>
            </Col>
            <Col xs="12" sm="6" md="4" lg="2" className="main-footer__section">
              <p className="main-footer__heading">About us</p>
              <ul>
                <li className="main-footer__item">
                  <Link
                    className="main-footer__link"
                    to={{
                      pathname: '/',
                      state: {
                        target: 'top',
                        offset: -200,
                        duration: 500,
                        smooth: true,
                      },
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link
                    className="main-footer__link"
                    to={{
                      pathname: '/',
                      state: {
                        target: 'about',
                        offset: -100,
                        duration: 500,
                        smooth: true,
                      },
                    }}
                  >
                    About us
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link
                    className="main-footer__link"
                    to={{
                      pathname: '/',
                      state: {
                        target: 'contact',
                        offset: -100,
                        duration: 500,
                        smooth: true,
                      },
                    }}
                  >
                    Contact and support
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link
                    className="main-footer__link"
                    to={{
                      pathname: '/',
                      state: {
                        target: 'offices',
                        offset: -100,
                        duration: 500,
                        smooth: true,
                      },
                    }}
                  >
                    Offices
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link
                    className="main-footer__link"
                    to={{
                      pathname: '/',
                      state: {
                        target: 'corporate',
                        offset: -100,
                        duration: 500,
                        smooth: true,
                      },
                    }}
                  >
                    Corporate
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link className="main-footer__link" to="/career">
                    Career
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs="12" sm="6" md="4" lg="2" className="main-footer__section">
              <p className="main-footer__heading">Partners</p>
              <ul>
                {partnersSorted.map((item) => (
                  <li className="main-footer__item" key={shortid.generate()}>
                    <a
                      href={item.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="main-footer__link"
                    >
                      {item.partner}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs="12" sm="6" md="4" lg="2" className="main-footer__section">
              <p className="main-footer__heading">Legal</p>
              <ul>
                <li className="main-footer__item">
                  <Link to="/terms" className="main-footer__link">
                    Terms and conditions
                  </Link>
                </li>
                <li className="main-footer__item">
                  <Link to="/privacy" className="main-footer__link">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs="12" sm="6" md="4" lg="2" className="main-footer__section">
              <div>
                <p className="main-footer__heading">Follow us</p>
                <ul className="main-footer__social-list">
                  <li className="main-footer__item">
                    <a
                      href="https://www.linkedin.com/company/thefintexgroup/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="main-footer__social-icon main-footer__social-icon--linkedin"
                    >
                      <span>Linkedin</span>
                    </a>
                  </li>
                  <li className="main-footer__item">
                    <a
                      href="https://twitter.com/sharesinsideag"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="main-footer__social-icon main-footer__social-icon--twitter"
                    >
                      <span>Twitter</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs="12" sm="6" md="4" lg="1" className="main-footer__section">
              <div>
                <p className="main-footer__heading main-footer__heading--app">
                  Get the app
                </p>
                <ul className="main-footer__app-list">
                  <li className="main-footer__app-item">
                    <AppStoreButton />
                  </li>
                  <li className="main-footer__app-item">
                    <GooglePlayButton />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          style={{
            borderTop: '1px solid #3e4447',
            paddingTop: '25px',
            paddingBottom: '10px',
          }}
        >
          <Container>
            <p>
              Fintex offers both investors and shareholders a truly digital
              communication platform. With our free App (Download from the
              Google Play Store or Apple Store) public companies can now engage
              directly with shareholders and new investors, and investors can
              follow in real-time; companies and funds.
            </p>
            <p>
              With offices in the UK, South America, Japan, Switzerland,
              Australia and across the world fintex.ltd bridges the gap between
              companies and investors. Download our free app for investors today
              at
              {' '}
              <a href="https://play.google.com/store/apps/details?id=com.sharesinside.android">
                Google Play
              </a>
              {' '}
              or
              {' '}
              <a href="https://apps.apple.com/pl/app/sharesinside-ltd-fintech/id1156912257">
                AppStore
              </a>
            </p>
          </Container>
        </div>
        <div className="main-footer__copyright">
          <p className="main-footer__copyright-text">
            Copyright &copy; 2012-2024 Fintex LTD.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
