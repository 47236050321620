import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import Routes from 'routes';
import store from 'storeConfig';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import ScrollToTop from 'common/components/scrollToTop';
import MainHeader from 'common/components/mainHeader';
import MainFooter from 'common/components/mainFooter';
import MainDataLoader from 'common/containers/MainDataLoader';
import './styles/index.scss';
import { ThemeProvider } from 'providers/themeProvider';
import { unregister } from './registerServiceWorker';

unregister();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <ThemeProvider>
        <BrowserRouter>
          <MainDataLoader />
          <MainHeader />
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
          <MainFooter />
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root'),
);

ReactGA.initialize('G-14PZ4CE4JL');
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
});
hotjar.initialize(978593, 6);
