import React from 'react';
import './index.scss';

const AppStoreButton = () => (
  <a
    className="app-store-button"
    href="https://itunes.apple.com/pl/app/sharesinside-ltd-fintech/id1156912257"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span>Download on the App Store</span>
  </a>
);

export default AppStoreButton;
