import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_DATA_PREVIEW_SUCCESS } from 'profile/settings/redux/types';

const initialState = {
  followerData: [],
  followerMeta: {},
  shareholderData: [],
  shareholderMeta: {},
  vipData: [],
  vipMeta: {},
};

const DataPreviewReducer = createReducer(
  { ...initialState },
  {
    [FETCH_DATA_PREVIEW_SUCCESS]: (state, action) => {
      const { follower, shareholder, vip } = action.payload.data;
      let followerData;
      let shareholderData;
      let vipData;
      if (follower && follower.meta.current_page === 1) {
        followerData = follower ? [...follower.data] : [...state.followerData];
      } else {
        followerData = follower
          ? [...state.followerData, ...follower.data]
          : [...state.followerData];
      }
      if (shareholder && shareholder.meta.current_page === 1) {
        shareholderData = shareholder
          ? [...shareholder.data]
          : [...state.shareholderData];
      } else {
        shareholderData = shareholder
          ? [...state.shareholderData, ...shareholder.data]
          : [...state.shareholderData];
      }
      if (vip && vip.meta.current_page === 1) {
        vipData = vip ? [...vip.data] : [...state.vipData];
      } else {
        vipData = vip ? [...state.vipData, ...vip.data] : [...state.vipData];
      }
      return {
        ...state,
        followerData,
        followerMeta: follower ? follower.meta : state.followerMeta,
        shareholderData,
        shareholderMeta: shareholder ? shareholder.meta : state.shareholderMeta,
        vipData,
        vipMeta: vip ? vip.meta : state.vipMeta,
      };
    },
  },
);

export default DataPreviewReducer;
