import React, { memo } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import CookiesBanner from 'common/components/cookiesBanner';
import logo from 'assets/img/logo.png';
import useClasses from './hooks/useClasses';
import './index.scss';
import useMainHeader from './hooks/useMainHeader';
import MainHeaderNavigation from './MainHeaderNavigation';
import ThemeSwitch from '../themeToggleSwitch';

const MainHeader = () => {
  const {
    isMobileViewActive,
    isMobileNavOpen,
    toggleMobileNav,
    cookies,
    setCookiesAccept,
    setCookiesReject,
  } = useMainHeader();

  const {
    mainHeaderWrapperClasses,
    mainHeaderClasses,
    menuButtonClasses,
    mainNavListClasses,
    mainNavItemsClasses,
  } = useClasses(cookies, isMobileNavOpen, isMobileViewActive);

  return (
    <div className={mainHeaderWrapperClasses}>
      <div className={mainHeaderClasses}>
        {!cookies && (
          <CookiesBanner
            setCookiesAccept={setCookiesAccept}
            setCookiesReject={setCookiesReject}
          />
        )}

        <Container className="main-header__container">
          <div className="main-header__content">
            <Link to="/">
              <div className="main-header__logo">
                <img
                  src={logo}
                  className="main-header__logo-image"
                  alt="Fintex ltd. - Bringing Companies and Shareholders Together"
                />
              </div>
            </Link>
            {isMobileViewActive && (
              <button onClick={toggleMobileNav} className={menuButtonClasses}>
                <span className="menu-button__element" />
                <span className="menu-button__element" />
                <span className="menu-button__element" />
              </button>
            )}
            <MainHeaderNavigation
              isMobileViewActive={isMobileViewActive}
              toggleMobileNav={toggleMobileNav}
              mainNavListClasses={mainNavListClasses}
              mainNavItemsClasses={mainNavItemsClasses}
              cookies={cookies}
            />

            <ThemeSwitch />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default memo(MainHeader);
