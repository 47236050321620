import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_STARTUP_CATEGORIES_SUCCESS,
  UPDATE_ADMIN_STARTUP_CATEGORIES_REQUESTS,
} from 'adminPanel/adminContent/startupCategories/redux/types';

const initialState = {
  data: [],
  resultsNumber: null,
};

const StartupCategoriesReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_STARTUP_CATEGORIES_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        data: [...data],
        resultsNumber: data.length,
      };
    },
    [UPDATE_ADMIN_STARTUP_CATEGORIES_REQUESTS]: (state, action) => ({
      ...state,
      list: action.payload.updatedStartupCategoriesRequests,
    }),
  },
);

export default StartupCategoriesReducer;
