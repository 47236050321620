import { createReducer } from 'common/utils/reduxUtils';
import {
  SAVE_USER_DETAILS_FILTERS,
  SAVE_USER_DETAILS_SEARCH,
  REMOVE_USER_DETAILS_FILTERS,
} from 'adminPanel/adminContent/usersManagement/redux/types';

const initialState = {
  per_page: 10,
  user: 0,
  type: '',
  relation: 'admins',
  search: '',
};

const usersFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_USER_DETAILS_FILTERS]: (state, action) => {
      if (
        action.payload.filters.category === 'per_page' ||
        action.payload.filters.category === 'user' ||
        action.payload.filters.category === 'relation' ||
        action.payload.filters.category === 'type'
      ) {
        return {
          ...state,
          [action.payload.filters.category]: action.payload.filters.value,
        };
      }
      if (action.payload.filters && action.payload.filters[0]) {
        const { category } = action.payload.filters[0];
        return {
          ...state,
          [category]: action.payload.filters,
        };
      }
      if (action.payload.filters.category) {
        const { category } = action.payload.filters;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [SAVE_USER_DETAILS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [REMOVE_USER_DETAILS_FILTERS]: state => ({
      ...state,
      per_page: 10,
      search: '',
    }),
  },
);

export default usersFiltersReducer;
