import React from 'react';
import classNames from 'classnames';
import './index.scss';

const ModalContainer = ({ children, handleOutsideClick, className }) => (
  <div
    className="modal__wrapper"
    onClick={handleOutsideClick}
    role="button"
    onKeyPress={() => null}
    tabIndex="0"
  >
    <div
      className={classNames('modal__container', className)}
      onClick={e => {
        e.stopPropagation();
      }}
      role="button"
      onKeyPress={() => null}
      tabIndex="0"
    >
      {children}
    </div>
  </div>
);

export default ModalContainer;
