import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_LOCATION_DATA_SUCCESS } from 'common/redux/types';

const locationDataReducer = createReducer(
  {},
  {
    [FETCH_LOCATION_DATA_SUCCESS]: (state, action) => ({
      ...state,
      results: action.payload.results,
    }),
  },
);

export default locationDataReducer;
