import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { usePermissions } from 'permissions/hooks';

const PermissionRequired = ({
  permission,
  redirectFallback = null,
  children,
}) => {
  const { checkUserPermission } = usePermissions();
  const isAllowed = useMemo(() => checkUserPermission(permission), [
    checkUserPermission,
    permission,
  ]);
  return isAllowed ? (
    children
  ) : redirectFallback ? (
    <Redirect to={redirectFallback} />
  ) : null;
};

export default PermissionRequired;
