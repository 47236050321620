import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_LANDING_DATA_SUCCESS } from 'common/redux/types';

const landingReducer = createReducer(
  {},
  {
    [FETCH_LANDING_DATA_SUCCESS]: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
  },
);

export default landingReducer;
