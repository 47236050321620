import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_EMPLOYEES_LIST_SUCCESS,
  FETCH_EMPLOYEES_LIST_REQUEST,
  FETCH_EMPLOYEES_LIST_FAILURE,
  CLEAR_EMPLOYEES_LIST,
} from '../types';

const initialState = {
  list: [],
  isLoading: false,
};

const employeesListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_EMPLOYEES_LIST_SUCCESS]: (state, action) => {
      return {
        ...state,
        list: action.payload.employees,
        isLoading: false,
      };
    },
    [FETCH_EMPLOYEES_LIST_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_EMPLOYEES_LIST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [CLEAR_EMPLOYEES_LIST]: (state) => ({
      ...state,
      list: [],
      isLoading: false,
    }),
  },
);

export default employeesListReducer;
