import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_UPCOMING_EVENTS_SUCCESS } from '../types';

const initialState = {
  list: [],
};

const upcomingEventsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_UPCOMING_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),
  },
);

export default upcomingEventsReducer;
