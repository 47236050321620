import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_DEMO_COMPANIES_SUCCESS,
  FETCH_DEMO_COMPANIES_REQUEST,
  FETCH_DEMO_COMPANIES_FAILURE,
} from 'adminPanel/adminContent/demo/redux/types.js';

const initialState = {
  isLoading: false,
  list: [],
  nextPageIndex: null,
  meta: {},
};

const demoCompaniesReducer = createReducer(
  { ...initialState },
  {
    [FETCH_DEMO_COMPANIES_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.demoCompanies;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        isLoading: false,
        list: [...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        meta,
      };
    },
    [FETCH_DEMO_COMPANIES_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_DEMO_COMPANIES_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
);

export default demoCompaniesReducer;
