import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_FUNDS_MANAGER_SUCCESS,
  REMOVE_FUNDS_MANAGER,
} from 'common/redux/types';

const initialState = {
  currentFundsManager: {},
};

const fundsManagerReducer = createReducer(
  { ...initialState },
  {
    [FETCH_FUNDS_MANAGER_SUCCESS]: (state, action) => ({
      ...state,
      currentFundsManager: action.payload.currentFundsManager,
    }),
    [REMOVE_FUNDS_MANAGER]: state => ({
      ...state,
      currentFundsManager: {},
    }),
  },
);

export default fundsManagerReducer;
