import * as types from 'userAuth/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const { SET_USER_AUTH_TOKEN, REMOVE_USER_AUTH_TOKEN } = types;

const setUserAuthTokenAction = createActionCreator(
  SET_USER_AUTH_TOKEN,
  'token',
);

const removeUserAuthTokenAction = createActionCreator(REMOVE_USER_AUTH_TOKEN);

export const setUserAuthToken = (token) => (dispatch) => {
  dispatch(setUserAuthTokenAction(token));
};

export const removeUserAuthTokenAndResetState = () => (dispatch) => {
  // redux state will be reseted automaticially (see rootReducer)
  dispatch(removeUserAuthTokenAction());
};
