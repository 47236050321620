import { createReducer } from 'common/utils/reduxUtils';
import { SAVE_ADMIN_FUND_TYPES_SEARCH } from 'adminPanel/adminContent/fundTypes/redux/types';

const initialState = {
  sector: [],
  search: '',
  per_page: 10,
};

const IndustriesFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_ADMIN_FUND_TYPES_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default IndustriesFiltersReducer;
