export const REMOVE_SHAREHOLDERS_REQUESTS_FILTERS =
  'REMOVE_SHAREHOLDERS_REQUESTS_FILTERS';
export const SAVE_SHAREHOLDERS_REQUESTS_FILTERS =
  'SAVE_SHAREHOLDERS_REQUESTS_FILTERS';
export const SAVE_SHAREHOLDERS_REQUESTS_SEARCH =
  'SAVE_SHAREHOLDERS_REQUESTS_SEARCH';

export const FETCH_SHAREHOLDERS_REQUESTS_REQUEST =
  'FETCH_SHAREHOLDERS_REQUESTS_REQUEST';
export const FETCH_SHAREHOLDERS_REQUESTS_FAILURE =
  'FETCH_SHAREHOLDERS_REQUESTS_FAILURE';

export const FETCH_SHAREHOLDERS_REQUESTS_SUCCESS =
  'FETCH_SHAREHOLDERS_REQUESTS_SUCCESS';
export const UPDATE_SHAREHOLDERS_REQUESTS = 'UPDATE_SHAREHOLDERS_REQUESTS';
