import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCookiesAccept as setCookiesAcceptAction, setCookiesReject as setCookiesRejectAction } from 'common/redux/actions/cookiesActions.js';
import checkMobileViewActive from 'common/utils/viewUtils';
import { throttle } from 'lodash';

const useMainHeader = () => {
  const dispatch = useDispatch();

  const cookies = useSelector((state) => state.cookies);

  const [isMobileViewActive, setMobileViewActive] = useState(
    checkMobileViewActive(),
  );
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const setCookiesAccept = useCallback(
    () => dispatch(setCookiesAcceptAction()),
    [dispatch],
  );

  const setCookiesReject = useCallback(
      () => dispatch(setCookiesRejectAction()),
      [dispatch],
  );

  useEffect(() => {
    const checkIsMobileViewActive = throttle(
      () => setMobileViewActive(checkMobileViewActive()),
      500,
    );
    window.addEventListener('resize', checkIsMobileViewActive);
    return () => {
      window.removeEventListener('resize', checkIsMobileViewActive);
    };
  }, [setMobileViewActive]);

  const toggleMobileNav = useCallback(
    () => setMobileNavOpen((value) => !value),
    [],
  );

  return {
    isMobileViewActive,
    isMobileNavOpen,
    toggleMobileNav,
    cookies,
    setCookiesAccept,
    setCookiesReject
  };
};

export default useMainHeader;
