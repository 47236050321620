import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_DEMO_COMPANIES_FILTERS,
  SAVE_DEMO_COMPANIES_FILTERS,
  SAVE_DEMO_COMPANIES_SEARCH,
} from 'adminPanel/adminContent/demo/redux/types.js';

const initialState = {
  sector: [],
  country: [],
  continent: [],
  industry: [],
  search: '',
  relations: [],
  per_page: 10,
};

const demoCompaniesFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_DEMO_COMPANIES_FILTERS]: (state, action) => {
      if (action.payload.filter.category === 'per_page') {
        return {
          ...state,
          per_page: action.payload.filter.value,
        };
      }
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_DEMO_COMPANIES_FILTERS]: (state) => ({
      ...state,
      sector: [],
      country: [],
      continent: [],
      industry: [],
      relations: [],
    }),
    [SAVE_DEMO_COMPANIES_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
  },
);

export default demoCompaniesFiltersReducer;
