import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_OTHER_COMPANIES_SUCCESS } from 'company/redux/types';

const initialState = {
  list: [],
};

const otherCompaniesReducer = createReducer(
  { ...initialState },
  {
    [FETCH_OTHER_COMPANIES_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),
  },
);

export default otherCompaniesReducer;
