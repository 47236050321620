import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_STARTUP_GALLERY_SUCCESS,
  FETCH_STARTUP_ALLPHOTOS_SUCCESS,
} from 'common/redux/types';

const initialState = {
  data: [],
};

export const startupGalleryReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUP_GALLERY_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        data: [...data],
      };
    },
  },
);

export const allStartupPhotosReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUP_ALLPHOTOS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
      };
    },
  },
);
