import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthentication } from 'permissions/hooks';

const AuthenticationForbidden = ({ redirectFallback = null, children }) => {
  const { isAuthenticated } = useAuthentication();
  return !isAuthenticated ? (
    children
  ) : redirectFallback ? (
    <Redirect to={redirectFallback} />
  ) : null;
};

export default AuthenticationForbidden;
