import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_PROJECT_SUCCESS, REMOVE_PROJECT } from 'project/redux/types';
import { FETCH_LATEST_PROJECTS_SUCCESS } from '../types';

const initialState = {
  currentProject: {},
  latestProjects: [],
};

const ProjectReducer = createReducer(
  { ...initialState },
  {
    [FETCH_PROJECT_SUCCESS]: (state, action) => ({
      ...state,
      currentProject: action.payload.currentProject,
    }),
    [REMOVE_PROJECT]: (state) => ({
      ...state,
      currentProject: {},
    }),

    [FETCH_LATEST_PROJECTS_SUCCESS]: (state, action) => ({
      ...state,
      latestProjects: action.payload.latestProjects,
    }),
  },
);

export default ProjectReducer;
