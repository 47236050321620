import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_OTHER_STARTUPS_SUCCESS } from 'startup/redux/types';

const initialState = {
  list: [],
};

const otherStartupsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_OTHER_STARTUPS_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),
  },
);

export default otherStartupsReducer;
