export const FETCH_WATCHLIST_REQUEST = 'FETCH_WATCHLIST_REQUEST';
export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS';
export const FETCH_WATCHLIST_FAILURE = 'FETCH_WATCHLIST_FAILURE';

export const UPDATE_WATCHLIST = 'UPDATE_WATCHLIST';
export const CLEAR_WATCHLIST = 'CLEAR_WATCHLIST';

export const SAVE_WATCHLIST_FILTERS = 'SAVE_WATCHLIST_FILTERS';
export const REMOVE_WATCHLIST_FILTERS = 'REMOVE_WATCHLIST_FILTERS';
export const SAVE_WATCHLIST_SEARCH = 'SAVE_WATCHLIST_SEARCH';
export const SAVE_WATCHLIST_DATE_RANGE = 'SAVE_WATCHLIST_DATE_RANGE';
export const SAVE_WATCHLIST_EVENT_DATE = 'SAVE_WATCHLIST_EVENT_DATE';

export const SAVE_WATCHLIST_ADHOC_CHECKBOX = 'SAVE_WATCHLIST_ADHOC_CHECKBOX';

export const FETCH_WATCHLIST_CALENDAR_EVENTS_REQUEST =
  'FETCH_WATCHLIST_CALENDAR_EVENTS_REQUEST';
export const FETCH_WATCHLIST_CALENDAR_EVENTS_SUCCESS =
  'FETCH_WATCHLIST_CALENDAR_EVENTS_SUCCESS';
export const FETCH_WATCHLIST_CALENDAR_EVENTS_FAILURE =
  'FETCH_WATCHLIST_CALENDAR_EVENTS_FAILURE';

export const FETCH_WATCHLIST_UPCOMING_EVENTS_REQUEST =
  'FETCH_WATCHLIST_UPCOMING_EVENTS_REQUEST';
export const FETCH_WATCHLIST_UPCOMING_EVENTS_SUCCESS =
  'FETCH_WATCHLIST_UPCOMING_EVENTS_SUCCESS';
export const FETCH_WATCHLIST_UPCOMING_EVENTS_FAILURE =
  'FETCH_WATCHLIST_UPCOMING_EVENTS_FAILURE';

export const SAVE_WATCHLIST_CALENDAR_FETCHED_MONTHS =
  'SAVE_WATCHLIST_CALENDAR_FETCHED_MONTHS';

export const FETCH_WATCHLIST_LATEST_NEWS_LIST_REQUEST =
  'FETCH_WATCHLIST_LATEST_NEWS_LIST_REQUEST';
export const FETCH_WATCHLIST_LATEST_NEWS_LIST_SUCCESS =
  'FETCH_WATCHLIST_LATEST_NEWS_LIST_SUCCESS';
export const FETCH_WATCHLIST_LATEST_NEWS_LIST_FAILURE =
  'FETCH_WATCHLIST_LATEST_NEWS_LIST_FAILURE';
