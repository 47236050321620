import React from 'react';
import classNames from 'classnames';
import { Input as InputElement } from 'reactstrap';
import './index.scss';

const defaultProps = {
  type: 'text',
  className: '',
  onBlur: () => null,
  onChange: () => null,
  error: '',
  touched: false,
};

const Input = ({
  value,
  type,
  className,
  onBlur,
  onChange,
  name,
  error,
  touched,
  placeholder,
  maxLength,
  disabled,
  rightButton,
  buttonOnClick,
  onKeyPress,
  autoFocus,
  innerRef,
}) => {
  const classes = classNames({
    input: true,
    [className]: true,
    'right-border': rightButton,
    'input-error': touched && error,
  });

  return (
    <div className="input">
      <div
        className={classNames({
          input__container: true,
          input__small: rightButton,
        })}
      >
        <InputElement
          type={type}
          name={name}
          readOnly={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className={classes}
          maxLength={maxLength}
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          innerRef={innerRef}
        />
        {touched && error && <div className="input-error-message">{error}</div>}
      </div>
      {rightButton && (
        <div
          className="input__button"
          onClick={buttonOnClick}
          role="presentation"
        >
          {rightButton}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = defaultProps;

export default Input;
