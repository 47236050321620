export const FETCH_OTHER_COMPANIES_REQUEST = 'FETCH_OTHER_COMPANIES_REQUEST';
export const FETCH_OTHER_COMPANIES_SUCCESS = 'FETCH_OTHER_COMPANIES_SUCCESS';
export const FETCH_OTHER_COMPANIES_FAILURE = 'FETCH_OTHER_COMPANIES_FAILURE';

export const FETCH_UPCOMING_EVENTS_REQUEST = 'FETCH_UPCOMING_EVENTS_REQUEST';
export const FETCH_UPCOMING_EVENTS_SUCCESS = 'FETCH_UPCOMING_EVENTS_SUCCESS';
export const FETCH_UPCOMING_EVENTS_FAILURE = 'FETCH_UPCOMING_EVENTS_FAILURE';

export const SAVE_EVENTS_DATE_RANGE = 'SAVE_EVENTS_DATE_RANGE';
export const SAVE_EVENTS_DATE = 'SAVE_EVENTS_DATE';
export const SAVE_EVENTS_PUBLICITY_CHECKBOX = 'SAVE_EVENTS_PUBLICITY_CHECKBOX';
export const REMOVE_EVENTS_FILTERS = 'REMOVE_EVENTS_FILTERS';

export const FETCH_COMPANY_CALENDAR_EVENTS_REQUEST =
  'FETCH_COMPANY_CALENDAR_EVENTS_REQUEST';
export const FETCH_COMPANY_CALENDAR_EVENTS_SUCCESS =
  'FETCH_COMPANY_CALENDAR_EVENTS_SUCCESS';
export const FETCH_COMPANY_CALENDAR_EVENTS_FAILURE =
  'FETCH_COMPANY_CALENDAR_EVENST_FAILURE';

export const REMOVE_COMPANY_CALENDAR_EVENTS = 'REMOVE_COMPANY_CALENDAR_EVENTS';

export const SAVE_COMPANY_CALENDAR_FETCHED_MONTHS =
  'SAVE_COMPANY_CALENDAR_FETCHED_MONTHS';
