import axios from 'axios';
import * as types from 'common/redux/types';
import { createActionCreator } from 'common/utils/reduxUtils';

const {
  FETCH_STARTUP_REQUEST,
  FETCH_STARTUP_SUCCESS,
  FETCH_STARTUP_FAILURE,
  REMOVE_STARTUP,
} = types;

const fetchStartupRequest = createActionCreator(FETCH_STARTUP_REQUEST);
const fetchStartupSuccess = createActionCreator(
  FETCH_STARTUP_SUCCESS,
  'currentStartup',
);
const fetchStartupFailure = createActionCreator(FETCH_STARTUP_FAILURE);

export const fetchStartup = (id) => (dispatch) => {
  dispatch(fetchStartupRequest());
  return axios
    .get(`${process.env.REACT_APP_API_URL}/startups/${id}`)
    .then((response) => {
      dispatch(fetchStartupSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchStartupFailure());
    });
};

export const removeStartup = createActionCreator(REMOVE_STARTUP);
