import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_FUND_TYPES_LIST_SUCCESS } from 'common/redux/types';

const fundTypesListReducer = createReducer([], {
  [FETCH_FUND_TYPES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
  }),
});

export default fundTypesListReducer;
