import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

const CircleSpinner = ({ color, className }) => (
  <Spinner name="circle" color={color} fadeIn="none" className={className} />
);

CircleSpinner.defaultProps = {
  color: '#193a67',
  className: null,
};

CircleSpinner.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CircleSpinner;
