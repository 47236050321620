import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_STARTUPS_REQUESTS_REQUEST,
  FETCH_STARTUPS_REQUESTS_SUCCESS,
  FETCH_STARTUPS_REQUESTS_FAILURE,
  UPDATE_STARTUPS_REQUESTS,
} from 'adminPanel/redux/types';

const initialState = {
  data: [],
  nextPageIndex: null,
  resultsNumber: null,
  meta: {},
  isLoading: false,
};

const startupsRequestsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_STARTUPS_REQUESTS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        meta,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_STARTUPS_REQUESTS_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_STARTUPS_REQUESTS_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [UPDATE_STARTUPS_REQUESTS]: (state, action) => ({
      ...state,
      list: action.payload.updatedStartupsRequests,
    }),
  },
);

export default startupsRequestsReducer;
