import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUND_TYPES_SUCCESS,
  UPDATE_ADMIN_FUND_TYPES_REQUESTS,
} from 'adminPanel/adminContent/fundTypes/redux/types';

const initialState = {
  data: [],
  resultsNumber: null,
};

const FundTypesReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUND_TYPES_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        data: [...data],
        resultsNumber: data.length,
      };
    },
    [UPDATE_ADMIN_FUND_TYPES_REQUESTS]: (state, action) => ({
      ...state,
      list: action.payload.updatedCompaniesRequests,
    }),
  },
);

export default FundTypesReducer;
