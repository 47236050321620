import React from 'react';
import { Link } from 'react-router-dom';
import PermissionRequired from 'permissions/components/permissionRequired';
import { PERMISSIONS_FUNCTION_TYPES } from 'userAuth/utils/permissionTypes';
import AuthenticationRequired from 'permissions/components/authenticationRequired';

const LoggedInOptionsNavigation = ({ toggleOptionsMenu }) => (
  <AuthenticationRequired redirectFallback={null}>
    <PermissionRequired permission={PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST}>
      <li className="main-header-options-menu__item">
        <Link
          to="/watchlist"
          onClick={toggleOptionsMenu}
          className="main-header-options-menu__link"
        >
          Watchlist
        </Link>
      </li>
    </PermissionRequired>
    <PermissionRequired permission={PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES}>
      <li className="main-header-options-menu__item">
        <Link
          to="/companies"
          onClick={toggleOptionsMenu}
          className="main-header-options-menu__link"
        >
          Equities
        </Link>
      </li>
    </PermissionRequired>
    <PermissionRequired
      permission={PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS}
    >
      <li className="main-header-options-menu__item">
        <Link
          to="/fundsmanagers"
          onClick={toggleOptionsMenu}
          className="main-header-options-menu__link"
        >
          Funds
        </Link>
      </li>
    </PermissionRequired>
    <PermissionRequired permission={PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS}>
      <li className="main-header-options-menu__item">
        <Link
          to="/startups"
          onClick={toggleOptionsMenu}
          className="main-header-options-menu__link"
        >
          Startups
        </Link>
      </li>
    </PermissionRequired>
  </AuthenticationRequired>
);

export default LoggedInOptionsNavigation;
