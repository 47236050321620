import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_WATCHLIST_SUCCESS,
  FETCH_WATCHLIST_REQUEST,
  FETCH_WATCHLIST_FAILURE,
  UPDATE_WATCHLIST,
  CLEAR_WATCHLIST,
} from 'watchlist/redux/types';

const initialState = {
  data: [],
  hasNextPage: false,
  nextPageIndex: null,
  isLoading: false,
};

const watchlistReducer = createReducer(
  { ...initialState },
  {
    [FETCH_WATCHLIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        data: meta.current_page === 1 ? [...data] : [...state.data, ...data],
        hasNextPage,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        isLoading: false,
      };
    },
    [FETCH_WATCHLIST_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_WATCHLIST_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [UPDATE_WATCHLIST]: (state, action) => ({
      ...state,
      data: [...action.payload.data],
    }),
    [CLEAR_WATCHLIST]: (state) => ({
      ...state,
      data: [],
      hasNextPage: false,
      nextPageIndex: null,
      isLoading: false,
    }),
  },
);

export default watchlistReducer;
