import { saveState } from 'stateLoader';

const saveAuthData = (token, userData) =>
  saveState({
    expireTime: new Date().getTime() + 24 * 3600000,
    state: {
      userData,
      auth: {
        token,
      },
    },
  });

const clearAuthData = () => saveAuthData(null, {});

export { saveAuthData, clearAuthData };
