export const PERMISSIONS_FUNCTION_TYPES = {
  BEING_AS_MEMBER: 'BEING_AS_MEMBER',
  BEING_AS_COMPANY_EDITOR: 'BEING_AS_COMPANY_EDITOR',
  MANAGE_NEWS: 'MANAGE_NEWS',
  MANAGE_EVENTS: 'MANAGE_EVENTS',
  MANAGE_COMPANY_EDITORS: 'MANAGE_COMPANY_EDITORS',
  MANAGE_PRIMARY_ADMINISTRATOR: 'MANAGE_PRIMARY_ADMINISTRATOR',
  MANAGE_SECONDARY_ADMINISTRATOR: 'MANAGE_SECONDARY_ADMINISTRATOR',
  MANAGE_FUND_PRIMARY_ADMINISTRATOR: 'MANAGE_FUND_PRIMARY_ADMINISTRATOR',
  MANAGE_FUND_SECONDARY_ADMINISTRATOR: 'MANAGE_FUND_SECONDARY_ADMINISTRATOR',
  MANAGE_FUNDS_MANAGER_PRIMARY_ADMINISTRATOR:
    'MANAGE_FUNDS_MANAGER_PRIMARY_ADMINISTRATOR',
  MANAGE_FUNDS_MANAGER_SECONDARY_ADMINISTRATOR:
    'MANAGE_FUNDS_MANAGER_SECONDARY_ADMINISTRATOR',
  MANAGE_STARTUP_PRIMARY_ADMINISTRATOR: 'MANAGE_STARTUP_PRIMARY_ADMINISTRATOR',
  MANAGE_STARTUP_SECONDARY_ADMINISTRATOR:
    'MANAGE_STARTUP_SECONDARY_ADMINISTRATOR',
  MANAGE_INDUSTRIES: 'MANAGE_INDUSTRIES',
  MANAGE_FUND_TYPES: 'MANAGE_FUND_TYPES',
  MANAGE_STARTUP_CATEGORIES: 'MANAGE_STARTUP_CATEGORIES',
  MANAGE_DOMESTIC_ADMINISTRATORS: 'MANAGE_DOMESTIC_ADMINISTRATORS',
  MANAGE_COMPANY_REQUESTS: 'MANAGE_COMPANY_REQUESTS',
  MANAGE_LSE_DATA_REQUESTS: 'MANAGE_LSE_DATA_REQUESTS',
  MANAGE_FUNDS_MANAGER_REQUESTS: 'MANAGE_FUNDS_MANAGER_REQUESTS',
  MANAGE_STARTUP_REQUESTS: 'MANAGE_STARTUP_REQUESTS',
  MANAGE_INVESTOR_REQUESTS: 'MANAGE_INVESTOR_REQUESTS',
  MANAGING_MEMBERS: 'MANAGING_MEMBERS',
  MANAGING_FUND_MEMBERS: 'MANAGING_FUND_MEMBERS',
  MANAGING_STARTUP_MEMBERS: 'MANAGING_STARTUP_MEMBERS',
  MANAGING_SHAREHOLDERS: 'MANAGING_SHAREHOLDERS',
  MANAGING_PUSH_NOTIFICATIONS_OPTIONS: 'MANAGING_PUSH_NOTIFICATIONS_OPTIONS',
  VIEW_WATCHLIST: 'VIEW_WATCHLIST',
  VIEW_NEWS: 'VIEW_NEWS',
  VIEW_PROJECTS: 'VIEW_PROJECTS',
  VIEW_EVENTS: 'VIEW_EVENTS',
  VIEW_COMPANIES: 'VIEW_COMPANIES',
  VIEW_FUNDS_MANAGERS: 'VIEW_FUNDS_MANAGERS',
  VIEW_STARTUPS: 'VIEW_STARTUPS',
  VIEW_FUNDS: 'VIEW_FUNDS',
  VIEW_STATISTICS: 'VIEW_STATISTICS',
  VIEW_FUND_STATISTICS: 'VIEW_FUND_STATISTICS',
  VIEW_STARTUP_STATISTICS: 'VIEW_STARTUP_STATISTICS',
  VIEW_USER_LOGIN_DATA: 'VIEW_USER_LOGIN_DATA',
  VIEWING_USER_LIST: 'VIEWING_USER_LIST',
  FOLLOW_COMPANIES: 'FOLLOW_COMPANIES',
  BROWSE_NOTIFICATIONS: 'BROWSE_NOTIFICATIONS',
  OPEN_ADMIN_PANEL: 'OPEN_ADMIN_PANEL',
  REQUEST_AS_SHAREHOLDER: 'REQUEST_AS_SHAREHOLDER',
  EDIT_COMPANY_INFO: 'EDIT_COMPANY_INFO',
  EDIT_FUND_INFO: 'EDIT_FUND_INFO',
  EDIT_STARTUP_INFO: 'EDIT_STARTUP_INFO',
  CHANGE_COMPANY_VISUAL_STYLE: 'CHANGE_COMPANY_VISUAL_STYLE',
  REMOVE_COMPANY: 'REMOVE_COMPANY',
  REMOVE_FUNDS_MANAGER: 'REMOVE_FUNDS_MANAGER',
  REMOVE_STARTUP: 'REMOVE_STARTUP',
  RECEIVING_PUSH_NOTIFICATIONS: 'RECEIVING_PUSH_NOTIFICATIONS',
  CHANGE_USER_SETTINGS: 'CHANGE_USER_SETTINGS',
};

export const PERMISSION_USERS_TYPES = [
  {
    TYPE: 'DEMO_USER',
    OBJECT_PROPERTIES: 'is_demo_account',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
    ],
  },
  {
    TYPE: 'REGULAR_USER',
    OBJECT_PROPERTIES: false,
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
    ],
  },
  {
    TYPE: 'SHAREHOLDER',
    OBJECT_PROPERTIES: false,
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
    ],
  },
  {
    TYPE: 'MEMBER',
    OBJECT_PROPERTIES: false,
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
    ],
  },
  {
    TYPE: 'FUND_EDITOR',
    OBJECT_PROPERTIES: 'is_editor_fund',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'FUNDS_MANAGER_EDITOR',
    OBJECT_PROPERTIES: 'is_editor_funds_manager',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'STARTUP_EDITOR',
    OBJECT_PROPERTIES: 'is_editor_startup',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_STARTUP_INFO,
    ],
  },
  {
    TYPE: 'EDITOR',
    OBJECT_PROPERTIES: 'is_editor',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
    ],
  },
  {
    TYPE: 'FUND_SECONDARY_ADMIN',
    OBJECT_PROPERTIES: 'is_secondary_admin_fund',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'FUNDS_MANAGER_SECONDARY_ADMIN',
    OBJECT_PROPERTIES: 'is_secondary_admin_funds_manager',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUND_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INVESTOR_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'STARTUP_SECONDARY_ADMIN',
    OBJECT_PROPERTIES: 'is_secondary_admin_startup',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUP_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_STARTUP_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INVESTOR_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_STARTUP_INFO,
    ],
  },
  {
    TYPE: 'SECONDARY_ADMIN',
    OBJECT_PROPERTIES: 'is_secondary_admin',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_SHAREHOLDERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
    ],
  },
  {
    TYPE: 'FUND_PRIMARY_ADMIN',
    OBJECT_PROPERTIES: 'is_primary_admin_fund',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'FUNDS_MANAGER_PRIMARY_ADMIN',
    OBJECT_PROPERTIES: 'is_primary_admin_funds_manager',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUND_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INVESTOR_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'STARTUP_PRIMARY_ADMIN',
    OBJECT_PROPERTIES: 'is_primary_admin_startup',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_STARTUP_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUP_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INVESTOR_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_STARTUP_INFO,
    ],
  },
  {
    TYPE: 'PRIMARY_ADMIN',
    OBJECT_PROPERTIES: 'is_primary_admin',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_SHAREHOLDERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
    ],
  },
  {
    TYPE: 'DOMESTIC_ADMIN',
    OBJECT_PROPERTIES: 'is_domestic_admin',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_COMPANY,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_STARTUP,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_FUNDS_MANAGER,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
    ],
  },
  {
    TYPE: 'CONTENT_ADMIN',
    OBJECT_PROPERTIES: 'is_content_admin',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INDUSTRIES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_TYPES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_CATEGORIES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_DOMESTIC_ADMINISTRATORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_LSE_DATA_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_STARTUP_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUND_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUP_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_COMPANY,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_FUNDS_MANAGER,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_STARTUP,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_USER_LOGIN_DATA,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
      PERMISSIONS_FUNCTION_TYPES.EDIT_STARTUP_INFO,
    ],
  },
  {
    TYPE: 'GLOBAL_ADMIN',
    OBJECT_PROPERTIES: 'is_global_admin',
    PERMISSIONS: [
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_MEMBER,
      PERMISSIONS_FUNCTION_TYPES.BEING_AS_COMPANY_EDITOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_PROJECTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_EDITORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_PRIMARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_SECONDARY_ADMINISTRATOR,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INDUSTRIES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUND_TYPES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_CATEGORIES,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_DOMESTIC_ADMINISTRATORS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_COMPANY_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_LSE_DATA_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_FUNDS_MANAGER_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_STARTUP_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGE_INVESTOR_REQUESTS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_FUND_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_STARTUP_MEMBERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_SHAREHOLDERS,
      PERMISSIONS_FUNCTION_TYPES.MANAGING_PUSH_NOTIFICATIONS_OPTIONS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_WATCHLIST,
      PERMISSIONS_FUNCTION_TYPES.VIEW_NEWS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_EVENTS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUPS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUND_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_STARTUP_STATISTICS,
      PERMISSIONS_FUNCTION_TYPES.VIEWING_USER_LIST,
      PERMISSIONS_FUNCTION_TYPES.FOLLOW_COMPANIES,
      PERMISSIONS_FUNCTION_TYPES.BROWSE_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.OPEN_ADMIN_PANEL,
      PERMISSIONS_FUNCTION_TYPES.REQUEST_AS_SHAREHOLDER,
      PERMISSIONS_FUNCTION_TYPES.EDIT_COMPANY_INFO,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_COMPANY_VISUAL_STYLE,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_COMPANY,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_FUNDS_MANAGER,
      PERMISSIONS_FUNCTION_TYPES.REMOVE_STARTUP,
      PERMISSIONS_FUNCTION_TYPES.RECEIVING_PUSH_NOTIFICATIONS,
      PERMISSIONS_FUNCTION_TYPES.CHANGE_USER_SETTINGS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_USER_LOGIN_DATA,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS_MANAGERS,
      PERMISSIONS_FUNCTION_TYPES.VIEW_FUNDS,
      PERMISSIONS_FUNCTION_TYPES.EDIT_FUND_INFO,
      PERMISSIONS_FUNCTION_TYPES.EDIT_STARTUP_INFO,
    ],
  },
];
