import React from 'react';
import Contact from 'landing/components/contactSection';
import useLanding from 'landing/components/landingView/useLanding';

const ContactSection = () => {
  const { landingData, location } = useLanding();

  return <Contact routerState={location.state} landingData={landingData} />;
};

export default ContactSection;
