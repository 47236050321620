import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_OTHER_FUNDS_MANAGERS_SUCCESS } from 'fundsManager/redux/types';

const initialState = {
  list: [],
};

const otherFundsManagersReducer = createReducer(
  { ...initialState },
  {
    [FETCH_OTHER_FUNDS_MANAGERS_SUCCESS]: (state, action) => ({
      ...state,
      list: action.payload.list,
    }),
  },
);

export default otherFundsManagersReducer;
