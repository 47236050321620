import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_STARTUPS_FILTERS,
  SAVE_STARTUPS_FILTERS,
  SAVE_STARTUPS_SEARCH,
  SAVE_STARTUPS_RAISED_RANGE,
  SAVE_STARTUPS_SORT_CHECKBOX,
} from 'startups/redux/types';

const initialState = {
  country: [],
  category: [],
  continent: [],
  currency: [],
  equityFund: [],
  raisedRange: {},
  sortBy: 'default',
  relations: [],
  search: '',
};

const startupsFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_STARTUPS_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_STARTUPS_FILTERS]: (state) => ({
      ...state,
      country: [],
      category: [],
      continent: [],
      currency: [],
      equityFund: [],
      raisedRange: {},
      relations: [],
      sortBy: 'default',
    }),
    [SAVE_STARTUPS_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [SAVE_STARTUPS_RAISED_RANGE]: (state, action) => ({
      ...state,
      raisedRange: action.payload.raisedRange,
    }),
    [SAVE_STARTUPS_SORT_CHECKBOX]: (state, action) => ({
      ...state,
      sortBy: action.payload.sortBy ? 'most_viewed' : 'default',
    }),
  },
);

export default startupsFiltersReducer;
