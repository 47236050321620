import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_STARTUP_SUCCESS,
  REMOVE_ADMIN_STARTUP,
} from 'adminPanel/redux/types';

const initialState = {
  currentStartup: {},
};

const adminStartupReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_STARTUP_SUCCESS]: (state, action) => ({
      ...state,
      currentStartup: action.payload.currentStartup,
    }),
    [REMOVE_ADMIN_STARTUP]: (state) => ({
      ...state,
      currentStartup: {},
    }),
  },
);

export default adminStartupReducer;
