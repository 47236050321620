import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_INDUSTRIES_LIST_SUCCESS } from 'common/redux/types';

const industriesListReducer = createReducer([], {
  [FETCH_INDUSTRIES_LIST_SUCCESS]: (state, action) => ({
    ...state,
    list: action.payload.list,
  }),
});

export default industriesListReducer;
