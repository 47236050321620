import { createReducer } from 'common/utils/reduxUtils';
import { FETCH_ADMIN_USERS_LIST_SUCCESS } from 'adminPanel/redux/types';

const initialState = {
  data: [],
  hasNextPage: false,
  nextPageIndex: null,
};

const adminUsersListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_USERS_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.data;
      const hasNextPage = meta.current_page < meta.last_page;

      return {
        ...state,
        data: [...data],
        meta,
        hasNextPage,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
  },
);

export default adminUsersListReducer;
