export const FETCH_COUNTRIES_LIST_REQUEST = 'FETCH_COUNTRIES_LIST_REQUEST';
export const FETCH_COUNTRIES_LIST_SUCCESS = 'FETCH_COUNTRIES_LIST_SUCCESS';
export const FETCH_COUNTRIES_LIST_FAILURE = 'FETCH_COUNTRIES_LIST_FAILURE';

export const FETCH_COUNTRY_CALLING_CODES_LIST_REQUEST =
  'FETCH_COUNTRY_CALLING_CODES_LIST_REQUEST';
export const FETCH_COUNTRY_CALLING_CODES_LIST_SUCCESS =
  'FETCH_COUNTRY_CALLING_CODES_LIST_SUCCESS';
export const FETCH_COUNTRY_CALLING_CODES_LIST_FAILURE =
  'FETCH_COUNTRY_CALLING_CODES_LIST_FAILURE';

export const FETCH_INDUSTRIES_LIST_REQUEST = 'FETCH_INDUSTRIES_LIST_REQUEST';
export const FETCH_INDUSTRIES_LIST_SUCCESS = 'FETCH_INDUSTRIES_LIST_SUCCESS';
export const FETCH_INDUSTRIES_LIST_FAILURE = 'FETCH_INDUSTRIES_LIST_FAILURE';

export const FETCH_FUND_TYPES_LIST_REQUEST = 'FETCH_FUND_TYPES_LIST_REQUEST';
export const FETCH_FUND_TYPES_LIST_SUCCESS = 'FETCH_FUND_TYPES_LIST_SUCCESS';
export const FETCH_FUND_TYPES_LIST_FAILURE = 'FETCH_FUND_TYPES_LIST_FAILURE';

export const FETCH_STARTUP_CATEGORIES_LIST_REQUEST =
  'FETCH_STARTUP_CATEGORIES_LIST_REQUEST';
export const FETCH_STARTUP_CATEGORIES_LIST_SUCCESS =
  'FETCH_STARTUP_CATEGORIES_LIST_SUCCESS';
export const FETCH_STARTUP_CATEGORIES_LIST_FAILURE =
  'FETCH_STARTUP_CATEGORIES_LIST_FAILURE';

export const FETCH_CURRENCIES_LIST_REQUEST = 'FETCH_CURRENCIES_LIST_REQUEST';
export const FETCH_CURRENCIES_LIST_SUCCESS = 'FETCH_CURRENCIES_LIST_SUCCESS';
export const FETCH_CURRENCIES_LIST_FAILURE = 'FETCH_CURRENCIES_LIST_FAILURE';

export const FETCH_CONTINENTS_LIST_REQUEST = 'FETCH_CONTINENTS_LIST_REQUEST';
export const FETCH_CONTINENTS_LIST_SUCCESS = 'FETCH_CONTINENTS_LIST_SUCCESS';
export const FETCH_CONTINENTS_LIST_FAILURE = 'FETCH_CONTINENTS_LIST_FAILURE';

export const FETCH_SECTORS_LIST_REQUEST = 'FETCH_SECTORS_LIST_REQUEST';
export const FETCH_SECTORS_LIST_SUCCESS = 'FETCH_SECTORS_LIST_SUCCESS';
export const FETCH_SECTORS_LIST_FAILURE = 'FETCH_SECTORS_LIST_FAILURE';

export const FETCH_LOCATION_DATA_REQUEST = 'FETCH_LOCATION_DATA_REQUEST';
export const FETCH_LOCATION_DATA_SUCCESS = 'FETCH_LOCATION_DATA_SUCCESS';
export const FETCH_LOCATION_DATA_FAILURE = 'FETCH_LOCATION_DATA_FAILURE';

export const FETCH_SYMBOLS_LIST_REQUEST = 'FETCH_SYMBOLS_LIST_REQUEST';
export const FETCH_SYMBOLS_LIST_SUCCESS = 'FETCH_SYMBOLS_LIST_SUCCESS';
export const FETCH_SYMBOLS_LIST_FAILURE = 'FETCH_SYMBOLS_LIST_FAILURE';
export const CLEAR_SYMBOLS_LIST = 'CLEAR_SYMBOLS_LIST';

export const FETCH_EXCHANGES_LIST_REQUEST = 'FETCH_EXCHANGES_LIST_REQUEST';
export const FETCH_EXCHANGES_LIST_SUCCESS = 'FETCH_EXCHANGES_LIST_SUCCESS';
export const FETCH_EXCHANGES_LIST_FAILURE = 'FETCH_EXCHANGES_LIST_FAILURE';

export const GET_COOKIE_ACCEPT = 'GET_COOKIE_ACCEPT';
export const SET_COOKIE_ACCEPT = 'SET_COOKIE_ACCEPT';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';

export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';

export const FETCH_COMPANY_NEWS_REQUEST = 'FETCH_COMPANY_NEWS_REQUEST';
export const FETCH_COMPANY_NEWS_SUCCESS = 'FETCH_COMPANY_NEWS_SUCCESS';
export const FETCH_COMPANY_NEWS_FAILURE = 'FETCH_COMPANY_NEWS_FAILURE';
export const REMOVE_COMPANY_NEWS = 'REMOVE_COMPANY_NEWS';

export const FETCH_FUND_NEWS_REQUEST = 'FETCH_FUND_NEWS_REQUEST';
export const FETCH_FUND_NEWS_SUCCESS = 'FETCH_FUND_NEWS_SUCCESS';
export const FETCH_FUND_NEWS_FAILURE = 'FETCH_FUND_NEWS_FAILURE';
export const REMOVE_FUND_NEWS = 'REMOVE_FUND_NEWS';

export const FETCH_FUNDS_MANAGER_NEWS_REQUEST =
  'FETCH_FUNDS_MANAGER_NEWS_REQUEST';
export const FETCH_FUNDS_MANAGER_NEWS_SUCCESS =
  'FETCH_FUNDS_MANAGER_NEWS_SUCCESS';
export const FETCH_FUNDS_MANAGER_NEWS_FAILURE =
  'FETCH_FUNDS_MANAGER_NEWS_FAILURE';
export const REMOVE_FUNDS_MANAGER_NEWS = 'REMOVE_FUNDS_MANAGER_NEWS';

export const FETCH_STARTUP_NEWS_REQUEST = 'FETCH_STARTUP_NEWS_REQUEST';
export const FETCH_STARTUP_NEWS_SUCCESS = 'FETCH_STARTUP_NEWS_SUCCESS';
export const FETCH_STARTUP_NEWS_FAILURE = 'FETCH_STARTUP_NEWS_FAILURE';
export const REMOVE_STARTUP_NEWS = 'REMOVE_STARTUP_NEWS';

export const FETCH_COMPANY_EVENTS_REQUEST = 'FETCH_COMPANY_EVENTS_REQUEST';
export const FETCH_COMPANY_EVENTS_SUCCESS = 'FETCH_COMPANY_EVENTS_SUCCESS';
export const FETCH_COMPANY_EVENTS_FAILURE = 'FETCH_COMPANY_EVENTS_FAILURE';
export const REMOVE_COMPANY_EVENTS = 'REMOVE_COMPANY_EVENTS';

export const FETCH_FUND_EVENTS_REQUEST = 'FETCH_FUND_EVENTS_REQUEST';
export const FETCH_FUND_EVENTS_SUCCESS = 'FETCH_FUND_EVENTS_SUCCESS';
export const FETCH_FUND_EVENTS_FAILURE = 'FETCH_FUND_EVENTS_FAILURE';
export const REMOVE_FUND_EVENTS = 'REMOVE_FUND_EVENTS';

export const FETCH_FUNDS_MANAGER_EVENTS_REQUEST =
  'FETCH_FUNDS_MANAGER_EVENTS_REQUEST';
export const FETCH_FUNDS_MANAGER_EVENTS_SUCCESS =
  'FETCH_FUNDS_MANAGER_EVENTS_SUCCESS';
export const FETCH_FUNDS_MANAGER_EVENTS_FAILURE =
  'FETCH_FUNDS_MANAGER_EVENTS_FAILURE';
export const REMOVE_FUNDS_MANAGER_EVENTS = 'REMOVE_FUNDS_MANAGER_EVENTS';

export const FETCH_STARTUP_EVENTS_REQUEST = 'FETCH_STARTUP_EVENTS_REQUEST';
export const FETCH_STARTUP_EVENTS_SUCCESS = 'FETCH_STARTUP_EVENTS_SUCCESS';
export const FETCH_STARTUP_EVENTS_FAILURE = 'FETCH_STARTUP_EVENTS_FAILURE';
export const REMOVE_STARTUP_EVENTS = 'REMOVE_STARTUP_EVENTS';

export const FETCH_COMPANY_HISTORICAL_DATA_REQUEST =
  'FETCH_COMPANY_HISTORICAL_DATA_REQUEST';
export const FETCH_COMPANY_HISTORICAL_DATA_SUCCESS =
  'FETCH_COMPANY_HISTORICAL_DATA_SUCCESS';
export const FETCH_COMPANY_HISTORICAL_MORE_DATA_SUCCESS =
  'FETCH_COMPANY_HISTORICAL_MORE_DATA_SUCCESS';
export const FETCH_COMPANY_HISTORICAL_DATA_FAILURE =
  'FETCH_COMPANY_HISTORICAL_DATA_FAILURE';
export const REMOVE_COMPANY_HISTORICAL_DATA = 'REMOVE_COMPANY_HISTORICAL_DATA';

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILURE = 'FETCH_COMPANY_FAILURE';

export const FETCH_FUNDS_MANAGER_REQUEST = 'FETCH_FUNDS_MANAGER_REQUEST';
export const FETCH_FUNDS_MANAGER_SUCCESS = 'FETCH_FUNDS_MANAGER_SUCCESS';
export const FETCH_FUNDS_MANAGER_FAILURE = 'FETCH_FUNDS_MANAGER_FAILURE';
export const REMOVE_FUNDS_MANAGER = 'REMOVE_FUNDS_MANAGER';

export const FETCH_STARTUP_REQUEST = 'FETCH_STARTUP_REQUEST';
export const FETCH_STARTUP_SUCCESS = 'FETCH_STARTUP_SUCCESS';
export const FETCH_STARTUP_FAILURE = 'FETCH_STARTUP_FAILURE';
export const REMOVE_STARTUP = 'REMOVE_STARTUP';

export const FETCH_FUND_REQUEST = 'FETCH_FUND_REQUEST';
export const FETCH_FUND_SUCCESS = 'FETCH_FUND_SUCCESS';
export const FETCH_FUND_FAILURE = 'FETCH_FUND_FAILURE';
export const REMOVE_FUND = 'REMOVE_FUND';

export const FETCH_COMPANIES_NAMES_LIST_REQUEST =
  'FETCH_COMPANIES_NAMES_LIST_REQUEST';
export const FETCH_COMPANIES_NAMES_LIST_SUCCESS =
  'FETCH_COMPANIES_NAMES_LIST_SUCCESS';
export const FETCH_COMPANIES_NAMES_LIST_FAILURE =
  'FETCH_COMPANIES_NAMES_LIST_FAILURE';

export const FETCH_FUNDS_MANAGERS_NAMES_LIST_REQUEST =
  'FETCH_FUNDS_MANAGERS_NAMES_LIST_REQUEST';
export const FETCH_FUNDS_MANAGERS_NAMES_LIST_SUCCESS =
  'FETCH_FUNDS_MANAGERS_NAMES_LIST_SUCCESS';
export const FETCH_FUNDS_MANAGERS_NAMES_LIST_FAILURE =
  'FETCH_FUNDS_MANAGERS_NAMES_LIST_FAILURE';

export const FETCH_FUNDS_NAMES_LIST_REQUEST = 'FETCH_FUNDS_NAMES_LIST_REQUEST';
export const FETCH_FUNDS_NAMES_LIST_SUCCESS = 'FETCH_FUNDS_NAMES_LIST_SUCCESS';
export const FETCH_FUNDS_NAMES_LIST_FAILURE = 'FETCH_FUNDS_NAMES_LIST_FAILURE';

export const FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_REQUEST =
  'FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_REQUEST';
export const FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_SUCCESS =
  'FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_SUCCESS';
export const FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_FAILURE =
  'FETCH_FUND_MANAGERS_WITH_FUNDS_LIST_FAILURE';

export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const ADD_COMPANY_HISTORICAL_DATA_REQUEST =
  'ADD_COMPANY_HISTORICAL_DATA_REQUEST';
export const ADD_COMPANY_HISTORICAL_DATA_SUCCESS =
  'ADD_COMPANY_HISTORICAL_DATA_SUCCESS';
export const ADD_COMPANY_HISTORICAL_DATA_FAILURE =
  'ADD_COMPANY_HISTORICAL_DATA_FAILURE';

export const EDIT_COMPANY_HISTORICAL_DATA_REQUEST =
  'EDIT_COMPANY_HISTORICAL_DATA_REQUEST';
export const EDIT_COMPANY_HISTORICAL_DATA_SUCCESS =
  'EDIT_COMPANY_HISTORICAL_DATA_SUCCESS';
export const EDIT_COMPANY_HISTORICAL_DATA_FAILURE =
  'EDIT_COMPANY_HISTORICAL_DATA_FAILURE';

export const ADD_COMPANY_HISTORICAL_SECTION_REQUEST =
  'ADD_COMPANY_HISTORICAL_SECTION_REQUEST';
export const ADD_COMPANY_HISTORICAL_SECTION_SUCCESS =
  'ADD_COMPANY_HISTORICAL_SECTION_SUCCESS';
export const ADD_COMPANY_HISTORICAL_SECTION_FAILURE =
  'ADD_COMPANY_HISTORICAL_SECTION_FAILURE';

export const EDIT_COMPANY_HISTORICAL_SECTION_REQUEST =
  'EDIT_COMPANY_HISTORICAL_SECTION_REQUEST';
export const EDIT_COMPANY_HISTORICAL_SECTION_SUCCESS =
  'EDIT_COMPANY_HISTORICAL_SECTION_SUCCESS';
export const EDIT_COMPANY_HISTORICAL_SECTION_FAILURE =
  'EDIT_COMPANY_HISTORICAL_SECTION_FAILURE';

export const REMOVE_COMPANY_HISTORICAL_SECTION_REQUEST =
  'REMOVE_COMPANY_HISTORICAL_SECTION_REQUEST';
export const REMOVE_COMPANY_HISTORICAL_SECTION_SUCCESS =
  'REMOVE_COMPANY_HISTORICAL_SECTION_SUCCESS';
export const REMOVE_COMPANY_HISTORICAL_SECTION_FAILURE =
  'REMOVE_COMPANY_HISTORICAL_SECTION_FAILURE';

export const REMOVE_ONE_COMPANY_HISTORICAL_DATA_REQUEST =
  'REMOVE_ONE_COMPANY_HISTORICAL_DATA_REQUEST';
export const REMOVE_ONE_COMPANY_HISTORICAL_DATA_SUCCESS =
  'REMOVE_ONE_COMPANY_HISTORICAL_DATA_SUCCESS';
export const REMOVE_ONE_COMPANY_HISTORICAL_DATA_FAILURE =
  'REMOVE_ONE_COMPANY_HISTORICAL_DATA_FAILURE';

export const FETCH_FUNDS_MANAGER_DOCUMENTS_REQUEST =
  'FETCH_FUNDS_MANAGER_DOCUMENTS_REQUEST';
export const FETCH_FUNDS_MANAGER_DOCUMENTS_SUCCESS =
  'FETCH_FUNDS_MANAGER_DOCUMENTS_SUCCESS';
export const FETCH_FUNDS_MANAGER_DOCUMENTS_MORE_SUCCESS =
  'FETCH_FUNDS_MANAGER_DOCUMENTS_MORE_SUCCESS';
export const FETCH_FUNDS_MANAGER_DOCUMENTS_FAILURE =
  'FETCH_FUNDS_MANAGER_DOCUMENTS_FAILURE';

export const ADD_FUNDS_MANAGER_DOCUMENTS_REQUEST =
  'ADD_FUNDS_MANAGER_DOCUMENTS_REQUEST';
export const ADD_FUNDS_MANAGER_DOCUMENTS_SUCCESS =
  'ADD_FUNDS_MANAGER_DOCUMENTS_SUCCESS';
export const ADD_FUNDS_MANAGER_DOCUMENTS_FAILURE =
  'ADD_FUNDS_MANAGER_DOCUMENTS_FAILURE';

export const EDIT_FUNDS_MANAGER_DOCUMENTS_REQUEST =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_REQUEST';
export const EDIT_FUNDS_MANAGER_DOCUMENTS_SUCCESS =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_SUCCESS';
export const EDIT_FUNDS_MANAGER_DOCUMENTS_FAILURE =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_FAILURE';

export const REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_REQUEST =
  'REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_REQUEST';
export const REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_SUCCESS =
  'REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_SUCCESS';
export const REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_FAILURE =
  'REMOVE_ONE_FUNDS_MANAGER_DOCUMENTS_FAILURE';

export const REMOVE_FUNDS_MANAGER_DOCUMENTS = 'REMOVE_FUNDS_MANAGER_DOCUMENTS';
export const ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST =
  'ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST';
export const ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS =
  'ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS';
export const ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE =
  'ADD_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE';

export const EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST';
export const EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS';
export const EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE =
  'EDIT_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE';

export const REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST =
  'REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_REQUEST';
export const REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS =
  'REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_SUCCESS';
export const REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE =
  'REMOVE_FUNDS_MANAGER_DOCUMENTS_SECTION_FAILURE';

export const FETCH_STARTUP_DOCUMENTS_REQUEST =
  'FETCH_STARTUP_DOCUMENTS_REQUEST';
export const FETCH_STARTUP_DOCUMENTS_SUCCESS =
  'FETCH_STARTUP_DOCUMENTS_SUCCESS';
export const FETCH_STARTUP_DOCUMENTS_MORE_SUCCESS =
  'FETCH_STARTUP_DOCUMENTS_MORE_SUCCESS';
export const FETCH_STARTUP_DOCUMENTS_FAILURE =
  'FETCH_STARTUP_DOCUMENTS_FAILURE';

export const ADD_STARTUP_DOCUMENTS_REQUEST = 'ADD_STARTUP_DOCUMENTS_REQUEST';
export const ADD_STARTUP_DOCUMENTS_SUCCESS = 'ADD_STARTUP_DOCUMENTS_SUCCESS';
export const ADD_STARTUP_DOCUMENTS_FAILURE = 'ADD_STARTUP_DOCUMENTS_FAILURE';

export const EDIT_STARTUP_DOCUMENTS_REQUEST = 'EDIT_STARTUP_DOCUMENTS_REQUEST';
export const EDIT_STARTUP_DOCUMENTS_SUCCESS = 'EDIT_STARTUP_DOCUMENTS_SUCCESS';
export const EDIT_STARTUP_DOCUMENTS_FAILURE = 'EDIT_STARTUP_DOCUMENTS_FAILURE';

export const REMOVE_ONE_STARTUP_DOCUMENTS_REQUEST =
  'REMOVE_ONE_STARTUP_DOCUMENTS_REQUEST';
export const REMOVE_ONE_STARTUP_DOCUMENTS_SUCCESS =
  'REMOVE_ONE_STARTUP_DOCUMENTS_SUCCESS';
export const REMOVE_ONE_STARTUP_DOCUMENTS_FAILURE =
  'REMOVE_ONE_STARTUP_DOCUMENTS_FAILURE';

export const REMOVE_STARTUP_DOCUMENTS = 'REMOVE_STARTUP_DOCUMENTS';
export const ADD_STARTUP_DOCUMENTS_SECTION_REQUEST =
  'ADD_STARTUP_DOCUMENTS_SECTION_REQUEST';
export const ADD_STARTUP_DOCUMENTS_SECTION_SUCCESS =
  'ADD_STARTUP_DOCUMENTS_SECTION_SUCCESS';
export const ADD_STARTUP_DOCUMENTS_SECTION_FAILURE =
  'ADD_STARTUP_DOCUMENTS_SECTION_FAILURE';

export const EDIT_STARTUP_DOCUMENTS_SECTION_REQUEST =
  'EDIT_STARTUP_DOCUMENTS_SECTION_REQUEST';
export const EDIT_STARTUP_DOCUMENTS_SECTION_SUCCESS =
  'EDIT_STARTUP_DOCUMENTS_SECTION_SUCCESS';
export const EDIT_STARTUP_DOCUMENTS_SECTION_FAILURE =
  'EDIT_STARTUP_DOCUMENTS_SECTION_FAILURE';

export const REMOVE_STARTUP_DOCUMENTS_SECTION_REQUEST =
  'REMOVE_STARTUP_DOCUMENTS_SECTION_REQUEST';
export const REMOVE_STARTUP_DOCUMENTS_SECTION_SUCCESS =
  'REMOVE_STARTUP_DOCUMENTS_SECTION_SUCCESS';
export const REMOVE_STARTUP_DOCUMENTS_SECTION_FAILURE =
  'REMOVE_STARTUP_DOCUMENTS_SECTION_FAILURE';

export const FETCH_FUND_DOCUMENTS_REQUEST = 'FETCH_FUND_DOCUMENTS_REQUEST';
export const FETCH_FUND_DOCUMENTS_SUCCESS = 'FETCH_FUND_DOCUMENTS_SUCCESS';
export const FETCH_FUND_DOCUMENTS_MORE_SUCCESS =
  'FETCH_FUND_DOCUMENTS_MORE_SUCCESS';
export const FETCH_FUND_DOCUMENTS_FAILURE = 'FETCH_FUND_DOCUMENTS_FAILURE';

export const ADD_FUND_DOCUMENTS_REQUEST = 'ADD_FUND_DOCUMENTS_REQUEST';
export const ADD_FUND_DOCUMENTS_SUCCESS = 'ADD_FUND_DOCUMENTS_SUCCESS';
export const ADD_FUND_DOCUMENTS_FAILURE = 'ADD_FUND_DOCUMENTS_FAILURE';

export const EDIT_FUND_DOCUMENTS_REQUEST = 'EDIT_FUND_DOCUMENTS_REQUEST';
export const EDIT_FUND_DOCUMENTS_SUCCESS = 'EDIT_FUND_DOCUMENTS_SUCCESS';
export const EDIT_FUND_DOCUMENTS_FAILURE = 'EDIT_FUND_DOCUMENTS_FAILURE';

export const REMOVE_ONE_FUND_DOCUMENTS_REQUEST =
  'REMOVE_ONE_FUND_DOCUMENTS_REQUEST';
export const REMOVE_ONE_FUND_DOCUMENTS_SUCCESS =
  'REMOVE_ONE_FUND_DOCUMENTS_SUCCESS';
export const REMOVE_ONE_FUND_DOCUMENTS_FAILURE =
  'REMOVE_ONE_FUND_DOCUMENTS_FAILURE';

export const REMOVE_FUND_DOCUMENTS = 'REMOVE_FUND_DOCUMENTS';
export const ADD_FUND_DOCUMENTS_SECTION_REQUEST =
  'ADD_FUND_DOCUMENTS_SECTION_REQUEST';
export const ADD_FUND_DOCUMENTS_SECTION_SUCCESS =
  'ADD_FUND_DOCUMENTS_SECTION_SUCCESS';
export const ADD_FUND_DOCUMENTS_SECTION_FAILURE =
  'ADD_FUND_DOCUMENTS_SECTION_FAILURE';

export const EDIT_FUND_DOCUMENTS_SECTION_REQUEST =
  'EDIT_FUND_DOCUMENTS_SECTION_REQUEST';
export const EDIT_FUND_DOCUMENTS_SECTION_SUCCESS =
  'EDIT_FUND_DOCUMENTS_SECTION_SUCCESS';
export const EDIT_FUND_DOCUMENTS_SECTION_FAILURE =
  'EDIT_FUND_DOCUMENTS_SECTION_FAILURE';

export const REMOVE_FUND_DOCUMENTS_SECTION_REQUEST =
  'REMOVE_FUND_DOCUMENTS_SECTION_REQUEST';
export const REMOVE_FUND_DOCUMENTS_SECTION_SUCCESS =
  'REMOVE_FUND_DOCUMENTS_SECTION_SUCCESS';
export const REMOVE_FUND_DOCUMENTS_SECTION_FAILURE =
  'REMOVE_FUND_DOCUMENTS_SECTION_FAILURE';

export const FETCH_COMPANY_TEAM_REQUEST = 'FETCH_COMPANY_TEAM_REQUEST';
export const FETCH_COMPANY_TEAM_SUCCESS = 'FETCH_COMPANY_TEAM_SUCCESS';
export const FETCH_COMPANY_TEAM_FAILURE = 'FETCH_COMPANY_TEAM_FAILURE';

export const REMOVE_EMPLOYEE_REQUEST = 'REMOVE_EMPLOYEE_REQUEST';
export const REMOVE_EMPLOYEE_SUCCESS = 'REMOVE_EMPLOYEE_SUCCESS';
export const REMOVE_EMPLOYEE_FAILURE = 'REMOVE_EMPLOYEE_FAILURE';

export const UPDATE_COMPANY_SECTION_REQUEST = 'UPDATE_COMPANY_SECTION_REQUEST';

export const UPDATE_COMPANY_SECTION_SUCCESS = 'UPDATE_COMPANY_SECTION_REQUEST';

export const UPDATE_COMPANY_SECTION_FAILURE = 'UPDATE_COMPANY_SECTION_REQUEST';

export const CREATE_COMPANY_SECTION_REQUEST = 'CREATE_COMPANY_SECTION_REQUEST';
export const CREATE_COMPANY_SECTION_SUCCESS = 'CREATE_COMPANY_SECTION_SUCCESS';
export const CREATE_COMPANY_SECTION_FAILURE = 'CREATE_COMPANY_SECTION_FAILURE';

export const REMOVE_COMPANY_SECTION_REQUEST = 'REMOVE_COMPANY_SECTION_REQUEST';

export const REMOVE_COMPANY_SECTION_SUCCESS = 'REMOVE_COMPANY_SECTION_SUCCESS';
export const REMOVE_COMPANY_SECTION_FAILURE = 'REMOVE_COMPANY_SECTION_FAILURE';

export const FETCH_FUNDS_MANAGER_TEAM_REQUEST =
  'FETCH_FUNDS_MANAGER_TEAM_REQUEST';
export const FETCH_FUNDS_MANAGER_TEAM_SUCCESS =
  'FETCH_FUNDS_MANAGER_TEAM_SUCCESS';
export const FETCH_FUNDS_MANAGER_TEAM_FAILURE =
  'FETCH_FUNDS_MANAGER_TEAM_FAILURE';

export const REMOVE_FUNDS_MANAGER_EMPLOYEE_REQUEST =
  'REMOVE_FUNDS_MANAGER_EMPLOYEE_REQUEST';
export const REMOVE_FUNDS_MANAGER_EMPLOYEE_SUCCESS =
  'REMOVE_FUNDS_MANAGER_EMPLOYEE_SUCCESS';
export const REMOVE_FUNDS_MANAGER_EMPLOYEE_FAILURE =
  'REMOVE_FUNDS_MANAGER_EMPLOYEE_FAILURE';

export const UPDATE_FUNDS_MANAGER_SECTION_REQUEST =
  'UPDATE_FUNDS_MANAGER_SECTION_REQUEST';

export const UPDATE_FUNDS_MANAGER_SECTION_SUCCESS =
  'UPDATE_FUNDS_MANAGER_SECTION_SUCCESS';

export const UPDATE_FUNDS_MANAGER_SECTION_FAILURE =
  'UPDATE_FUNDS_MANAGER_SECTION_FAILURE';

export const CREATE_FUNDS_MANAGER_SECTION_REQUEST =
  'CREATE_FUNDS_MANAGER_SECTION_REQUEST';
export const CREATE_FUNDS_MANAGER_SECTION_SUCCESS =
  'CREATE_FUNDS_MANAGER_SECTION_SUCCESS';
export const CREATE_FUNDS_MANAGER_SECTION_FAILURE =
  'CREATE_FUNDS_MANAGER_SECTION_FAILURE';

export const REMOVE_FUNDS_MANAGER_SECTION_REQUEST =
  'REMOVE_FUNDS_MANAGER_SECTION_REQUEST';

export const REMOVE_FUNDS_MANAGER_SECTION_SUCCESS =
  'REMOVE_FUNDS_MANAGER_SECTION_SUCCESS';
export const REMOVE_FUNDS_MANAGER_SECTION_FAILURE =
  'REMOVE_FUNDS_MANAGER_SECTION_FAILURE';

export const FETCH_GALLERY_REQUEST = 'FETCH_GALLERY_REQUEST';
export const FETCH_GALLERY_SUCCESS = 'FETCH_GALLERY_SUCCESS';
export const FETCH_GALLERY_FAILURE = 'FETCH_GALLERY_FAILURE';

export const FETCH_ALLPHOTOS_REQUEST = 'FETCH_ALLPHOTOS_REQUEST';
export const FETCH_ALLPHOTOS_SUCCESS = 'FETCH_ALLPHOTOS_SUCCESS';
export const FETCH_ALLPHOTOS_FAILURE = 'FETCH_ALLPHOTOS_FAILURE';

export const FETCH_LANDING_DATA_REQUEST = 'FETCH_LANDING_DATA_REQUEST';
export const FETCH_LANDING_DATA_SUCCESS = 'FETCH_LANDING_DATA_SUCCESS';
export const FETCH_LANDING_DATA_FAILURE = 'FETCH_LANDING_DATA_FAILURE';

export const FETCH_OFFERS_REQUEST = 'FETCH_CAREER_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_CAREER_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_CAREER_FAILURE';

export const REMOVE_OFFERS_FILTERS = 'REMOVE_CAREER_FILTERS';
export const SAVE_OFFERS_FILTERS = 'SAVE_CAREER_FILTERS';
export const SAVE_OFFERS_SEARCH = 'SAVE_CAREER_SEARCH';

export const FETCH_SINGLE_OFFER_REQUEST = 'FETCH_SINGLE_OFFER_REQUEST';
export const FETCH_SINGLE_OFFER_SUCCESS = 'FETCH_SINGLE_OFFER_SUCCESS';
export const FETCH_SINGLE_OFFER_FAILURE = 'FETCH_SINGLE_OFFER_FAILURE';

export const REMOVE_SINGLE_OFFER = 'REMOVE_SINGLE_OFFER';

export const FETCH_STARTUP_TEAM_REQUEST = 'FETCH_STARTUP_TEAM_REQUEST';
export const FETCH_STARTUP_TEAM_SUCCESS = 'FETCH_STARTUP_TEAM_SUCCESS';
export const FETCH_STARTUP_TEAM_FAILURE = 'FETCH_STARTUP_TEAM_FAILURE';

export const REMOVE_STARTUP_EMPLOYEE_REQUEST =
  'REMOVE_STARTUP_EMPLOYEE_REQUEST';
export const REMOVE_STARTUP_EMPLOYEE_SUCCESS =
  'REMOVE_STARTUP_EMPLOYEE_SUCCESS';
export const REMOVE_STARTUP_EMPLOYEE_FAILURE =
  'REMOVE_STARTUP_EMPLOYEE_FAILURE';

export const UPDATE_STARTUP_SECTION_REQUEST = 'UPDATE_STARTUP_SECTION_REQUEST';

export const UPDATE_STARTUP_SECTION_SUCCESS = 'UPDATE_STARTUP_SECTION_SUCCESS';

export const UPDATE_STARTUP_SECTION_FAILURE = 'UPDATE_STARTUP_SECTION_FAILURE';

export const CREATE_STARTUP_SECTION_REQUEST = 'CREATE_STARTUP_SECTION_REQUEST';
export const CREATE_STARTUP_SECTION_SUCCESS = 'CREATE_STARTUP_SECTION_SUCCESS';
export const CREATE_STARTUP_SECTION_FAILURE = 'CREATE_STARTUP_SECTION_FAILURE';

export const REMOVE_STARTUP_SECTION_REQUEST = 'REMOVE_STARTUP_SECTION_REQUEST';

export const REMOVE_STARTUP_SECTION_SUCCESS = 'REMOVE_STARTUP_SECTION_SUCCESS';
export const REMOVE_STARTUP_SECTION_FAILURE = 'REMOVE_STARTUP_SECTION_FAILURE';

export const FETCH_STARTUP_GALLERY_REQUEST = 'FETCH_STARTUP_GALLERY_REQUEST';
export const FETCH_STARTUP_GALLERY_SUCCESS = 'FETCH_STARTUP_GALLERY_SUCCESS';
export const FETCH_STARTUP_GALLERY_FAILURE = 'FETCH_STARTUP_GALLERY_FAILURE';

export const FETCH_STARTUP_ALLPHOTOS_REQUEST =
  'FETCH_STARTUP_ALLPHOTOS_REQUEST';
export const FETCH_STARTUP_ALLPHOTOS_SUCCESS =
  'FETCH_STARTUP_ALLPHOTOS_SUCCESS';
export const FETCH_STARTUP_ALLPHOTOS_FAILURE =
  'FETCH_STARTUP_ALLPHOTOS_FAILURE';

export const FETCH_STARTUPS_NAMES_LIST_REQUEST =
  'FETCH_STARTUPS_NAMES_LIST_REQUEST';
export const FETCH_STARTUPS_NAMES_LIST_SUCCESS =
  'FETCH_STARTUPS_NAMES_LIST_SUCCESS';
export const FETCH_STARTUPS_NAMES_LIST_FAILURE =
  'FETCH_STARTUPS_NAMES_LIST_FAILURE';

export const FETCH_STARTUP_PROJECTS_REQUEST = 'FETCH_STARTUP_PROJECTS_REQUEST';
export const FETCH_STARTUP_PROJECTS_SUCCESS = 'FETCH_STARTUP_PROJECTS_SUCCESS';
export const FETCH_STARTUP_PROJECTS_FAILURE = 'FETCH_STARTUP_PROJECTS_FAILURE';
export const REMOVE_STARTUP_PROJECTS = 'REMOVE_STARTUP_PROJECTS';
