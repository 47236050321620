import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUNDS_LIST_SUCCESS,
  UPDATE_ADMIN_FUNDS_LIST,
  REMOVE_ADMIN_FUNDS_LIST,
} from 'adminPanel/redux/types';

const initialState = {
  list: [],
  nextPageIndex: null,
};

const adminFundsListReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUNDS_LIST_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.adminFunds;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
      };
    },
    [UPDATE_ADMIN_FUNDS_LIST]: (state, action) => ({
      ...state,
      list: action.payload.updatedAdminFundsList,
    }),
    [REMOVE_ADMIN_FUNDS_LIST]: state => ({
      ...state,
      list: [],
      nextPageIndex: null,
    }),
  },
);

export default adminFundsListReducer;
