import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_CHARTS_DATA_SUCCESS,
  FETCH_CHARTS_DATA_REQUEST,
  FETCH_CHARTS_DATA_FAILURE,
  REMOVE_CHARTS_DATA,
} from '../types';

const initialState = {
  data: [],
  isLoading: false,
};

const chartsDataReducer = createReducer(
  { ...initialState },
  {
    [FETCH_CHARTS_DATA_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: [...data],
      };
    },
    [FETCH_CHARTS_DATA_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_CHARTS_DATA_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [REMOVE_CHARTS_DATA]: (state) => ({
      ...state,
      data: [],
      isLoading: false,
    }),
  },
);

export default chartsDataReducer;
