export const FETCH_OTHER_FUNDS_REQUEST = 'FETCH_OTHER_FUNDS_REQUEST';
export const FETCH_OTHER_FUNDS_SUCCESS = 'FETCH_OTHER_FUNDS_SUCCESS';
export const FETCH_OTHER_FUNDS_FAILURE = 'FETCH_OTHER_FUNDS_FAILURE';

export const FETCH_UPCOMING_FUND_EVENTS_REQUEST =
  'FETCH_UPCOMING_FUND_EVENTS_REQUEST';
export const FETCH_UPCOMING_FUND_EVENTS_SUCCESS =
  'FETCH_UPCOMING_FUND_EVENTS_SUCCESS';
export const FETCH_UPCOMING_FUND_EVENTS_FAILURE =
  'FETCH_UPCOMING_FUND_EVENTS_FAILURE';

export const SAVE_FUND_EVENTS_DATE_RANGE = 'SAVE_FUND_EVENTS_DATE_RANGE';
export const SAVE_FUND_EVENTS_DATE = 'SAVE_FUND_EVENTS_DATE';
export const REMOVE_FUND_EVENTS_FILTERS = 'REMOVE_FUND_EVENTS_FILTERS';

export const FETCH_FUND_CALENDAR_EVENTS_REQUEST =
  'FETCH_FUND_CALENDAR_EVENTS_REQUEST';
export const FETCH_FUND_CALENDAR_EVENTS_SUCCESS =
  'FETCH_FUND_CALENDAR_EVENTS_SUCCESS';
export const FETCH_FUND_CALENDAR_EVENTS_FAILURE =
  'FETCH_FUND_CALENDAR_EVENST_FAILURE';

export const REMOVE_FUND_CALENDAR_EVENTS = 'REMOVE_FUND_CALENDAR_EVENTS';

export const SAVE_FUND_CALENDAR_FETCHED_MONTHS =
  'SAVE_FUND_CALENDAR_FETCHED_MONTHS';
