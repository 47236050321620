import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_ADMIN_FUND_NEWS_SUCCESS,
  REMOVE_ADMIN_FUND_NEWS,
} from 'adminPanel/redux/types';

const initialState = {
  list: [],
  nextPageIndex: null,
};

const adminFundNewsReducer = createReducer(
  { ...initialState },
  {
    [FETCH_ADMIN_FUND_NEWS_SUCCESS]: (state, action) => {
      const { data, meta } = action.payload.adminFundNews;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        list: meta.current_page === 1 ? [...data] : [...state.list, ...data],
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
      };
    },
    [REMOVE_ADMIN_FUND_NEWS]: state => ({
      ...state,
      list: [],
      nextPageIndex: null,
    }),
  },
);

export default adminFundNewsReducer;
