import React from 'react';
import MainHeaderOptionsMenu from 'common/components/mainHeaderOptionsMenu';
import LoginHeaderNavigation from './LoginHeaderNavigation';
import LandingHeaderNavigation from './LandingHeaderNavigation';
import DefaultHeaderNavigation from './DefaultHeaderNavigation';
import useMainHeaderNavigation from './hooks/useMainHeaderNavigation';

const MainHeaderNavigation = ({
  isMobileViewActive,
  toggleMobileNav,
  mainNavListClasses,
  mainNavItemsClasses,
  cookies,
}) => {
  const {
    isOnLandingPage,
    isOnLoginPage,
    isOptionsMenuOpen,
    indicator,
    clearIndicator,
    toggleOptionsMenu,
    handleLogOutClick,
    userData,
    openOptionsMenu,
  } = useMainHeaderNavigation();

  return (
    <nav className="main-nav">
      {isOnLandingPage && (
        <LandingHeaderNavigation
          isMobileViewActive={isMobileViewActive}
          toggleMobileNav={toggleMobileNav}
          mainNavItemsClasses={mainNavItemsClasses}
          mainNavListClasses={mainNavListClasses}
          userData={userData}
          openOptionsMenu={openOptionsMenu}
          handleLogOutClick={handleLogOutClick}
        />
      )}

      {isOnLoginPage && (
        <LoginHeaderNavigation
          mainNavItemsClasses={mainNavItemsClasses}
          mainNavListClasses={mainNavListClasses}
          toggleMobileNav={toggleMobileNav}
        />
      )}

      {!isOnLandingPage && !isOnLoginPage && (
        <DefaultHeaderNavigation
          mainNavListClasses={mainNavListClasses}
          mainNavItemsClasses={mainNavItemsClasses}
          toggleMobileNav={toggleMobileNav}
          cookies={cookies}
          indicator={indicator}
          clearIndicator={clearIndicator}
          isMobileViewActive={isMobileViewActive}
          userData={userData}
          openOptionsMenu={openOptionsMenu}
          handleLogOutClick={handleLogOutClick}
        />
      )}

      {!isMobileViewActive && isOptionsMenuOpen && (
        <MainHeaderOptionsMenu
          handleLogOutClick={handleLogOutClick}
          toggleOptionsMenu={toggleOptionsMenu}
          isOnLandingPage={isOnLandingPage}
          isOnLoginPage={isOnLoginPage}
        />
      )}
    </nav>
  );
};

export default MainHeaderNavigation;
