import React from 'react';
import ContactForm from 'landing/components/contactForm';
import { sendMessage } from 'common/api/mailApi';

const Contact = ({ contactModalVisibleChange }) => {
  const submitContactForm = (values, actions) => {
    sendMessage(values)
      .then(response => {
        if (response.status === 200) {
          contactModalVisibleChange({
            visible: true,
            message: response.data.message,
          });
          actions.resetForm({ name: '', email: '', message: '' });
          actions.setSubmitting(false);
        }
      })
      .catch(() => {
        contactModalVisibleChange({ visible: true, message: 'ERROR!' });
        actions.resetForm({ name: '', email: '', message: '' });
        actions.setSubmitting(false);
      });
  };

  return <ContactForm submitContactForm={submitContactForm} />;
};

export default Contact;
