import { createReducer } from 'common/utils/reduxUtils';
import {
  FETCH_LSE_DATA_SUCCESS,
  FETCH_LSE_DATA_REQUEST,
  FETCH_LSE_DATA_FAILURE,
} from 'adminPanel/adminContent/lseDataRequests/redux/types.js';

const initialState = {
  isLoading: false,
  list: [],
  isRejected: null,
  nextPageIndex: null,
  resultsNumber: null,
  meta: {},
};

const lseDataReducer = createReducer(
  { ...initialState },
  {
    [FETCH_LSE_DATA_SUCCESS]: (state, action) => {
      const { data, meta, isRejected } = action.payload.lseData;
      const hasNextPage = meta.current_page < meta.last_page;
      return {
        ...state,
        isLoading: false,
        list: [...data],
        isRejected,
        nextPageIndex: hasNextPage ? meta.current_page + 1 : null,
        resultsNumber: meta.total,
        meta,
      };
    },
    [FETCH_LSE_DATA_REQUEST]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_LSE_DATA_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
);

export default lseDataReducer;
