export const FETCH_OTHER_FUNDS_MANAGERS_REQUEST =
  'FETCH_OTHER_FUNDS_MANAGERS_REQUEST';
export const FETCH_OTHER_FUNDS_MANAGERS_SUCCESS =
  'FETCH_OTHER_FUNDS_MANAGERS_SUCCESS';
export const FETCH_OTHER_FUNDS_MANAGERS_FAILURE =
  'FETCH_OTHER_FUNDS_MANAGERS_FAILURE';

export const FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_REQUEST =
  'FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_REQUEST';
export const FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_SUCCESS =
  'FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_SUCCESS';
export const FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_FAILURE =
  'FETCH_UPCOMING_FUNDS_MANAGER_EVENTS_FAILURE';

export const SAVE_FUNDS_MANAGER_EVENTS_DATE_RANGE =
  'SAVE_FUNDS_MANAGER_EVENTS_DATE_RANGE';
export const SAVE_FUNDS_MANAGER_EVENTS_DATE = 'SAVE_FUNDS_MANAGER_EVENTS_DATE';
export const REMOVE_FUNDS_MANAGER_EVENTS_FILTERS =
  'REMOVE_FUNDS_MANAGER_EVENTS_FILTERS';

export const FETCH_FUNDS_MANAGER_CALENDAR_EVENTS_REQUEST =
  'FETCH_FUNDS_MANAGER_CALENDAR_EVENTS_REQUEST';
export const FETCH_FUNDS_MANAGER_CALENDAR_EVENTS_SUCCESS =
  'FETCH_FUNDS_MANAGER_CALENDAR_EVENTS_SUCCESS';
export const FETCH_FUNDS_MANAGER_CALENDAR_EVENTS_FAILURE =
  'FETCH_FUNDS_MANAGER_CALENDAR_EVENST_FAILURE';

export const REMOVE_FUNDS_MANAGER_CALENDAR_EVENTS =
  'REMOVE_FUNDS_MANAGER_CALENDAR_EVENTS';

export const SAVE_FUNDS_MANAGER_CALENDAR_FETCHED_MONTHS =
  'SAVE_FUNDS_MANAGER_CALENDAR_FETCHED_MONTHS';
