import { createReducer } from 'common/utils/reduxUtils';
import {
  REMOVE_WATCHLIST_FILTERS,
  SAVE_WATCHLIST_FILTERS,
  SAVE_WATCHLIST_SEARCH,
  SAVE_WATCHLIST_DATE_RANGE,
  SAVE_WATCHLIST_EVENT_DATE,
  SAVE_WATCHLIST_ADHOC_CHECKBOX,
} from 'watchlist/redux/types';

const initialState = {
  adHoc: null,
  attachments: [],
  search: '',
  dateRange: {},
  date: {},
  contentType: [],
  relations: [],
  publicity: [],
};

const watchlistFiltersReducer = createReducer(
  { ...initialState },
  {
    [SAVE_WATCHLIST_FILTERS]: (state, action) => {
      if (action.payload.filter && action.payload.filter[0]) {
        const { category } = action.payload.filter[0];
        return {
          ...state,
          [category]: action.payload.filter,
        };
      }
      if (action.payload.filter.category) {
        const { category } = action.payload.filter;
        return {
          ...state,
          [category]: [],
        };
      }
      return {
        ...state,
      };
    },
    [REMOVE_WATCHLIST_FILTERS]: state => ({
      ...state,
      adHoc: null,
      attachments: [],
      dateRange: {},
      date: {},
      contentType: [],
      relations: [],
      publicity: [],
    }),
    [SAVE_WATCHLIST_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload.search,
    }),
    [SAVE_WATCHLIST_DATE_RANGE]: (state, action) => ({
      ...state,
      dateRange: action.payload.dateRange,
    }),
    [SAVE_WATCHLIST_ADHOC_CHECKBOX]: (state, action) => ({
      ...state,
      adHoc: action.payload.adHoc ? 1 : null,
    }),
    [SAVE_WATCHLIST_EVENT_DATE]: (state, action) => ({
      ...state,
      date: action.payload.date,
    }),
  },
);

export default watchlistFiltersReducer;
